import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
`;

export const Main = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 6px;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: transparent;
`;

export const MainHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .rightHeaderLeft {
    display: flex;
    flex-direction: row;
    align-items: center;

    .name {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.secondary.onSecondary};
      margin-left: 4px;
    }
  
    .action {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant};
      margin-left: 2px;
    }
  }

  .date {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const MainContent = styled.div`
  margin-top: 12px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
`;
