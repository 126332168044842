export interface IDefaultTheme {
  premium: {
    premium: string;
    onPremium: string;
    onPremiumHover: string;
    onPremiumPressed: string;
  };
  danger: {
    danger: string;
    dangerHover: string;
    dangerPressed: string;
    dangerOutline: string;
    dangerContainer: string;
    onDanger: string;
    onDangerHover: string;
    onDangerPressed: string;
    onDangerOutline: string;
  };
  success: {
    success: string;
    successHover: string;
    successPressed: string;
    successOutline: string;
    successContainer: string;
    onSuccess: string;
    onSuccessHover: string;
    onSuccessPressed: string;
    onSuccessOutline: string;
  };
  warning: {
    warning: string;
    warningHover: string;
    warningPressed: string;
    warningOutline: string;
    warningContainer: string;
    onWarning: string;
    onWarningHover: string;
    onWarningPressed: string;
    onWarningOutline: string;
  };
  information: {
    information: string;
    informationHover: string;
    informationPressed: string;
    informationOutline: string;
    informationContainer: string;
    onInformation: string;
    onInformationHover: string;
    onInformationPressed: string;
    onInformationOutline: string;
  };
  notification: {
    notification: string;
    notificationHover: string;
    notificationPressed: string;
    notificationOutline: string;
    notificationContainer: string;
    onNotification: string;
    onNotificationHover: string;
    onNotificationPressed: string;
    onNotificationOutline: string;
  };
}

export const common = {
  premium: {
    premium: '#FFC700',
    onPremium: '#324150',
    get onPremiumHover() {return `${this.onPremium} 12%`},
    get onPremiumPressed() {return  `${this.onPremium} 24%`},
  },
  danger: {
    danger: '#E2574C',
    get dangerHover() {return `${this.danger} 12%`},
    get dangerPressed() {return  `${this.danger} 24%`},
    get dangerOutline() {return  `${this.danger} 50%`},
    get dangerContainer() {return  `${this.danger} 16%`},
    onDanger: '#FFFFFF',
    get onDangerHover() {return `${this.onDanger} 12%`},
    get onDangerPressed() {return  `${this.onDanger} 24%`},
    get onDangerOutline() {return  `${this.onDanger} 50%`},
  },
  success: {
    success: '#09B66D',
    get successHover() {return `${this.success} 12%`},
    get successPressed() {return  `${this.success} 24%`},
    get successOutline() {return  `${this.success} 50%`},
    get successContainer() {return  `${this.success} 16%`},
    onSuccess: '#FFFFFF',
    get onSuccessHover() {return `${this.onSuccess} 12%`},
    get onSuccessPressed() {return  `${this.onSuccess} 24%`},
    get onSuccessOutline() {return  `${this.onSuccess} 50%`},
  },
  warning: {
    warning: '#FDBF5E',
    get warningHover() {return `${this.warning} 12%`},
    get warningPressed() {return  `${this.warning} 24%`},
    get warningOutline() {return  `${this.warning} 50%`},
    get warningContainer() {return  `${this.warning} 16%`},
    onWarning: '#FFFFFF',
    get onWarningHover() {return `${this.onWarning} 12%`},
    get onWarningPressed() {return  `${this.onWarning} 24%`},
    get onWarningOutline() {return  `${this.onWarning} 50%`},
  },
  information: {
    information: '#0081FF',
    get informationHover() {return `${this.information} 12%`},
    get informationPressed() {return  `${this.information} 24%`},
    get informationOutline() {return  `${this.information} 50%`},
    get informationContainer() {return  `${this.information} 16%`},
    onInformation: '#FFFFFF',
    get onInformationHover() {return `${this.onInformation} 12%`},
    get onInformationPressed() {return  `${this.onInformation} 24%`},
    get onInformationOutline() {return  `${this.onInformation} 50%`},
  },
  notification: {
    notification: '#FF3D57',
    get notificationHover() {return `${this.notification} 12%`},
    get notificationPressed() {return  `${this.notification} 24%`},
    get notificationOutline() {return  `${this.notification} 24%`},
    get notificationContainer() {return  `${this.notification} 50%`},
    onNotification: '#FFFFFF',
    get onNotificationHover() {return `${this.onNotification} 12%`},
    get onNotificationPressed() {return  `${this.onNotification} 24%`},
    get onNotificationOutline() {return  `${this.onNotification} 50%`},
  },
} as IDefaultTheme;

export interface IColorsTheme extends IDefaultTheme {
  primary: {
    primary: string;
    primaryLightContainer: string;
    primaryHover: string;
    primaryPressed: string;
    primaryOutline: string;
    primaryContainer: string;
    onPrimary: string;
    onPrimaryHover: string;
    onPrimaryPressed: string;
    onPrimaryOutline: string;
  };
  secondary: {
    secondary: string;
    secondaryHover: string;
    secondaryPressed: string;
    secondaryOutline: string;
    onSecondary: string;
    onSecondaryVariant: string;
    onSecondaryHover: string;
    onSecondaryPressed: string;
    onSecondaryOutline: string;
  };
  tertiary: {
    tertiary: string;
    onTertiary: string;
  };
  surface: {
    surface: string;
    surfaceContainer: string;
    surfaceOutline: string;
    onSurface: string;
    onSurfaceVariant: string;
    onSurfacePlaceholder: string;
    onSurfaceHover: string;
    onSurfacePressed: string;
  };
}
