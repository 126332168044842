import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ListagemArquivosActions } from "Store/ListagemArquivos/ListagemArquivos.actions";
import { IObraData } from "Data/interfaces/Obra/IObraData";
import { createSchema } from "../../fileInfo.schema";
import {
  INomenclatureData,
  SeparatorsData,
  VersionFieldEnum,
  VersionFieldEnumLabel,
} from "Data/interfaces/Nomenclatura/INomenclatureData";
import { getValueField } from "Pages/ListagemArquivos/ListagemArquivos.helpers";
import Button from "Components/UI/Button";
import { useForm } from "react-hook-form";
import FieldControled from "Components/UI/FieldControled";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectStage from "Components/UI/SelectStage";
import OptStage from "Components/UI/SelectStage/Opt";
import { isEmptyObject } from "jquery";
import styles from "../../ListagemArquivos.module.scss";

interface INewEdicaoNomenclatura {
  record: any;
  nomenclature: INomenclatureData;
  obra: IObraData;
  constructionSiteDisciplinesId: number;
  isLoadingRenameFile: boolean;
  text: any;
  islistEdit?: boolean;
  index: number;
  toggleEditForm?: () => void;
  onSubmit: (values: any) => void;
  onCancel?: () => void;
}

const NewEdicaoNomenclatura: FC<INewEdicaoNomenclatura> = ({
  record,
  nomenclature,
  obra,
  constructionSiteDisciplinesId,
  isLoadingRenameFile,
  text,
  islistEdit,
  index,
  toggleEditForm,
  onSubmit,
  onCancel,
}) => {
  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [acronymStages, setAcronymStages] = useState<string[]>([]);

  const defaultValues = useMemo(() => {
    let currValues: Record<string, any> = {
      constructionSiteId: record.FileInfo.ConstructionSiteFk,
      constructionSiteDisciplineId: record?.FileInfo?.ConstructionSiteDisciplineFk ||
        constructionSiteDisciplinesId,
      fileId: record.FileInfo.ApiId,
      oldName: record.Name,
      extension: record.Extension,
      fileInfoId: record.FileInfo.FileInfoId,
      api: record.FileInfo.Api,
      status: record.FileInfo.Status,
      hasNomenclatureControl: record.HasNomenclatureControl?.toString(),
      index: index,
      description: record.FileInfo.Description || undefined,
    };

    if (record.HasNomenclatureControl) {
      obra.VersionConfig.VersionFields.forEach((field) => {
        currValues[`field${field.Ordem}`] = getValueField(text, field.Ordem, obra.VersionConfig.VersionFields);
        currValues[`separator${field.Ordem}`] = SeparatorsData.get(field.Separador)?.Value;
      });
    } else {
      currValues['simpleFileName'] = record.Name.split('.').slice(0, -1).join('.') || undefined;
    }

    return currValues;
  }, [
    record,
    index,
    obra.VersionConfig.VersionFields,
    text,
    constructionSiteDisciplinesId,
  ]);

  const filteredStages = useMemo(() => {
    if (!obra.AllowOnlyStagesLinkedToDisciplinesInUpload) {
      return obra.Stages;
    }
    return obra.Stages.filter((stage) => {
      return stage.IsDefault ||
        stage.ConstructionSiteStageDisciplines.some((discipline) => {
          return discipline.ConstructionSiteDisciplineFk === constructionSiteDisciplinesId;
        });
    });
  }, [
    obra.Stages,
    obra.AllowOnlyStagesLinkedToDisciplinesInUpload,
    constructionSiteDisciplinesId,
  ]);

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(createSchema(
      obra.VersionConfig.VersionFields,
      record.HasNomenclatureControl,
      obra.ValidateStagesInNomenclature ? acronymStages : undefined,
    ))
  });

  const handleChangeValue = useCallback((
    field: string,
    value: string = '',
  ) => {
    setValue(field, value);
  }, [setValue]);

  const renameSubmit = (values: any) => {
    onSubmit(values);
    setIsSent(true);
    setActiveIndex(index);
  };

  useEffect(() => {
    if (!isLoadingRenameFile && isSent) {
      dispatch(ListagemArquivosActions.setEditFormIndex(index));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRenameFile]);

  useEffect(() => {
    if (obra.Stages?.length > 0) {
      const data: string[] = [];
      for (const stage of filteredStages) {
        if (stage.Acronym) {
          data.push(stage.Acronym);
        }
      }
      setAcronymStages(data);
    }
  }, [obra.Stages, filteredStages]);

  useEffect(() => {
    if (!!acronymStages) {
      trigger();
    }
  }, [acronymStages, trigger]);

  return (
    <div className={`${styles['formWrapper']} ${islistEdit ? styles['formWrapperListEdit'] : ''}`}>
      <form onSubmit={handleSubmit(renameSubmit)}>
        <FieldControled
          name='constructionSiteId'
          control={control}
          label=''
          type='hidden'
          trigger={trigger}
        />
        <FieldControled
          label=''
          name='fileId'
          control={control}
          type='hidden'
          trigger={trigger}
        />
        <FieldControled
          label=''
          name='oldName'
          control={control}
          type='hidden'
          trigger={trigger}
        />
        <FieldControled
          label=''
          name='extension'
          control={control}
          type='hidden'
          trigger={trigger}
        />
        <FieldControled
          label=''
          name='fileInfoId'
          control={control}
          type='hidden'
          trigger={trigger}
        />
        <FieldControled
          label=''
          name='api'
          control={control}
          type='hidden'
          trigger={trigger}
        />
        <FieldControled
          label=''
          name='status'
          control={control}
          type='hidden'
          trigger={trigger}
        />
        <FieldControled
          label=''
          name='hasNomenclatureControl'
          control={control}
          type='hidden'
          trigger={trigger}
        />
        <FieldControled
          label=''
          name='index'
          control={control}
          type='hidden'
          trigger={trigger}
        />
        <div className={styles['fieldsWrapper']} >
          {record.HasNomenclatureControl ? (
            obra.VersionConfig.VersionFields?.map((field, indexVersionField) => {
              const isStageValidation = obra.ValidateStagesInNomenclature &&
                field.CampoVersaoId === VersionFieldEnum.Fase;

              return (
                <span key={`fields${field.Ordem}`}>
                  {isStageValidation && (
                    <SelectStage
                      key={`field${indexVersionField}`}
                      name={`field${field.Ordem}`}
                      control={control}
                      value={getValues(`field${field.Ordem}`)}
                      label={field.CampoPersonalizado
                        || VersionFieldEnumLabel.get(field.CampoVersaoId)
                        || ''}
                      opts={filteredStages?.map((stage) => ({
                        key: stage.Acronym || '',
                        label: (
                          <OptStage
                            left={stage.Acronym || ''}
                            separator={stage.Acronym ? '|' : ''}
                            right={stage.Name}
                          />
                        ),
                        labelSearch: stage.Acronym || '',
                      }))}
                      emptyLabel={
                        <OptStage
                          left="Nenhuma etapa encontrada."
                          isEmpty
                        />
                      }
                      height="35px"
                      trigger={trigger}
                      onChangeValue={(value) => handleChangeValue(`field${field.Ordem}`, value)}
                    />
                  )}
                  {!isStageValidation && (
                    <FieldControled
                      key={`field${indexVersionField}`}
                      name={`field${field.Ordem}`}
                      control={control}
                      label={field.CampoPersonalizado
                        || VersionFieldEnumLabel.get(field.CampoVersaoId)
                        || ''
                      }
                      disabled={isLoadingRenameFile && (activeIndex === index)}
                      trigger={trigger}
                    />
                  )}
                  <FieldControled
                    key={`separator${indexVersionField}`}
                    name={`separator${field.Ordem}`}
                    control={control}
                    label=''
                    type='hidden'
                    trigger={trigger}
                  />
                </span>
              )
            })
          ) : (
            <div className={styles['simpleFileNameWrapper']}>
              <FieldControled
                name="simpleFileName"
                control={control}
                label="Nome do Arquivo"
                type="hidden"
                trigger={trigger}
              />
            </div>
          )}
        </div>
        <div className={styles['descriptionWrapper']}>
          <FieldControled
            name="description"
            control={control}
            label="Descrição"
            disabled={isLoadingRenameFile && (activeIndex === index)}
            trigger={trigger}
          />
        </div>
        <div className={styles['buttonsWrapper']}>
          {(islistEdit && onCancel) ? (
            <>
              <Button
                onClick={onCancel}
                disabled={isLoadingRenameFile || !isEmptyObject(errors)}
              >
                Cancelar
              </Button>
              <Button htmlType='submit' type='primary' loading={isLoadingRenameFile}>
                Salvar
              </Button>
            </>
          ) : (
            toggleEditForm && (
              <>
                <Button
                  onClick={toggleEditForm}
                  disabled={isLoadingRenameFile || !isEmptyObject(errors)}
                >
                  Cancelar
                </Button>
                <Button
                  htmlType='submit'
                  type='primary'
                  disabled={isLoadingRenameFile && (activeIndex === index)}
                  loading={isLoadingRenameFile && (activeIndex === index)}
                >
                  Salvar
                </Button>
              </>
            )
          )}
        </div>
      </form>
    </div>
  )
};

export default NewEdicaoNomenclatura;
