import { Dispatch, FC, useEffect, useMemo, useRef, useState } from 'react';
import PageHeader from '../../Components/UI/PageHeader/PageHeader';
import BreadCrumb, { IBreadcrumbItem } from '../../Components/UI/BreadCrumb';
import Table from '../../Components/UI/Table';
import TableActionTopbar from '../../Components/UI/TableActionTopbar';
import { IGlobalReducerState } from '../../Store/Base/interface/IGlobalReducerState';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { ListagemArquivosActions } from '../../Store/ListagemArquivos/ListagemArquivos.actions';
import emptyListImage from '../../Static/images/computer-upload.png';
import {
  getBreadCrumb,
  getColumnsTable,
  getColumnsTableEdit,
  IRequestPlotColumns,
  getBreadCrumbDefault
} from './ListagemArquivos.columns';
import {
  getButtonsTableToolbar,
  handleOpenFileLink,
  IListSaveOrder,
  renameFile,
  sortList,
  updateFileInfo,
  updateUploadState
} from './ListagemArquivos.helpers';
import FileDrop from '../../Components/UI/FileDrop';
import { Http } from '../../Utils/Http';
import ExclusaoArquivos, {
  IDeleteFilesComponentRequest
} from './Modals/ExclusaoArquivos';
import NovaPasta from './Modals/NovaPasta';
import AvisoTamanhoEQuantidadeArquivos from './Modals/AvisoTamanhoEQuantidadeArquivos';
import { ICreateFolderRequest } from '../../Data/interfaces/Obra/ICreateFolderRequest';
import './ListagemArquivos-override.css';
import SolicitacaoPlotagem from './SolicitacaoPlotagem';
import { IUnarchiveFilesRequest } from '../../Data/interfaces/Obra/IUnarchiveFilesRequest';
import SignalR from '../../Components/UI/SignalR';
import { mapNotification } from 'Utils/ObraUtils';
import UpdateStatus from 'Components/UI/UpdateStatusList';
import DownloadModal from 'Components/UI/ModalDownload';
import { NotificationActions } from 'Store/Notification/Notification.actions';
import AvisoRenderizacaoUpgrade from './Modals/AvisoRenderizacaoUpgrade';
import { history } from 'Store';
import LoadingArchives from 'Components/UI/CustomLoading/LoadingArchives';
import { IFileData } from 'Data/interfaces/ListagemArquivos/IListFilesResponse';
import ProgressRenderBar from './Modals/ProgressRenderBar';
import EdicaoNomenclatura from './Components/EdicaoNomenclatura/EdicaoNomenclatura';
import AppStorage from '../../Utils/AppStorage';
import { ViewerActions } from 'Store/Viewer/Viewer.actions';
import { ConfigProvider, Empty } from 'antd';
import CustomEmptyData from 'Components/UI/CustomEmptyData';
import TabsShell, { PathActiveEnum } from 'Components/UI/TabsShell';
import { DisciplineIconsEnum } from 'Pages/Disciplinas';
import { ObraActions } from 'Store/Obra/Obra.actions';
import DownloadBloqueadosModal, { IBlockedParams } from './Modals/DownloadBloqueadosModal';
import { IDownloadFilesRequest } from 'Data/interfaces/Obra/IDownloadFilesRequest';
import VisualizacaoBloqueadosModal from './Modals/VisualizacaoBloqueadosModal';
import AvisoUploadUpgrade from './Modals/AvisoUploadUpgrade';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import { Mixpanel } from 'Utils/MixPanel';
import useWindowSize from 'Hooks/useWindowSize';
import { DisciplinaActions } from 'Store/Disciplina/Disciplina.actions';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';
import { HistoryActions } from 'Store/History/History.actions';
import { IHistoryFileRequestList } from 'Data/interfaces/History/IHistoryFileApi';
import { UploadActions } from 'Store/Upload/Upload.actions';
import NewEdicaoNomenclatura from './Components/NewEdicaoNomenclatura/NewEdicaoNomenclatura';
import toastHandler from 'Utils/toastHandler';
import styles from './ListagemArquivos.module.scss';

interface IListagemArquivos {
  match: any;
}

export interface IOpenFileAction {
  csId: number;
  ConstructionSiteDisciplinesId: number;
  record: IFileData;
  constructionSiteName: string;
  breadCrumbId: number | string;
  dispatch: Dispatch<any>;
  constructionSiteApiId: number;
  event: number;
}

export interface IOpenVisualizationBlockedFileAction {
  path: string;
  newWindown: boolean;
}

const ListagemArquivos: FC<Props> = ({
  Obra,
  BreadCrumbItem,
  ListFiles,
  filters,
  selectedEditRowKeys,
  showEditTable,
  showPlotRequestTable,
  isLoadingFileList,
  match,
  nomenclature,
  editFormIndex,
  isLoading,
  isDownloading,
  showModalDeleteFiles,
  showModalCreateFolder,
  showModalInfoFiles,
  ObsoleteFolderId,
  isLoadingRenameFile,
  TenantUsers,
  notificationsMap,
  CloudServiceFolderUrl,
  userInfo,
  planFree,
  userLimitsActive,
  loadObsoleteFiles,
  isLoadingFilesObsoleteList,
  openLink,
  currentUserPrivileges,
  isLoadingUpdateStatus,
  statusUpdateStatus,
  dateUpdateStatus,
  isShellCollapsed,
  listArchivesNewDisciplineNoCache
}) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const downloadViaStreamFlag = useFeatureFlag('download-via-stream');
  const downloadViaStream = downloadViaStreamFlag.enabled && downloadViaStreamFlag.value === 'test';
  const stagesFeatureValidateFlag = useFeatureFlag('stages-feature-validate');
  const stagesFeatureValidate = stagesFeatureValidateFlag.enabled && stagesFeatureValidateFlag.value === 'test';

  const { height, width } = useWindowSize();
  const isLoadingCache = useSelector(
    (state: IGlobalReducerState) => state.updateStatus.isLoading
  );
  const breadCrumbId = Http.GetQueryParams('breadCrumbId');
  const onlyFolder = Http.GetQueryParams('onlyFolder');
  const isObsoleteFolder = Http.GetQueryParams('isObsoleteFolder') === 'true';
  const folderName = Http.GetQueryParams('folderName');
  const obraApi = Http.GetQueryParams('obraApi');
  const ConstructionSiteDisciplinesId = Number(
    Http.GetQueryParams('ConstructionSiteDisciplinesId')
  );
  const DisciplineName = Http.GetQueryParams('DisciplineName');
  const itemIdNotification = mapNotification(notificationsMap, true);
  const filterNotification = mapNotification(notificationsMap);
  const requestPlotListFiles: IRequestPlotColumns[] = ListFiles.filter((file) =>
    selectedEditRowKeys.includes(file.Identifier.ApiId || file.Identifier.ItemId)
  )
    .filter((file) => file.Status !== 0)
    .map((file, index) => ({
      Name: file?.Name,
      HasColor: false,
      Extension: file?.Extension,
      key: index,
      FileInfoFk: file?.FileInfo?.FileInfoId || null,
      FileApiId: file?.FileInfo?.ApiId || null
    }));

  const firstOpen = useRef(0);
  const [breadCrumbs, setBreadCrumbs] = useState<IBreadcrumbItem[]>([]);
  const [isFileListRequest, setIsFileListRequest] = useState(false);
  const [showModalLimitRenderFiles, setShowModalLimitRenderFiles] = useState(false);
  const [showModalRenderUpgrade, setShowModalRenderUpgrade] = useState(false);
  const [showModalDownloadBlockedFiles, setShowModalDownloadBlockedFiles] = useState(
    false
  );
  const [showModalUpgradeUpload, setShowModalUpgradeUpload] = useState(false);
  const [
    showModalVisualizationBlockedFiles,
    setShowModalVisualizationBlockedFiles
  ] = useState(false);
  const [
    hasPermissionVisualizationBlockedFiles,
    setHasPermissionVisualizationBlockedFiles
  ] = useState(false);
  const [openFileVisualizationLinkStatus, setOpenFileVisualizationLinkStatus] = useState(
    {} as IOpenVisualizationBlockedFileAction
  );
  const [blockedParams, setBlockedParams] = useState<IBlockedParams>({
    hasPermission: false,
    isBlocked: false,
    hasBlockedFolders: false,
    hasMultipleFiles: false,
  });
  const [openFileAction, setOpenFileAction] = useState({} as IOpenFileAction);
  const [activeRow, setActiveRow] = useState<string[]>([]);
  const [searchArchives, setSearchArchives] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<IFileData[]>([]);
  const [deleteFilesRequest, setDeleteFilesRequest] = useState(
    {} as IDeleteFilesComponentRequest
  );

  const request = useMemo(() => {
    const result = match.params;

    if (breadCrumbId) {
      result['breadCrumbId'] = breadCrumbId;
    }

    if (onlyFolder) {
      result['onlyFolder'] = onlyFolder;
    }

    if (isObsoleteFolder) {
      result['isObsoleteFiles'] = isObsoleteFolder;
    }

    if (folderName) {
      result['folderName'] = folderName;
    }

    if (obraApi) {
      result['api'] = obraApi;
    }

    result['googleDriveExperiment'] = "test";

    if (userInfo) {
      result['userInfoTracking'] = userInfo;
    }

    if (currentTenant) {
      result['currentTenantTracking'] = currentTenant;
    }

    if (listArchivesNewDisciplineNoCache) {
      result['ignoreCache'] = true;
    }

    return result;
  }, [
    breadCrumbId,
    folderName,
    isObsoleteFolder,
    match.params,
    obraApi,
    onlyFolder,
    userInfo,
    listArchivesNewDisciplineNoCache
  ]);

  useEffect(() => {
    if (
      firstOpen.current === 0 &&
      Obra?.Name &&
      DisciplineName &&
      Obra.ConstructionSiteId === Number(match.params.constructionSiteId)
    ) {
      if (DisciplineName === folderName) {
        Mixpanel.track({
          name: 'PAGE_VIEW',
          props: {
            constructionSiteId: Number(match.params.constructionSiteId),
            disciplineId: ConstructionSiteDisciplinesId,
            disciplineApiFolderId: match.params.folderId,
            disciplineName: DisciplineName
          },
          userInfo,
          currentListTenant: currentTenant
        });
      } else {
        Mixpanel.track({
          name: 'PAGE_VIEW',
          props: {
            constructionSiteId: Number(match.params.constructionSiteId),
            disciplineId: ConstructionSiteDisciplinesId,
            disciplineName: DisciplineName,
            folderId: match.params.folderId,
            folderName: folderName
          },
          userInfo,
          currentListTenant: currentTenant
        });
      }
      firstOpen.current++;
    }
  }, [Obra]);

  useEffect(() => {
    if (
      AppStorage.GetItem('listFilesOrder') &&
      !isLoadingFileList &&
      !isLoadingFilesObsoleteList &&
      !isLoadingUpdateStatus
    ) {
      const listFilesSaveOrder: IListSaveOrder = JSON.parse(
        AppStorage.GetItem('listFilesOrder') ?? ''
      );
      sortList(
        listFilesSaveOrder.dataIndex,
        listFilesSaveOrder.sortOrder,
        ListFiles,
        dispatch
      );
    }
  }, [isLoadingFileList, isLoadingFilesObsoleteList, isLoadingUpdateStatus]);

  useEffect(() => {
    dispatch(ListagemArquivosActions.listFiles(request));
    dispatch(ListagemArquivosActions.listFilesMoveToClear());
    if (listArchivesNewDisciplineNoCache) {
      dispatch(DisciplinaActions.listArchiveNewDisciplineNoCache(null));
    }
  }, [
    match.params.folderId,
    breadCrumbId,
    onlyFolder,
    isObsoleteFolder,
    listArchivesNewDisciplineNoCache
  ]);

  useEffect(() => {
    if (loadObsoleteFiles && !isObsoleteFolder) {
      request['loadObsoleteFiles'] = loadObsoleteFiles;
      dispatch(ListagemArquivosActions.filesVersion(request));
    }
    if (loadObsoleteFiles && isObsoleteFolder) {
      dispatch(ListagemArquivosActions.loadObsoleteFiles(false));
    }
  }, [loadObsoleteFiles]);

  useEffect(() => {
    if (isLoadingFileList && !isFileListRequest) {
      setIsFileListRequest(true);
    }
  }, [isLoadingFileList]);

  useEffect(() => {
    dispatch(ViewerActions.setOpenFileLink(''));
    dispatch(UploadActions.checkUploadFilesInfoClear());
  }, []);

  useEffect(() => {
    const pathname = history.location.pathname;
    const search = history.location.search;
    dispatch(
      ObraActions.storeArchivesPath({
        ...match,
        url: `${pathname}${search}`
      })
    );
  }, [match]);

  useEffect(() => {
    if (isLoadingFileList || Obra.ConstructionSiteId === undefined) {
      setBreadCrumbs(getBreadCrumbDefault(BreadCrumbItem));
      dispatch(ListagemArquivosActions.setSelectedEditRowKeys([]));
    } else {
      setBreadCrumbs(
        getBreadCrumb(
          Obra,
          BreadCrumbItem,
          ConstructionSiteDisciplinesId,
          isObsoleteFolder
        )
      );
    }
  }, [
    BreadCrumbItem,
    Obra,
    Obra.ConstructionSiteId,
    isLoadingFileList,
    ConstructionSiteDisciplinesId,
    isObsoleteFolder
  ]);

  useEffect(() => {
    if (!isLoadingRenameFile) {
      setActiveRow([]);
    }
  }, [isLoadingRenameFile]);

  useEffect(() => {
    //Marcar como vistas as notificações no frontend
    const notificationMarkAsView = notificationsMap
      .filter((x) => x.ItemParentId === request.folderId && !x.hasRead && !x.hasView)
      .map((item) => item);

    //Buscar e marcar como lidas notificações que tiveram suas pastas excluídas
    if (isFileListRequest && !isLoadingFileList) {
      setIsFileListRequest(false);

      const folderIds = ListFiles?.filter((folder) => folder.IsFolder).map(
        (folder) => folder.Identifier.ApiId
      );

      notificationsMap
        .filter((x) => x.ItemParentId !== request.folderId && !x.hasRead && !x.hasView)
        .forEach((notification) => {
          const itemsAux = notification.ItemsAux ?? [];
          const index =
            itemsAux.findIndex((folderId) => folderId === request.folderId) ?? -1;

          if (index !== -1 && !folderIds.includes(itemsAux[index + 1])) {
            notificationMarkAsView.push(notification);
          }
        });
    }

    //Marcar notificaçãoes como 'vistas'
    if (notificationMarkAsView?.length > 0) {
      dispatch(NotificationActions.setNotificationAsView(notificationMarkAsView));
    }
  }, [ListFiles, isLoadingFileList]);

  useEffect(() => {
    if (selectedEditRowKeys.length <= 1) {
      dispatch(
        ListagemArquivosActions.setShowModalDownloadMultipleFiles({ isVisible: false })
      );
    }
  }, [selectedEditRowKeys]);

  const onChange = (selecteds: any, selectedsData: any) => {
    dispatch(ListagemArquivosActions.setSelectedEditRowKeys(selecteds));
    setSelectedFiles(selectedsData);
  };

  const rowSelection = {
    selectedRowKeys: selectedEditRowKeys,
    columnWidth: '5%',
    onChange
  };

  const onSubmit = (values: any) => {
    let closable = true;

    let fullName = '';
    let nomenclatureCount = 0;

    nomenclatureCount = Obra.VersionConfig.VersionFields.length - 1;

    for (let i = 0; i <= nomenclatureCount; i++) {
      fullName += values[`field${i}`] + values[`separator${i}`];
    }
    const newName = fullName.slice(0, -1) + '.' + values.extension;

    if (values && !ListFiles.find((file) => file.Name === newName)) {
      closable = false;
      renameFile(values, BreadCrumbItem, dispatch);
    }

    if (
      !ListFiles.find(
        (file) =>
          (file.Identifier.ApiId === values.fileId ||
            file.Identifier.ItemId === values.fileId) &&
          (file.FileInfo.Description === values.description ||
            (!file.FileInfo.Description && !values.description))
      )
    ) {
      closable = false;
      updateFileInfo(values, dispatch);
    }

    if (closable) {
      setActiveRow([]);
    }
  };

  // const onChangeNomenclature = (index: number) => {
  //   dispatch(ListagemArquivosActions.setSelectedEditRowKeys([index]));
  //   dispatch(ListagemArquivosActions.setShowEditTable(true));
  //   dispatch(ListagemArquivosActions.setEditFormIndex(0));
  // };

  const onChangeNomenclatureInList = (record: any) => {
    setActiveRow((prevState: string[]) =>
      prevState[0] === (record.Identifier.ApiId || record.Identifier.ItemId)
        ? []
        : [record.Identifier.ApiId || record.Identifier.ItemId]
    );
  };

  const openModalExclusaoArquivos = () => {
    setDeleteFilesRequest({
      filesToDelete: selectedFiles,
      FolderId: BreadCrumbItem.Identifier,
      ObsoleteFolderId: ObsoleteFolderId,
      ConstructionSiteId: Obra.ConstructionSiteId
    });
    dispatch(ListagemArquivosActions.setShowModalDeleteFiles(true));
  };

  const openModalDownloadMultiplesFiles = (hasFolder: boolean) => {
    if (downloadViaStream) {
      if (hasFolder) {
        return dispatch(
          ListagemArquivosActions.setShowModalDownloadMultipleFiles({
            isVisible: true,
            hasFolder: true
          })
        );
      } else {
        handleTrackDownloadMultiple();
        return handleDowload();
      }
    }

    if (hasFolder) {
      dispatch(
        ListagemArquivosActions.setShowModalDownloadMultipleFiles({
          isVisible: true,
          hasFolder: true
        })
      );
    } else {
      dispatch(
        ListagemArquivosActions.setShowModalDownloadMultipleFiles({ isVisible: true })
      );
    }
  };

  const openModalDownloadBlockedFiles = (
    hasPermission: boolean,
    isBlocked: boolean,
    hasBlockedFolders: boolean,
    hasMultipleFiles: boolean,
  ) => {
    setBlockedParams({
      hasPermission,
      isBlocked,
      hasBlockedFolders,
      hasMultipleFiles,
    });
    setShowModalDownloadBlockedFiles(true);
  };

  const openModalVisualizationBlockedFiles = (hasPermission: boolean) => {
    dispatch(ViewerActions.setOpenFileLink(''));
    setHasPermissionVisualizationBlockedFiles(hasPermission);
    setShowModalVisualizationBlockedFiles(true);
  };

  const closeModalVisualizationBlockedFiles = () => {
    dispatch(ViewerActions.setOpenFileLink(''));
    setOpenFileVisualizationLinkStatus({} as IOpenVisualizationBlockedFileAction);
    setShowModalVisualizationBlockedFiles(false);
  };

  const openModalCriarPasta = () => {
    dispatch(ListagemArquivosActions.setShowModalCreateFolder(true));
  };

  const handleDowload = () => {
    const FilesId = selectedEditRowKeys;
    const request: IDownloadFilesRequest = {
      api: Obra.Api,
      constructionSiteId: Obra.ConstructionSiteId || 0,
      itemsId: FilesId,
      constructionSiteName: Obra.Name,
      tenantName: Obra.TenantName,
      UserId: userInfo?.Id,
      userInfoTracking: userInfo,
      currentTenantTracking: currentTenant
    };

    if (downloadViaStream) {
      toastHandler.showInfo('Seu download está em andamento...');
      const url = `${process.env.REACT_APP_BASE_DOWNLOAD_URL
        }api/Download/stream?constructionSiteId=${Obra.ConstructionSiteId}&api=${Obra.Api
        }&itemsIds=${FilesId.join('&itemsIds=')}`;
      window.open(url, '_blank')?.focus();
    } else {
      dispatch(ListagemArquivosActions.downloadFiles(request));
      setShowModalDownloadBlockedFiles(false);
    }
  };

  const handleVisualizationBlockedFile = () => {
    if (openFileVisualizationLinkStatus.newWindown) {
      window.open(openFileVisualizationLinkStatus.path, '_blank', 'noopener,noreferrer');
    } else {
      handleOpenFileLink(openFileVisualizationLinkStatus.path, dispatch);
    }

    setOpenFileVisualizationLinkStatus({} as IOpenVisualizationBlockedFileAction);
    setShowModalVisualizationBlockedFiles(false);
  };

  const desarquivarArquivos = () => {
    const FilesId = selectedEditRowKeys || [];
    const request: IUnarchiveFilesRequest = {
      ConstructionSiteId: Obra.ConstructionSiteId || 0,
      ObsoleteFolderId: BreadCrumbItem.Identifier,
      FolderId: BreadCrumbItem.PreviousItem?.Identifier || '',
      FilesId: FilesId || []
    };
    dispatch(ListagemArquivosActions.unarchiveFiles(request));
  };

  const createFolder = (folderName: string) => {
    if (Obra.ConstructionSiteId) {
      const request: ICreateFolderRequest = {
        ConstructionSiteId: Obra.ConstructionSiteId,
        NewFolderName: folderName,
        FolderCurrentId: BreadCrumbItem.Identifier
      };

      dispatch(ListagemArquivosActions.createFolder(request));
    }
  };

  const handleUpgradePlan = () => {
    Mixpanel.track({
      name: 'CALL_T0_ACTION',
      props: {
        origin: 'listagem-arquivos',
        originPath: window.location.pathname
      },
      userInfo,
      currentListTenant: currentTenant
    });

    if (userInfo?.CurrentRoleFk === 1) {
      history.push('/faturamento/planos');
    } else {
      window.open('https://bim.maletadoengenheiro.com.br/seja-premium');
    }
  };

  const openModalUpgradeUpload = () => {
    setShowModalUpgradeUpload(true);
  };

  const filteredArchives = useMemo(
    () =>
      ListFiles?.filter(
        (file: any) =>
          file.Name?.toLowerCase().includes(searchArchives?.toLowerCase()) ||
          file.FileInfo?.Description?.toLowerCase().includes(
            searchArchives?.toLowerCase()
          )
      ),
    [ListFiles, searchArchives]
  );

  const iconLeft = () => {
    if ((DisciplineIconsEnum as any)[folderName || BreadCrumbItem.Name]) {
      return (DisciplineIconsEnum as any)[folderName || BreadCrumbItem.Name];
    }
    if (BreadCrumbItem.PreviousItem) {
      return 'arquivar';
    }
    return 'menuContexto';
  };

  const handleTrackDownloadMultiple = () => {
    const selectedFiles = ListFiles.filter((file) => {
      return selectedEditRowKeys.includes(
        file.Identifier.ApiId || file.Identifier.ItemId
      );
    });
    const hasFolder = selectedFiles.some((file) => file.IsFolder);
    const onlyFolder = selectedFiles.every((file) => file.IsFolder);

    Mixpanel.track({
      name: 'DOWNLOAD_FILE_MULTIPLO',
      props: {
        quantity: selectedEditRowKeys.length,
        api: Number(obraApi),
        constructionSiteId: Number(match.params.constructionSiteId),
        hasFolder,
        onlyFolder,
        isDownloadViaStream: downloadViaStream,
      },
      userInfo,
      currentListTenant: currentTenant
    });
  };

  const handleOpenHistoryDrawer = (fileData?: IFileData) => {
    let title = 'Histórico de Pasta';
    let parentName = BreadCrumbItem.Name;
    let itemId = BreadCrumbItem.Identifier;
    let isFolder = true;

    if (selectedEditRowKeys.length === 0 && !fileData) {
      if (!BreadCrumbItem.PreviousItem) {
        title = 'Histórico de Disciplina';
      } else {
        title = 'Histórico de Pasta';
      }
    }

    if (selectedEditRowKeys.length === 1 || fileData) {
      if (fileData) {
        if (!fileData.IsFolder) {
          title = 'Histórico de Arquivo';
          isFolder = false;
        }
        parentName = fileData.Name;
        itemId = fileData.Identifier.ApiId;
      }

      if (!fileData) {
        const fileIndex = ListFiles.findIndex((file) => {
          return file.Identifier.ApiId === selectedEditRowKeys[0];
        });
        if (fileIndex !== -1) {
          if (!ListFiles[fileIndex].IsFolder) {
            title = 'Histórico de Arquivo';
            isFolder = false;
          }
          parentName = ListFiles[fileIndex].Name;
          itemId = ListFiles[fileIndex].Identifier.ApiId;
        }
      }
    }

    const request: IHistoryFileRequestList = {
      title,
      parentName,
      itemId,
      isFolder
    };
    dispatch(HistoryActions.listHistoryFileRequest(request));
  };

  const leftBarAndPaddingWidth = isShellCollapsed ? 132 : 307;

  return showPlotRequestTable ? (
    <SolicitacaoPlotagem
      requestPlotListFiles={requestPlotListFiles}
      breadcrumbs={breadCrumbs}
    />
  ) : (
    <>
      <SignalR />
      <TabsShell
        ConstructionSiteId={match.params.constructionSiteId}
        active={PathActiveEnum.detail}
        loading={isLoadingFileList || isLoadingCache || searchLoading}
        match={match}
      />
      <PageHeader name={folderName || BreadCrumbItem.Name} iconLeft={iconLeft()}>
        <div className={styles['headerBreadcrumb']}>
          <BreadCrumb breadcrumbs={breadCrumbs} />
          <UpdateStatus
            status={statusUpdateStatus}
            date={dateUpdateStatus}
            loading={isLoading}
          />
        </div>
        <div className={styles['tableContainer']}>
          {isObsoleteFolder && !selectedEditRowKeys.length ? (
            <TableActionTopbar
              buttons={[]}
              onSearch={setSearchArchives}
              onSearchLoading={setSearchLoading}
              inputPlaceholder="Pesquise por um arquivo"
            />
          ) : (
            <TableActionTopbar
              buttons={
                !isLoadingFileList
                  ? getButtonsTableToolbar({
                    listFiles: ListFiles,
                    ConstructionSiteDisciplinesId: ConstructionSiteDisciplinesId,
                    obra: Obra,
                    selectedRowKeys: selectedEditRowKeys,
                    selectedFiles,
                    showEditTable,
                    dispatch,
                    openModalExclusaoArquivos,
                    openModalDownloadMultiplesFiles,
                    openModalDownloadBlockedFiles,
                    onDownload: () => handleDowload(),
                    desarquivarArquivos,
                    openModalCriarPasta,
                    breadCrumbItemId:
                      BreadCrumbItem.BreadCrumbItemId ||
                      BreadCrumbItem.BreadCrumbItemIdDropbox,
                    isDownloading,
                    isObsoleteFolder,
                    ObsoleteFolderId,
                    constructionSiteId: request.constructionSiteId,
                    folderId: request.folderId,
                    folderName: request.folderName,
                    disciplineName: DisciplineName,
                    platform: Obra.Api,
                    urlFolder: CloudServiceFolderUrl,
                    breadCrumbPreviousItemId: breadCrumbId,
                    planIsFree: planFree,
                    isLoadingFilesObsoleteList,
                    currentUserPrivileges,
                    userInfo,
                    currentTenant,
                    onOpenHistoryDrawer: handleOpenHistoryDrawer,
                    flags: { downloadViaStream },
                  })
                  : []
              }
              onSearch={setSearchArchives}
              onSearchLoading={setSearchLoading}
              inputPlaceholder="Pesquise por um arquivo"
            />
          )}
          {isLoadingFileList || isLoadingCache || searchLoading ? (
            <LoadingArchives multiple={11} />
          ) : ListFiles.length ? (
            showEditTable ? (
              <ConfigProvider
                renderEmpty={() =>
                  searchArchives ? (
                    <CustomEmptyData
                      title="Nenhum arquivo encontrado"
                      subtitle="Tente pesquisar pelo nome ou descrição do arquivo."
                    />
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
              >
                <Table
                  className={styles['editTable']}
                  dataSource={filteredArchives
                    .filter(
                      (file) =>
                        !file.IsFolder &&
                        (!selectedEditRowKeys.length ||
                          selectedEditRowKeys.includes(
                            file.Identifier.ApiId || file.Identifier.ItemId
                          ))
                    )
                    .map((fileData, index) => ({ ...fileData, key: index }))}
                  columns={getColumnsTableEdit({
                    onSubmit,
                    nomenclature,
                    isLoadingRenameFile,
                    editFormIndex,
                    dispatch,
                    obra: Obra,
                    isStageValidation: stagesFeatureValidate,
                    constructionSiteDisciplinesId: ConstructionSiteDisciplinesId,
                  })}
                />
              </ConfigProvider>
            ) : (
              <FileDrop
                dragInfo={BreadCrumbItem.Name}
                onDropFiles={(files) =>
                  updateUploadState(
                    files,
                    match,
                    Obra,
                    BreadCrumbItem,
                    dispatch,
                    ConstructionSiteDisciplinesId,
                    userInfo?.UserStoreItem.MaxQtyUploadFiles ||
                    userInfo?.UserStoreItem.StorePlan?.MaxQtyUploadFiles,
                    openModalUpgradeUpload,
                    folderName,
                    DisciplineName
                  )
                }
                maxQtyUploadFiles={
                  userInfo?.UserStoreItem.MaxQtyUploadFiles ||
                  userInfo?.UserStoreItem.StorePlan?.MaxQtyUploadFiles
                }
                limitUpload={
                  userInfo?.UserStoreItem.MaxQtyUploadFiles ||
                  userInfo?.UserStoreItem.StorePlan?.MaxQtyUploadFiles
                }
              >
                <ConfigProvider
                  renderEmpty={() =>
                    searchArchives ? (
                      <CustomEmptyData
                        title="Nenhum arquivo encontrado"
                        subtitle="Tente pesquisar pelo nome ou descrição do arquivo."
                      />
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                >
                  <Table
                    virtual
                    scroll={{
                      x: width - leftBarAndPaddingWidth,
                      y: height - 331 // height - (TopBar+PageHeader+ActionBar)
                    }}
                    className={styles['listTable']}
                    columns={getColumnsTable({
                      fileList: ListFiles,
                      filters,
                      constructionSiteId: Obra.ConstructionSiteId || 0,
                      constructionSiteName: Obra.Name,
                      breadCrumbItem: BreadCrumbItem,
                      dispatch,
                      constructionSiteApiId: Obra.Api,
                      ConstructionSiteDisciplinesId: ConstructionSiteDisciplinesId,
                      itemsNotification: filterNotification,
                      itemIdNotification: itemIdNotification,
                      setShowModalLimitRenderFiles,
                      setShowModalRenderUpgrade,
                      planFree: planFree,
                      maxRender:
                        userInfo?.UserStoreItem.MaxQtyRenderedFiles ||
                        userInfo?.UserStoreItem.StorePlan?.MaxQtyRenderedFiles,
                      activeRender: userLimitsActive?.QtyRenderedFiles ?? 0,
                      userInfo: userInfo,
                      currentTenant,
                      disciplineName: DisciplineName,
                      showModalLimitRenderFiles,
                      onChangeNomenclatureInList,
                      activeRow,
                      setOpenFileAction,
                      onSubmit,
                      isLoadingRenameFile,
                      nomenclature,
                      openLink,
                      isLoadingFilesObsoleteList,
                      currentUserPrivileges,
                      isObsoleteFolder,
                      openModalVisualizationBlockedFiles,
                      setOpenFileVisualizationLinkStatus,
                      onOpenHistoryDrawer: handleOpenHistoryDrawer
                    })}
                    dataSource={filteredArchives?.map((fileData, index) => ({
                      ...fileData,
                      key: index
                    }))}
                    rowClassName={styles['file-list-row']}
                    expandable={{
                      expandIcon: () => false,
                      expandedRowKeys: activeRow,
                      expandedRowRender: (record, index) => {
                        if (!record.IsFolder && record.FileInfo) {
                          if (stagesFeatureValidate) {
                            return (
                              <NewEdicaoNomenclatura
                                record={record}
                                nomenclature={nomenclature}
                                obra={Obra}
                                constructionSiteDisciplinesId={ConstructionSiteDisciplinesId}
                                isLoadingRenameFile={isLoadingRenameFile}
                                text={record.Name}
                                islistEdit
                                index={index}
                                onSubmit={onSubmit}
                                onCancel={() => setActiveRow([])}
                              />
                            );
                          }
                          return (
                            <EdicaoNomenclatura
                              record={record}
                              nomenclature={nomenclature}
                              obra={Obra}
                              onSubmit={onSubmit}
                              isLoadingRenameFile={isLoadingRenameFile}
                              text={record.Name}
                              islistEdit
                              onCancel={() => setActiveRow([])}
                              index={index}
                            />
                          );
                        }
                        return null;
                      }
                    }}
                    rowKey={(record: any) =>
                      record.Identifier.ApiId || record.Identifier.ItemId
                    }
                    rowSelection={rowSelection}
                  />
                </ConfigProvider>
              </FileDrop>
            )
          ) : (
            <FileDrop
              dragInfo={BreadCrumbItem.Name}
              onDropFiles={(files) =>
                updateUploadState(
                  files,
                  match,
                  Obra,
                  BreadCrumbItem,
                  dispatch,
                  ConstructionSiteDisciplinesId,
                  userInfo?.UserStoreItem.MaxQtyUploadFiles ||
                  userInfo?.UserStoreItem.StorePlan?.MaxQtyUploadFiles,
                  openModalUpgradeUpload,
                  folderName,
                  DisciplineName
                )
              }
              maxQtyUploadFiles={
                userInfo?.UserStoreItem.MaxQtyUploadFiles ||
                userInfo?.UserStoreItem.StorePlan?.MaxQtyUploadFiles
              }
              limitUpload={
                userInfo?.UserStoreItem.MaxQtyUploadFiles ||
                userInfo?.UserStoreItem.StorePlan?.MaxQtyUploadFiles
              }
            >
              <div className={styles['emptyListWrap']}>
                <img src={emptyListImage} alt="Pasta vazia" />
                <span className={styles['title']}>
                  Você ainda não possui arquivos nesta pasta, arraste para fazer upload
                </span>
              </div>
            </FileDrop>
          )}
        </div>
        {showModalInfoFiles && (
          <AvisoTamanhoEQuantidadeArquivos
            visible={showModalInfoFiles}
            onConfirm={() =>
              dispatch(ListagemArquivosActions.setShowModalInfoFiles(false))
            }
          />
        )}
        {showModalDeleteFiles && (
          <ExclusaoArquivos
            isLoading={isLoading}
            visible={showModalDeleteFiles}
            dispatch={dispatch}
            onCancel={() =>
              dispatch(ListagemArquivosActions.setShowModalDeleteFiles(false))
            }
            deleteFilesRequest={deleteFilesRequest}
          />
        )}
        {showModalCreateFolder && (
          <NovaPasta
            isLoading={isLoading}
            visible={showModalCreateFolder}
            onCancel={() =>
              dispatch(ListagemArquivosActions.setShowModalCreateFolder(false))
            }
            onConfirm={createFolder}
          />
        )}
        {showModalLimitRenderFiles && (
          <ProgressRenderBar
            value={userLimitsActive?.QtyRenderedFiles || userInfo?.QtyRenderedFiles || 0}
            maxLimits={userInfo?.UserStoreItem.StorePlan?.MaxQtyRenderedFiles}
            visible={showModalLimitRenderFiles}
            role={userInfo?.CurrentRoleFk}
            onCancel={() => setShowModalLimitRenderFiles(false)}
            openFileAction={openFileAction}
            userInfo={userInfo}
            currentTenant={currentTenant}
            disciplineName={DisciplineName}
          />
        )}
        {showModalRenderUpgrade && (
          <AvisoRenderizacaoUpgrade
            onConfirm={handleUpgradePlan}
            visible={showModalRenderUpgrade}
            role={userInfo?.CurrentRoleFk}
            onCancel={() => setShowModalRenderUpgrade(false)}
          />
        )}
        {showModalVisualizationBlockedFiles && (
          <VisualizacaoBloqueadosModal
            visible={showModalVisualizationBlockedFiles}
            onClose={() => closeModalVisualizationBlockedFiles()}
            onConfirm={() => handleVisualizationBlockedFile()}
            hasPermission={hasPermissionVisualizationBlockedFiles}
          />
        )}
        {showModalUpgradeUpload && (
          <AvisoUploadUpgrade
            visible={showModalUpgradeUpload}
            onCancel={() => setShowModalUpgradeUpload(false)}
            role={userInfo?.CurrentRoleFk}
            planFree={planFree}
            onConfirm={handleUpgradePlan}
            limitUpload={
              userInfo?.UserStoreItem.MaxQtyUploadFiles ||
              userInfo?.UserStoreItem.StorePlan?.MaxQtyUploadFiles
            }
          />
        )}
        <input
          id="uploadFiles"
          type="file"
          hidden
          multiple
          onChange={(e) => {
            updateUploadState(
              e.currentTarget.files,
              match,
              Obra,
              BreadCrumbItem,
              dispatch,
              ConstructionSiteDisciplinesId,
              userInfo?.UserStoreItem.MaxQtyUploadFiles ||
              userInfo?.UserStoreItem.StorePlan?.MaxQtyUploadFiles,
              openModalUpgradeUpload,
              folderName,
              DisciplineName
            );
            e.currentTarget.value = '';
          }}
        />
        <DownloadModal
          platform={Obra.Api}
          urlFolder={CloudServiceFolderUrl}
          onTrackDownloadMultiple={handleTrackDownloadMultiple}
        />
        {showModalDownloadBlockedFiles && (
          <DownloadBloqueadosModal
            visible={showModalDownloadBlockedFiles}
            blockedParams={blockedParams}
            onClose={() => setShowModalDownloadBlockedFiles(false)}
            onConfirm={() => handleDowload()}
          />
        )}
      </PageHeader>
    </>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.listagemArquivos,
  ...state.auth,
  isLoading: state.listagemArquivos.isLoading,
  TenantUsers: state.tenant.TenantUsers,
  nomenclature: state.nomenclatura.NomenclatureData,
  notificationsMap: state.notification.notificationsMap,
  isLoadingFilesObsoleteList: state.listagemArquivos.isLoadingFilesObsoleteList,
  openLink: state.viewer.openFileLink,
  statusUpdateStatus: state.updateStatus.status,
  dateUpdateStatus: state.updateStatus.lastUpdate,
  isLoadingUpdateStatus: state.updateStatus.isLoading,
  isShellCollapsed: state.obra.isCollapsed,
  listArchivesNewDisciplineNoCache: state.disciplina.listArchivesNewDisciplineNoCache
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IListagemArquivos;

export default connector(ListagemArquivos);
