import { FC } from "react";
import { ITopicDnDResponse } from "Data/interfaces/Activities/IDragAndDropDataFormat";
import LoadingCommentsActivity from "Components/UI/CustomLoading/LoadingCommentsActivity";
import { Virtuoso } from "react-virtuoso";
import Card from "./components/Card";
import Input from "./components/Input";
import Empty from "./components/Empty";
import { Icon } from "Components/UI";
import Button from "Components/UI/Button";
import { history } from "Store";
import AccessPermission from "Utils/AcessPermission";
import DeleteModal from "./components/Delete";
import useComments from "./useComments";
import { Content, InputContainer } from "./styles";

interface IComments {
  updateActivity: ITopicDnDResponse | null;
  loadingExternalLink: boolean;
  workComment: string | null;
}

const Comments: FC<IComments> = ({
  updateActivity,
  loadingExternalLink,
  workComment,
}) => {
  const {
    activeComment,
    isLoadingComments,
    isActionLoadingComent,
    isLoadingShareLinkComment,
    userInfo,
    theme,
    virtuosoRef,
    scrollListenerRef,
    copyingId,
    commentId,
    showDeleteModal,
    editComment,
    hasUpdate,
    handleSubmit,
    handleCopyLink,
    handleEdit,
    handleDelete,
    confirmDelete,
    handleClose,
    setCommentId,
    handleScrollToEnd,
    setVisibleRange,
  } = useComments({ updateActivity, workComment });

  const loading = isLoadingComments || loadingExternalLink;
  const createPermission = !AccessPermission.createCommentPermission(
    userInfo,
    updateActivity?.TopicAssignedUsers,
  );

  return (
    <>
      <Content loading={loading}>
        {loading && (
          <LoadingCommentsActivity multiple={8} />
        )}
        {!loading && activeComment.comments.length > 0 && (
          <Virtuoso
            ref={virtuosoRef}
            style={{ flex: 1 }}
            totalCount={activeComment.comments.length}
            data={activeComment.comments}
            itemContent={(index, comment) => (
              <Card
                key={comment.Id}
                item={comment}
                first={index === 0}
                isOwner={userInfo?.Id === comment.CreationAuthorFk}
                loadingShareLink={copyingId === comment.Id &&
                  isLoadingShareLinkComment
                }
                active={commentId === comment.Id}
                theme={theme}
                userInfo={userInfo}
                onCopyLink={handleCopyLink}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            )}
            alignToBottom={true}
            followOutput="smooth"
            onScroll={() => {
              if (!!commentId && scrollListenerRef.current) {
                scrollListenerRef.current = false;
                history.push('?');
                setCommentId(null);
              }
            }}
            rangeChanged={setVisibleRange}
          />
        )}
        {!loading && activeComment.comments.length === 0 && (
          <Empty />
        )}
        {hasUpdate && (
          <div className="containerReload">
            <Button
              type="primary"
              className="btnReload"
              onClick={handleScrollToEnd}
            >
              Novos comentários
              <Icon
                icon="downArrow"
                customSize={16}
                className="iconDownArrow"
              />
            </Button>
          </div>
        )}
      </Content>
      <InputContainer>
        <Input
          disabled={createPermission}
          loading={isActionLoadingComent}
          onSubmit={handleSubmit}
        />
      </InputContainer>
      {showDeleteModal && editComment && (
        <DeleteModal
          visible={showDeleteModal}
          loading={isActionLoadingComent}
          onConfirm={confirmDelete}
          onCancel={handleClose}
        />
      )}
    </>
  );
};

export default Comments;
