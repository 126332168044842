import {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ListContainer from '../Containers/ListContainer/ListContainer';
import { Icon } from '../Icon';
import Form from '../Form/Form';
import TextArea from '../Textarea';
import {
  VersionFieldEnumLabel,
  SeparatorsData,
  VersionFieldEnum,
} from '../../../Data/interfaces/Nomenclatura/INomenclatureData';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import schema, { createSchema } from './UploadFiles.schema';
import { IUploadFileData } from '../../../Data/interfaces/Upload/IUploadFileData';
import { sizeFileRangeToInitials } from '../../../Utils/toolBox';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { iconFileByFilename } from '../../../Utils/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { ListagemArquivosActions } from '../../../Store/ListagemArquivos/ListagemArquivos.actions';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import RadioButtons from '../RadioButtons';
import { NomenclaturaActions } from '../../../Store/Nomenclatura/Nomenclatura.actions';
import { getTheme } from 'Store/MultiDomain/MultiDomain.selector';
import { history } from 'Store';
import SelectStage from '../SelectStage';
import OptStage from '../SelectStage/Opt';
import { IObraData } from 'Data/interfaces/Obra/IObraData';
import { IStage } from 'Data/interfaces/Obra/IObraStages';
import FieldControled from '../FieldControled';
import { isObjectEmpty } from 'Utils/ObjectUtils';
import {
  ConfirmButton,
  FileItemWrapper,
  FilenameAndEditWrapper,
  FormWrapper,
  NewFileNameOptionsWrap,
  NomenclatureFieldsWrapper,
  OpenOriginFolder,
  RemoveFileButton,
} from './styles';

export interface IStageFileItem {
  index: number;
  uploadFile: IUploadFileData;
  isLoading: boolean;
  testShowEdit?: boolean;
  obra: IObraData;
  stages: IStage[];
  removeFile: (index: number) => void;
  changeFilename: (index: number, name: string) => void;
}

const StageFileItem: FC<IStageFileItem> = ({
  index,
  uploadFile,
  isLoading,
  testShowEdit,
  obra,
  stages,
  removeFile,
  changeFilename,
}) => {
  const dispatch = useDispatch();
  const theme = useSelector(getTheme);

  const [isShowEdit, setIsShowEdit] = useState(
    testShowEdit ? testShowEdit : false
  );
  const [newNameFileExistSelected, setNewNameFileExistSelected] = useState<string | number | undefined>();
  const [acronymStages, setAcronymStages] = useState<string[]>([]);

  const showEdit = () => setIsShowEdit(true);

  const checkUpload = uploadFile?.checkUploadFileInfo;

  const propsNomenclatureVersionFields = useMemo(() => {
    if (
      obra?.VersionConfig?.VersionFields &&
      obra.VersionConfig.VersionFields?.length > 0
    ) {
      return obra.VersionConfig.VersionFields?.sort((a, b) => a.Ordem - b.Ordem);
    }
    return [];
  }, [obra.VersionConfig.VersionFields]);

  useEffect(() => {
    if (checkUpload) {
      setNewNameFileExistSelected(checkUpload?.FileNameNewRevision);
    }
  }, [checkUpload]);

  useEffect(() => {
    if (stages?.length > 0) {
      const data: string[] = [];
      for (const stage of stages) {
        if (stage.Acronym) {
          data.push(stage.Acronym);
        }
      }
      setAcronymStages(data);
    }
  }, [stages]);

  if (!propsNomenclatureVersionFields) {
    dispatch(NomenclaturaActions.getNomenclature());
  }

  const hasNomenclatureError = () => {
    return checkUpload && checkUpload.HasInconsistencia;
  };

  const getEditForms = () => {
    if (isShowEdit && hasNomenclatureError() && checkUpload && checkUpload.HasInconsistencia) {
      return nomenclatureForm;
    }

    if (checkUpload && checkUpload.FileNameExist) {
      return existFileForm;
    }

    if (uploadFile.updateFileInfo && !uploadFile.updateFileInfo.finished) {
      return descriptionForm;
    }

    return '';
  };

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: (checkUpload?.Division && propsNomenclatureVersionFields?.map((field, indexVersionField) => ({
      [`field${field.Ordem}`]: checkUpload?.Division[indexVersionField] || '',
      [`separator${field.Ordem}`]: SeparatorsData.get(field.Separador)?.Value,
    }))) || undefined,
    resolver: yupResolver(createSchema(
      propsNomenclatureVersionFields,
      obra.ValidateStagesInNomenclature
        ? acronymStages
        : undefined
    ))
  });

  const handleChangeValue = useCallback((
    field: string,
    value: string = '',
  ) => {
    setValue(field, value);
  }, [setValue]);

  useEffect(() => {
    if (
      checkUpload?.Division &&
      propsNomenclatureVersionFields &&
      propsNomenclatureVersionFields?.length > 0
    ) {
      propsNomenclatureVersionFields.forEach((field, indexVersionField) => {
        handleChangeValue(`field${field.Ordem}`, checkUpload?.Division[indexVersionField]);
        handleChangeValue(`separator${field.Ordem}`, SeparatorsData.get(field.Separador)?.Value);
      })
      trigger();
    }
  }, [propsNomenclatureVersionFields, checkUpload, handleChangeValue, trigger]);

  const nomenclatureSubmit = (data: any) => {
    const fields = Object.keys(data);
    let filename = '';
    for (let i = 0; i < fields.length / 2; i++) {
      filename += `${data[`field${i}`]}${data[`separator${i}`]}`;
    }
    const splittedValues = uploadFile.file.name.split('.');
    const extension = splittedValues[splittedValues.length - 1];
    filename = `${filename.slice(0, -1)}.${extension}`;

    changeFilename(index, filename);
    reset();
  };

  const getWidthInput = (label: string) => {
    const width = label.length * 8.5;
    return width < 90 ? undefined : { minWidth: width } as CSSProperties;
  }

  const existFileForm = (
    <NewFileNameOptionsWrap>
      <div className="titleButtonWrap">
        <span className="existFileTitle">
          Nome do arquivo já existe, selecione uma opção:
        </span>
        <ConfirmButton
          type='submit'
          isnewfilename="true"
          onClick={() => changeFilename(index, String(newNameFileExistSelected))}
        >
          <Icon icon='confirmacao' customSize={26} className="confirmIcon" />
        </ConfirmButton>
      </div>
      <div className="radioButtonsWrap">
        <RadioButtons
          options={[
            {
              value: checkUpload?.FileNameNewRevision || '',
              label: (
                <div className="newFileNameOption">
                  <div>{checkUpload?.FileNameNewRevision}</div>
                  <div className="titleOption">Nova revisão</div>
                </div>
              ),
            },
            {
              value: checkUpload?.FileNameCopy || '',
              label: (
                <div className="newFileNameOption">
                  <div>{checkUpload?.FileNameCopy}</div>
                  <div className="titleOption">Cópia</div>
                </div>
              ),
            },
          ]}
          onSelect={setNewNameFileExistSelected}
          defaultValue={checkUpload?.FileNameNewRevision}
        />
      </div>
    </NewFileNameOptionsWrap>
  );

  const nomenclatureForm = (
    <form onSubmit={handleSubmit(nomenclatureSubmit)}>
      <FormWrapper>
        <NomenclatureFieldsWrapper isstory={testShowEdit}>
          {propsNomenclatureVersionFields.map((field, indexVersionField) => {
            const isStageValidation = obra.ValidateStagesInNomenclature &&
              field.CampoVersaoId === VersionFieldEnum.Fase;

            return (
              <span
                key={`fields${indexVersionField}`}
                style={getWidthInput(field.CampoPersonalizado || VersionFieldEnumLabel.get(field.CampoVersaoId) || '')}
                className="fieldsWrapper"
              >
                {isStageValidation && (
                  <SelectStage
                    key={`field${indexVersionField}`}
                    name={`field${field.Ordem}`}
                    control={control}
                    value={getValues(`field${field.Ordem}`)}
                    label={field.CampoPersonalizado
                      || VersionFieldEnumLabel.get(field.CampoVersaoId)
                      || ''}
                    opts={stages?.map((stage) => ({
                      key: stage.Acronym || '',
                      label: (
                        <OptStage
                          left={stage.Acronym || ''}
                          separator={stage.Acronym ? '|' : ''}
                          right={stage.Name}
                        />
                      ),
                      labelSearch: stage.Acronym || '',
                    }))}
                    emptyLabel={
                      <OptStage
                        left="Nenhuma etapa encontrada."
                        isEmpty
                      />
                    }
                    trigger={trigger}
                    onChangeValue={(value) => handleChangeValue(`field${field.Ordem}`, value)}
                  />
                )}
                {!isStageValidation && (
                  <FieldControled
                    key={`field${indexVersionField}`}
                    name={`field${field.Ordem}`}
                    control={control}
                    label={field.CampoPersonalizado
                      || VersionFieldEnumLabel.get(field.CampoVersaoId)
                      || ''}
                    style={getWidthInput(field.CampoPersonalizado || VersionFieldEnumLabel.get(field.CampoVersaoId) || '')}
                    className="field"
                    height="30px"
                    trigger={trigger}
                  />
                )}
                <FieldControled
                  key={`separator${indexVersionField}`}
                  name={`separator${field.Ordem}`}
                  control={control}
                  label=''
                  type='hidden'
                  height="30px"
                  trigger={trigger}
                />
              </span>
            )
          })}
        </NomenclatureFieldsWrapper>
        <ConfirmButton
          type='submit'
          disabled={!isObjectEmpty(errors)}
        >
          <Icon icon='confirmacao' customSize={26} className="confirmIcon" />
        </ConfirmButton>
      </FormWrapper>
    </form>
  );

  const descriptionSubmit = (data: any) => {
    if (uploadFile.updateFileInfo) {
      if (!data.description) {
        uploadFile.updateFileInfo.notShowToast = true;
      }
      uploadFile.updateFileInfo.Description = data.description;
      dispatch(ListagemArquivosActions.updateFileInfo([uploadFile.updateFileInfo]));
    };
  }

  const descriptionForm = (
    <Form onSubmit={descriptionSubmit} schema={schema}>
      <FormWrapper isstory={testShowEdit} isdescription>
        <TextArea
          name='description'
          label='Descrição do arquivo'
          placeholder='Insira uma descrição para o arquivo'
          style={{ height: 91 }}
        />
        <ConfirmButton type='submit' isdescription="true">
          <Icon icon='confirmacao' customSize={26} className="confirmIcon" />
        </ConfirmButton>
      </FormWrapper>
    </Form>
  );

  const handleOpenFolder = (uploadFile: IUploadFileData) => {
    if (!uploadFile.uploadBreadcrumbFile) return;

    history.push(`/obras/filelist/${uploadFile.csId}/${uploadFile.uploadBreadcrumbFile.fileParentId}${uploadFile.uploadBreadcrumbFile?.fileParentBreadcrumbId ? `?breadCrumbId=${uploadFile.uploadBreadcrumbFile.fileParentBreadcrumbId}` : ''}`);
  };

  if (uploadFile.removed) {
    return <></>;
  }

  return (
    <ListContainer height={'auto'}>
      <FileItemWrapper>
        <div className="fileItemDescriptionWrapper">
          <Icon
            icon={iconFileByFilename(uploadFile.file.name)}
            color={hasNomenclatureError() ? 'vermelho' : 'cinzaPadrao'}
            customSize={24} />
          <FilenameAndEditWrapper showedit={`${uploadFile.updateFileInfo && !uploadFile.updateFileInfo.finished}`}>
            <div className="filenameWrapper">
              <div className="fileName">
                {hasNomenclatureError() || uploadFile.uploadError ? (
                  <>
                    <span className="hasError">
                      {uploadFile.newFileName || uploadFile.file.name}
                    </span>
                    {hasNomenclatureError() ? (
                      <span className="hasError">Nomeclatura inválida</span>
                    ) : (
                      <span className="hasError">Erro ao enviar o arquivo</span>
                    )}
                  </>
                ) : (
                  <span>
                    {uploadFile.newFileName || uploadFile.file.name}
                  </span>
                )}

              </div>
              {uploadFile.isSending ? (
                <span className="fileSize">
                  {sizeFileRangeToInitials(uploadFile.sentSize, uploadFile.totalSize)}
                </span>
              ) : ''}
            </div>
            {hasNomenclatureError() && !isShowEdit && (
              <div className="editButton" onClick={showEdit}>
                <Icon icon='editar' color='primary' />
              </div>
            )}
          </FilenameAndEditWrapper>
          <div className="fileItemFooterWrapper">
            {hasNomenclatureError() && isShowEdit && (
              <RemoveFileButton onClick={() => removeFile(index)}>
                <Icon icon='cancelar' color='cinzaPadrao' customSize={10} />
              </RemoveFileButton>
            )}
            {uploadFile.isSending && (!uploadFile.updateFileInfo?.finished || uploadFile.updateFileInfo?.finished === undefined) ? (
              <CircularProgressbar
                className="loading"
                strokeWidth={14}
                styles={buildStyles({
                  pathColor: theme.colors.primary.primary,
                  trailColor: theme.colors.secondary.secondary
                })}
                value={uploadFile.sentSize / uploadFile.totalSize}
                maxValue={1}
              />
            ) : <></>}
            {uploadFile.updateFileInfo?.finished ? (
              <OpenOriginFolder
                onClick={() => handleOpenFolder(uploadFile)}
              >
                <Icon icon='arquivar' customSize={11} className="openCloudServiceIcon" />
              </OpenOriginFolder>
            ) : ''}
            {!(uploadFile.isSending || uploadFile.updateFileInfo?.finished || uploadFile.uploadCompleted || hasNomenclatureError() || checkUpload?.FileNameExist || uploadFile.uploadError) ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    rev=""
                    style={{ fontSize: 20 }}
                    color={theme.colors.primary.primary}
                    spin
                  />
                }
              />
            ) : ''}
          </div>
        </div>

        {getEditForms()}
      </FileItemWrapper>
    </ListContainer>
  );
};

export default StageFileItem;
