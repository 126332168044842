import { FC } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";

interface ISpinCustom {
  color?: string;
  size?: number;
  marginRigth?: number;
}

const SpinCustom: FC<ISpinCustom> = ({
  color,
  size,
  marginRigth,
}) => {
  const theme = useSelector(getTheme);

  const antIcon = (
    <LoadingOutlined
      rev=""
      color={color ? color : theme.colors.primary.primary}
      style={{
        color: color ? color : theme.colors.primary.primary,
        fontSize: size ? size : 22,
        marginRight: marginRigth ? marginRigth : 0,
      }}
      spin
    />
  )

  return <Spin indicator={antIcon} />;
}

export default SpinCustom;
