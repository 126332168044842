import { FC, memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { ActivitiesActions } from 'Store/Activities/Activities.actions';
import { ICommentApiResponse } from 'Data/interfaces/Activities/IComments';
import { IUserInforResponse } from 'Data/interfaces/Auth/IUserInfoResponse';

export interface ICommentSignalR {
  topicId: string;
  userInfo?: IUserInforResponse;
}

const CommentSignalR: FC<ICommentSignalR> = ({ topicId, userInfo }) => {
  const dispatch = useDispatch();

  const [connection, setConnection] = useState<HubConnection>();

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_BASE_API_URL?.toString().replace("/api", "")}/hubs/topics?topicId=${topicId}`
      )
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    newConnection.serverTimeoutInMilliseconds = 100000;
    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          connection.on('NewComment', (data: string) => {
            const response: ICommentApiResponse = JSON.parse(data);

            if (
              userInfo?.Id &&
              (response?.CreationAuthorFk || response?.CreationAuthor?.Id) &&
              userInfo.Id !== response?.CreationAuthorFk &&
              userInfo.Id !== response?.CreationAuthor?.Id
            ) {
              dispatch(ActivitiesActions.commentSignalRHasUpdate(response));
            }
          });
        })
        .catch((e) => console.log('Connection failed: ', e));
    }
  }, [connection]);

  return <></>;
};

export default memo(CommentSignalR, () => true);
