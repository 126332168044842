import { FC, useState } from "react";
import { Icon } from "Components/UI";
import Button from "Components/UI/Button";
import appStorage from 'Utils/AppStorage';
import { Container } from "./styles";
import { useFeatureFlag } from "Hooks/useFeatureFlag";
import VideoEx from "Components/VideoEx";

export const CLIENT_STAGE_CTA_CLOSED_KEY = "CLIENT_STAGE_CTA_CLOSED_KEY";

const CTA: FC = () => {
  const ctaStagesFeatureFlag = useFeatureFlag('cta-stages-feature');
  const clientClosedCta = appStorage.GetItem(CLIENT_STAGE_CTA_CLOSED_KEY, 'local');
  const ctaStagesFeatureEnable = ctaStagesFeatureFlag.enabled && !clientClosedCta;
  const ctaStagesFeatureUrl = (!!ctaStagesFeatureFlag?.value &&
    typeof ctaStagesFeatureFlag.value === 'string' &&
    !['control', 'test'].includes(ctaStagesFeatureFlag.value))
    ? ctaStagesFeatureFlag.value
    : (process.env.REACT_APP_DEMO_VIDEO_VIEWER || '');

  const [visible, setVisible] = useState(ctaStagesFeatureEnable);
  const [showVideo, setShowVideo] = useState(false);

  const handleClose = () => {
    appStorage.SetItem(CLIENT_STAGE_CTA_CLOSED_KEY, 'true', 'local');
    setVisible(false);
  };

  if (visible) {
    return (
      <>
        <Container>
          <div className="headerStageCta">
            <span className="badge">Novo</span>
            <Icon
              icon="cancelar"
              customSize={12}
              className="closeIcon"
              onClick={handleClose}
            />
          </div>

          <div className="mainStageCta">
            <span className="title">Controle de etapas</span>
            <span className="description">
              Saiba como configurar as etapas da sua obra e utilizar elas para validação de nomenclatura e atividades.
            </span>

            <Button
              type="primary"
              className="button"
              onClick={() => setShowVideo(true)}
            >
              <Icon
                icon="play"
                customSize={16}
                className="playIcon"
              />
              Assistir tutorial
            </Button>
          </div>
        </Container>

        {showVideo && (
          <VideoEx
            visible={showVideo}
            url={ctaStagesFeatureUrl}
            onCancel={() => setShowVideo(false)}
          />
        )}
      </>
    );
  }

  return null;
};

export default CTA;
