import React, { Dispatch, SetStateAction } from 'react';
import { IBreadcrumbItem } from '../../Components/UI/BreadCrumb';
import {
  ListagemArquivosActions,
  ListagemArquivosActionUnion
} from '../../Store/ListagemArquivos/ListagemArquivos.actions';
import { ColumnProps } from 'antd/lib/table';
import { Icon } from '../../Components/UI';
import Field from '../../Components/UI/Field';
import { INomenclatureData } from '../../Data/interfaces/Nomenclatura/INomenclatureData';
import { iconFileByExtension } from '../../Utils/Icons';
import {
  IFileData,
  FileStatusEnumLabel,
  FileStatusEnumColor,
  IBreadCrumbItem,
} from '../../Data/interfaces/ListagemArquivos/IListFilesResponse';
import { sizeFileToInitials } from '../../Utils/toolBox';
import ColumnHeader from '../../Components/UI/Table/ColumnHeader';
import FilterModal from './Modals/FilterModal';
import { Field as FieldReact } from 'react-final-form';
import Tooltip from '../../Components/UI/Tooltip';
import {
  setTag,
  sortList,
  openFolder,
  openFile,
  getFilterOptions,
  getSecondPlanUploadFiles
} from './ListagemArquivos.helpers';
import { IListagemArquivosFilters } from '../../Store/ListagemArquivos/interfaces/IListagemArquivosState';
import { IObraData } from '../../Data/interfaces/Obra/IObraData';
import { IBreadCrumbItem as IBreadCrumbItemResponse } from '../../Data/interfaces/ListagemArquivos/IListFilesResponse';
import { Dropdown, Menu, Checkbox, Badge, Spin } from 'antd';
import { GetAvatar } from '../../Utils/generateThumbnail';
import { datetimeToString, dateToString } from '../../Utils/DateUtils';
import toastHandler from '../../Utils/toastHandler';
import AccessPermission from '../../Utils/AcessPermission';
import { IUserInforResponse } from 'Data/interfaces/Auth/IUserInfoResponse';
import { allOtherExtensions, extensions2dAnd3d } from 'Utils/ViewerUtils';
import { IOpenFileAction, IOpenVisualizationBlockedFileAction } from '.';
import EdicaoNomenclatura from './Components/EdicaoNomenclatura/EdicaoNomenclatura';
import { ISharedUser } from 'Data/interfaces/User/ISharedUser';
import InfoRender from 'Components/UI/InfoRender';
import { LoadingOutlined } from '@ant-design/icons';
import InfoQRCodeDropdown from './Components/InfoQRCodeDropdown';
import { ITenantData } from 'Data/interfaces/Tenant/ITenantData';
import Button from 'Components/UI/Button';
import NewEdicaoNomenclatura from './Components/NewEdicaoNomenclatura/NewEdicaoNomenclatura';
import styles from './ListagemArquivos.module.scss';

export interface IColumnsInput {
  fileList: IFileData[];
  filters: IListagemArquivosFilters;
  breadCrumbItem: IBreadCrumbItem;
  constructionSiteId: number;
  ConstructionSiteDisciplinesId: number;
  constructionSiteName: string;
  constructionSiteApiId: number;
  itemsNotification: (string | null)[];
  itemIdNotification: (string | null)[];
  dispatch: Dispatch<ListagemArquivosActionUnion>;
  planFree?: boolean;
  setShowModalLimitRenderFiles: Dispatch<SetStateAction<boolean>>;
  setShowModalRenderUpgrade: Dispatch<SetStateAction<boolean>>;
  setOpenFileAction: Dispatch<SetStateAction<IOpenFileAction>>;
  openModalVisualizationBlockedFiles?: (hasPermission: boolean) => void;
  setOpenFileVisualizationLinkStatus: Dispatch<SetStateAction<IOpenVisualizationBlockedFileAction>>;
  goRender?: boolean;
  maxRender?: number;
  activeRender: number;
  userInfo?: IUserInforResponse;
  currentTenant?: ITenantData | null;
  disciplineName?: string | null;
  showModalLimitRenderFiles?: boolean;
  onChangeNomenclatureInList: (record: any) => void;
  activeRow: string[];
  openLink: string;
  isLoadingFilesObsoleteList?: boolean;
  currentUserPrivileges?: ISharedUser;
  isObsoleteFolder?: boolean;
  onOpenHistoryDrawer: (fileData?: IFileData) => void;

  isLoadingRenameFile: boolean;
  nomenclature: INomenclatureData;
  onSubmit: (values: any) => void;
}

export interface IEditColumnsInput {
  editFormIndex: number[];
  isLoadingRenameFile: boolean;
  nomenclature: INomenclatureData;
  obra: IObraData;
  constructionSiteDisciplinesId: number;
  isStageValidation?: boolean;
  onSubmit: (values: any) => void;
  dispatch: Dispatch<ListagemArquivosActionUnion>;
}

export interface IRequestPlotColumnsInput {
  fileList: IRequestPlotColumns[];
  dispatch: Dispatch<ListagemArquivosActionUnion>;
  deleteRow: (index: number) => void;
  onChangeQtyValue: (value: number, index: number) => void;
  onChangeHasColor: (e: any) => void;
  onChangeAllHasColor: (e: any) => void;
  onChangeShowQRCode: (e: any) => void;
  onChangeAllShowQRCode: (e: any) => void;
}

export interface IRequestPlotColumns {
  Name: string;
  QtyCopies?: number;
  HasColor?: boolean;
  IsFolder?: boolean;
  Extension?: string;
  key: number;
  FileInfoFk?: number | null,
  FileApiId?: string | null,
  ShowQRCode?: boolean;
}

export const getColumnsTable: (input: IColumnsInput) =>
  Array<ColumnProps<any>> = ({ fileList, filters, breadCrumbItem, constructionSiteId, constructionSiteName, constructionSiteApiId, dispatch, itemsNotification, itemIdNotification, planFree, setShowModalRenderUpgrade, maxRender, activeRender, setShowModalLimitRenderFiles, onChangeNomenclatureInList, activeRow, setOpenFileAction, onSubmit, isLoadingRenameFile, nomenclature, openLink, userInfo, currentTenant, disciplineName, isLoadingFilesObsoleteList, currentUserPrivileges, isObsoleteFolder, openModalVisualizationBlockedFiles, setOpenFileVisualizationLinkStatus, ConstructionSiteDisciplinesId, onOpenHistoryDrawer }) => ([
    {
      dataIndex: 'Name',
      className: styles['columnNome'],
      width: '25%',
      title: (<ColumnHeader
        title={'Nome'}
        sorter={(sortDirection: boolean) => sortList('Name', sortDirection, fileList, dispatch)} />),
      render: (text: string, record: any, index: number) => {
        const findId = itemsNotification?.find(id => id === record.Identifier.ApiId);
        const findFileId = itemIdNotification?.find(id => id === record.Identifier.ApiId);

        const handleActionFile = (event: number) => {
          // if (AccessPermission.isEngOrConsult() && (record.Status === 0) && openModalVisualizationBlockedFiles) {
          //   return openModalVisualizationBlockedFiles(false);
          // }
          if (record.IsFolder) {
            return openFolder(
              constructionSiteId,
              ConstructionSiteDisciplinesId,
              record.Identifier.ApiId,
              constructionSiteApiId,
              breadCrumbItem.BreadCrumbItemId || breadCrumbItem.BreadCrumbItemIdDropbox,
              false,
              record.Name,
              event,
              dispatch
            )
          }
          if (!record.IsFolder) {
            if (maxRender && (activeRender < maxRender) && (extensions2dAnd3d.indexOf(record.Extension.toLocaleLowerCase()) !== -1) && (record.RenderingStatus === null || record.RenderingStatus === 0)) {
              setOpenFileAction({
                csId: constructionSiteId,
                ConstructionSiteDisciplinesId,
                record,
                constructionSiteName,
                breadCrumbId: breadCrumbItem.BreadCrumbItemId || breadCrumbItem.BreadCrumbItemIdDropbox,
                dispatch,
                constructionSiteApiId,
                event,
              })
              setShowModalLimitRenderFiles(true);
            } else if (maxRender && (activeRender >= maxRender) && (extensions2dAnd3d.indexOf(record.Extension.toLocaleLowerCase()) !== -1) && (record.RenderingStatus === null || record.RenderingStatus === 0)) {
              setShowModalRenderUpgrade(true);
            } else {
              openFile(
                constructionSiteId,
                ConstructionSiteDisciplinesId,
                record,
                constructionSiteName,
                breadCrumbItem.BreadCrumbItemId || breadCrumbItem.BreadCrumbItemIdDropbox,
                dispatch,
                constructionSiteApiId,
                event,
                userInfo,
                currentTenant,
                disciplineName,
                openModalVisualizationBlockedFiles,
                setOpenFileVisualizationLinkStatus,
              )
            }
            return
          }
        }
        const handleOpenLink = (): string | undefined => {
          // if (AccessPermission.isEngOrConsult() && (record.Status === 0) && openModalVisualizationBlockedFiles) {
          //   openModalVisualizationBlockedFiles(false);
          //   return; 
          // }
          if (openLink.length > 0) {
            if (record.IsFolder) {
              return openLink;
            }
            if (record.RenderingStatus === 1) {
              return openLink;
            }
            if (allOtherExtensions.indexOf(record.Extension.toLocaleLowerCase()) !== -1) {
              return openLink;
            }
          }
          return undefined;
        }

        return (
          <div className={styles['nameCollumn']}>
            {(!record.UploadRenderingStatus || record.UploadRenderingStatus.archiveStatus === 'finishedSuccess') ? (
              <Icon
                color={record.HasInconsistencia ? 'vermelho' : (activeRow[0] === (record.Identifier.ApiId || record.Identifier.ItemId) ? 'primary' : 'cinzaPadrao')}
                icon={record.IsFolder
                  ? 'arquivar'
                  : iconFileByExtension(record.Extension)}
                customSize={18} />
            ) : (
              getSecondPlanUploadFiles(record.UploadRenderingStatus)
            )}
            <Tooltip overlay={
              record.FileInfo?.Description
                ? (<div className={styles['descriptionTooltip']} >
                  <div className={styles['iconWrap']}>
                    <Icon icon='informacao' customColor='#B3C0CE' customSize={17} />
                  </div>
                  {record.FileInfo.Description}
                </div>)
                : ''}
            >
              <a
                href={handleOpenLink()}
                onContextMenu={(e) => {
                  handleActionFile(e.button)
                }}
                onClick={(e) => {
                  handleActionFile(e.button)
                }}
                title={text}
                className={`${styles['name']} ${record.HasInconsistencia ? styles['hasInconsistencia'] : (activeRow[0] === (record.Identifier.ApiId || record.Identifier.ItemId) ? styles['nameActive'] : '')}`}
              >
                {text}
                {findId || findFileId ? <Badge color="#FF3D57" className={styles['dot']} /> : null}
              </a>
            </Tooltip>
            {(!record.IsFolder && AccessPermission.editFiles(currentUserPrivileges) && !isObsoleteFolder) && <Icon
              color='primary'
              className={`${styles['iconEditInList']} ${activeRow[0] === (record.Identifier.ApiId || record.Identifier.ItemId) ? styles['iconEditInListActive'] : ''}`}
              icon='editar'
              onClick={() =>
                !AccessPermission.editFiles(currentUserPrivileges)
                  ? toastHandler.showError("Você não tem permissão para realizar esta ação. Solicite a permissão para seu coordenador ou administrador.")
                  : !isLoadingRenameFile
                    ? onChangeNomenclatureInList(record)
                    : undefined
              }
            />}
          </div>
        );
      },
    },
    {
      dataIndex: 'Extension',
      className: styles['columnTipo'],
      width: '8%',
      title: (
        <ColumnHeader
          title={'Tipo'}
          sorter={(sortDirection: boolean) => sortList('Extension', sortDirection, fileList, dispatch)}
          showFilter={() => dispatch(ListagemArquivosActions.setShowFilterTipo(true))}
        />
      ),
      onFilter: (value: any, record: any) => {
        if (!record.Extension) {
          return record;
        }

        return record.Extension?.toLowerCase() === value?.toLowerCase()
      },
      filterDropdown: (propsFilters: any) => {
        const showDataFilters = getFilterOptions(fileList, 'Extension', (x: string) => {
          if (!x) {
            return 'PASTA'
          }

          return x.trim().toLocaleUpperCase()
        });
        return <FilterModal
          propsFilter={propsFilters}
          tipoOptions={showDataFilters}
          showFilter={(visible: boolean) => dispatch(ListagemArquivosActions.setShowFilterTipo(visible))} />;
      },
      onFilterDropdownVisibleChange: (visible) => dispatch(ListagemArquivosActions.setShowFilterTipo(visible)),
      filterDropdownVisible: filters.showTipo,
      render: (text: string) => text ? text.toUpperCase() : '-'
    },
    {
      dataIndex: 'SizeInBytes',
      className: styles['columnTamanho'],
      width: '10%',
      title: (<ColumnHeader
        title={'Tamanho'}
        sorter={(sortDirection: boolean) => sortList('SizeInBytes', sortDirection, fileList, dispatch)} />),
      render: (text: number) => text ? sizeFileToInitials(text) : '-',
    },
    {
      title: (<ColumnHeader title={'Visualização'} showOrder={false} />),
      dataIndex: 'RenderingStatus',
      className: styles['columnVisualizacao'],
      width: '12%',
      render: (rederingStatus: number, record: any) => {
        return (
          <InfoRender
            status={rederingStatus}
            signalRender={record.RenderingSignalR}
            extension={record.Extension}
          />
        );
      },
    },
    {
      title: (<ColumnHeader
        title={'Versão'}
        sorter={(sortDirection: boolean) => sortList('Revision', sortDirection, fileList, dispatch)}
        showFilter={() => dispatch(ListagemArquivosActions.setShowVersions(true))}
      />),
      width: '15%',
      dataIndex: 'Versions',
      className: styles['columnVersao'],

      render: (versions: any, record) => {
        const antIcon = (
          <LoadingOutlined rev="" style={{ color: '#3547C8', fontSize: 16, margin: '4px 0 6px 0' }} spin />
        );

        if (
          (versions && versions.length) ||
          (isLoadingFilesObsoleteList && record.HasNomenclatureControl)
        ) {
          let menu: React.ReactNode;

          if (isLoadingFilesObsoleteList) {
            menu = (
              <Menu className={styles['loadingVersionDropdownWrapper']}>
                <Menu.Item className={styles['loadingVersionDropdown']}>
                  <div>
                    <Spin indicator={antIcon} />
                  </div>
                  <div>
                    <span>Carregando versões...</span>
                  </div>
                </Menu.Item>
              </Menu>
            );
          } else {
            menu = (
              <Menu>
                {
                  //aqui faz o map para renderizar todas as versões do arquivo em tela
                  versions.map((version: any, index: number) => (
                    // TODO Implementar a função de abrir arquivo
                    <Menu.Item key={index}>
                      {version.Revision}
                    </Menu.Item>
                  ))
                }
              </Menu>
            );
          }

          return (
            <Dropdown overlay={menu as any} trigger={['click']} placement='bottomLeft'>
              <span style={{ display: 'flex' }} className={styles['versionSelect']} >
                <span className={styles['revision']} >{record.Revision || '-'}</span>
                <Icon
                  className={styles['versionSelectIcon']}
                  icon='setaOrdenarAsc'
                  color='cinzaPadrao'
                  customSize={9} />
              </span>
            </Dropdown>
          );
        }

        return record.Revision || '-';
      },
      onFilter: (value: any, record: any) => (record.Revision === value.toLocaleUpperCase()),
      onFilterDropdownVisibleChange: (visible) => dispatch(ListagemArquivosActions.setShowVersions(visible)),
      filterDropdownVisible: filters.showVersions,
      filterDropdown: (propsFilters: any) => {
        const showDataFilters = getFilterOptions(fileList, 'Revision', (x: string) => x || '0');

        return <FilterModal
          propsFilter={propsFilters}
          tipoOptions={showDataFilters}
          showFilter={(visible: boolean) => dispatch(ListagemArquivosActions.setShowVersions(visible))} />;
      },
    },
    {
      title: (
        <ColumnHeader
          title={'Modificado'}
          sorter={(sortDirection: boolean) => sortList('LastUpdate', sortDirection, fileList, dispatch)}
          showFilter={() => dispatch(ListagemArquivosActions.setShowModificado(true, true))}
        />
      ),
      width: '15%',
      className: styles['columnModificado'],
      dataIndex: 'LastUpdate',
      onFilter: (value: any, record: any) => {
        const lastUpdate = new Date(record.LastUpdate).toLocaleDateString();
        return (lastUpdate === value || record.ModifiedBy === value);
      },
      onFilterDropdownVisibleChange: (visible) => dispatch(ListagemArquivosActions.setShowModificado(visible, false)),
      filterDropdownVisible: filters.showModificado.show,
      filterDropdown: (propsFilters: any) => {
        if (filters.showModificado.showData) {
          const showDataFilters = getFilterOptions(fileList, 'LastUpdate', (v: any) => new Date(v).toLocaleDateString());

          return <FilterModal
            width='250px'
            propsFilter={propsFilters}
            tipoOptions={showDataFilters}
            showFilter={(visible: boolean) => dispatch(ListagemArquivosActions.setShowModificadoData(visible))}
          />;
        }

        if (filters.showModificado.showUsers) {
          const showUsersFilters = getFilterOptions(fileList, 'ModifiedBy', undefined, (index) => index !== '-', (updateIndex) => (
            <div className={styles['filterModificadoPor']}>
              <GetAvatar name={updateIndex} shape='circle' size='small' />
              {updateIndex}
            </div>
          ));

          return <FilterModal
            width='250px'
            propsFilter={propsFilters}
            tipoOptions={showUsersFilters}
            showFilter={(visible: boolean) => dispatch(ListagemArquivosActions.setShowModificadoUsers(visible))}
          />;
        }

        if (filters.showModificado.showDropdown) {
          return <Menu className={styles['menu-container']}>
            <Menu.Item onClick={() => dispatch(ListagemArquivosActions.setShowModificadoData(true))}>
              <div className={`ant-menu-submenu ant-menu-submenu-vertical ${styles['menu']}`} >
                <p className={styles['text']}>Filtrar por data</p>
                <div className={`ant-menu-submenu-title ${styles['icon']}`}>
                  <i className='ant-menu-submenu-arrow'></i>
                </div>
              </div>
            </Menu.Item>
            <Menu.Item onClick={() => dispatch(ListagemArquivosActions.setShowModificadoUsers(true))}>
              <div className={`ant-menu-submenu ant-menu-submenu-vertical ${styles['menu']}`} >
                <p className={styles['text']}>Filtrar por usuário</p>
                <div className={`ant-menu-submenu-title ${styles['icon']}`}>
                  <i className='ant-menu-submenu-arrow'></i>
                </div>
              </div>
            </Menu.Item>
          </Menu>;
        }

        return <></>;
      },
      render: (text: string, record: any) => {
        return (
          <Tooltip overlay={modifiedTooltip(text, record, onOpenHistoryDrawer)}>
            <span className={styles['modifiedDate']} >
              {dateToString(new Date(text))}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <ColumnHeader
          title={'Status'}
          sorter={(sortDirection: boolean) => sortList('Status', sortDirection, fileList, dispatch)}
          showFilter={() => dispatch(ListagemArquivosActions.setShowFilterStatus(true))}
        />
      ),
      width: '10%',
      className: styles['columnStatus'],
      dataIndex: 'Status',
      onFilter: (value: any, record: any) => (record.Status !== null && record.Status.toString() === value),
      filterDropdown: (propsFilters: any) => {
        const showDataFilters = getFilterOptions(
          fileList,
          'Status',
          undefined,
          undefined,
          (x: number) => setTag(FileStatusEnumLabel[x], FileStatusEnumColor[x]));

        return <FilterModal
          hideSearch={true}
          propsFilter={propsFilters}
          tipoOptions={showDataFilters}
          showFilter={(visible: boolean) => dispatch(ListagemArquivosActions.setShowFilterStatus(visible))}
        />;
      },
      onFilterDropdownVisibleChange: (visible) => dispatch(ListagemArquivosActions.setShowFilterStatus(visible)),
      filterDropdownVisible: filters.showStatus,
      render: (status: number, record: any) => {
        status = status || 0;
        return (record.IsFolder ? '-'
          : setTag(FileStatusEnumLabel[status], FileStatusEnumColor[status]));
      },
    },
  ]);

export const getColumnsTableEdit: (input: IEditColumnsInput) =>
  Array<ColumnProps<any>> = ({ editFormIndex, onSubmit, isLoadingRenameFile, nomenclature, dispatch, obra, isStageValidation, constructionSiteDisciplinesId }) => ([{
    title: 'Nome',
    dataIndex: 'Name',
    render: (text, record, index) => {
      const toggleEditForm = () => {
        dispatch(ListagemArquivosActions.setEditFormIndex((index)));
      };

      return (
        <div className={styles['editColumnWrapper']} >
          <div onClick={() => !isLoadingRenameFile ? toggleEditForm() : undefined}
            className={`${styles['nameColumnEdit']} ${editFormIndex.includes((index)) ? styles['active'] : ''}`}>
            <Icon
              className={styles['iconFile']}
              color={record.HasInconsistencia ? 'vermelho' : 'cinzaPadrao'}
              icon={record.IsFolder
                ? 'arquivar'
                : iconFileByExtension(record.Extension)}
              customSize={18} />
            <span className={`${styles['name']} ${record.HasInconsistencia ? styles['hasInconsistencia'] : ''}`}>
              {text}
            </span>
            <Icon
              className={styles['iconEdit']}
              icon='editar'
            />
          </div>
          {(editFormIndex.includes((index))) && (
            <>
              {isStageValidation && (
                <NewEdicaoNomenclatura
                  record={record}
                  nomenclature={nomenclature}
                  obra={obra}
                  constructionSiteDisciplinesId={constructionSiteDisciplinesId}
                  onSubmit={onSubmit}
                  isLoadingRenameFile={isLoadingRenameFile}
                  text={text}
                  toggleEditForm={toggleEditForm}
                  index={index}
                />
              )}
              {!isStageValidation && (
                <EdicaoNomenclatura
                  record={record}
                  nomenclature={nomenclature}
                  obra={obra}
                  onSubmit={onSubmit}
                  isLoadingRenameFile={isLoadingRenameFile}
                  text={text}
                  toggleEditForm={toggleEditForm}
                  index={index}
                />
              )}
            </>
          )}
        </div>
      );
    },
  }]);

export const getColumnsRequestPlotTable:
  (input: IRequestPlotColumnsInput) => Array<ColumnProps<any>>
  = ({
    fileList,
    dispatch,
    deleteRow,
    onChangeQtyValue,
    onChangeHasColor,
    onChangeAllHasColor,
    onChangeShowQRCode,
    onChangeAllShowQRCode,
  }) => {
    return [
      {
        dataIndex: 'Name',
        title: 'Nome',
        className: styles['columnNome'],
        render: (text: string, record: any, index) => (
          <div className={styles['nameCollumn']}>
            <Field label='' type='hidden' name={`name${index}`} value={text} />
            <Icon
              color='cinzaPadrao'
              icon={iconFileByExtension(record.Extension)}
              customSize={18} />
            <span className={styles['name']} >
              {text}
            </span>
          </div>
        ),
      },
      {
        dataIndex: 'ShowQRCode',
        title: (
          <FieldReact name={`isQrCodeField`} type='checkbox'>
            {
              (propsField) => (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    name={`isQrCode`}
                    checked={fileList.every((file) => {
                      return file.Extension?.toLowerCase() === 'pdf' && file.ShowQRCode;
                    })}
                    indeterminate={fileList.some((file) => {
                      return file.Extension?.toLowerCase() === 'pdf' && file.ShowQRCode;
                    }) && !fileList.every((file) => {
                      return file.Extension?.toLowerCase() === 'pdf' && file.ShowQRCode;
                    })}
                    onChange={onChangeAllShowQRCode}
                  >
                    <span>QR Code</span>
                  </Checkbox>
                  <InfoQRCodeDropdown />
                </span>
              )
            }
          </FieldReact>
        ),
        className: styles['columnCor'],
        render: (text, record, index) => (
          <FieldReact name={`isQrCodeField${index}`} type='checkbox' >
            {
              (propsField) => (
                <Tooltip
                  placement="topLeft"
                  overlay={
                    record?.Extension?.toLowerCase() !== 'pdf'
                      ? 'O rastreamento via QR Code só está disponível para PDF.'
                      : undefined
                  }
                >
                  <Checkbox className={styles['corColumn']}
                    name={`isQrCode${index}`}
                    onChange={onChangeShowQRCode}
                    checked={text}
                    disabled={record?.Extension?.toLowerCase() !== 'pdf'}
                  >
                    <span>Rastrear</span>
                  </Checkbox>
                </Tooltip>
              )
            }
          </FieldReact>
        ),
      },
      {
        dataIndex: 'QtyCopies',
        title: 'Cópias',
        className: styles['columnCopias'],
        render: (text, record, index) => (
          <div className={styles['qtyCopiesColumn']} >
            <Field
              className={`qtyCopies ${styles['qtyCopiesField']}`}
              controledInput
              value={text}
              placeholder='Quantidade'
              name={`QtyCopies${index}`}
              onInput={(e) => onChangeQtyValue(Math.abs(parseInt(e) || 0), index)}
              type="number"
              parse={(value) => Math.abs(parseInt(value) || 0)}
              label=''
            />
          </div>
        ),
      },
      {
        dataIndex: 'HasColor',
        title: (
          <FieldReact name={`isTermsAcceptedField`} type='checkbox'>
            {
              (propsField) => (
                <Checkbox
                  name={`isTermsAccepted`}
                  checked={fileList.every((file) => file.HasColor)}
                  indeterminate={fileList.some((file) => file.HasColor) &&
                    !fileList.every((file) => file.HasColor)
                  }
                  onChange={onChangeAllHasColor}
                >
                  <span>Cor</span>
                </Checkbox>)
            }
          </FieldReact>
        ),
        className: styles['columnCor'],
        render: (text, record, index) => (
          <FieldReact name={`isTermsAcceptedField${index}`} type='checkbox' >
            {
              (propsField) => (
                <Checkbox className={styles['corColumn']}
                  name={`isTermsAccepted${index}`}
                  onChange={onChangeHasColor}
                  checked={text}
                >
                  <span>Colorido</span>
                </Checkbox>)
            }
          </FieldReact>
        ),
      },
      {
        title: ' ',
        className: styles['columnExclui'],
        render: (text, record, index) => (
          <span className={styles['deletePlotRowButton']} onClick={() => deleteRow(index)}>
            <Icon icon='excluir' />
          </span>
        ),
      },
    ];
  }

export const getBreadCrumbDefault: (breadCrumbItem: IBreadCrumbItemResponse) =>
  IBreadcrumbItem[] = (breadCrumbItem) => {
    const breadCrumb: IBreadcrumbItem[] = [
      {
        href: '',
        isLoading: true,
      },
      {
        href: '',
        isLoading: true,
      },
    ];

    let nextBCI: IBreadCrumbItem | undefined = breadCrumbItem;
    const breadCrumbFolders = [];
    do {
      breadCrumbFolders.push({
        href: '',
        isLoading: true,
      });
      nextBCI = nextBCI.PreviousItem;
    } while (nextBCI);

    breadCrumb.push(...breadCrumbFolders.reverse());

    return breadCrumb;
  };

export const getBreadCrumb: (
  obra: IObraData,
  breadCrumbItem: IBreadCrumbItemResponse,
  constructionSiteDisciplinesId: number,
  isObsoleteFolder?: boolean
) => IBreadcrumbItem[] = (
  obra,
  breadCrumbItem,
  constructionSiteDisciplinesId,
  isObsoleteFolder,
) => {
    const breadCrumb: IBreadcrumbItem[] = [
      {
        description: obra.Name,
        href: `/obras/detail/${obra.ConstructionSiteId}`,
      },
      {
        description: 'Arquivos',
        href: `/obras/detail/${obra.ConstructionSiteId}`,
      },
    ];

    let nextBCI: IBreadCrumbItem | undefined = breadCrumbItem;
    const breadCrumbFolders = [];
    do {
      breadCrumbFolders.push({
        description: nextBCI.Name,
        href: `/obras/filelist/${obra.ConstructionSiteId}/${nextBCI.Identifier}?obraApi=${obra.Api}${constructionSiteDisciplinesId ? `&ConstructionSiteDisciplinesId=${constructionSiteDisciplinesId}` : ''}${nextBCI.Name ? `&folderName=${nextBCI.Name}` : ''}${nextBCI.PreviousItem?.BreadCrumbItemId || nextBCI.PreviousItem?.BreadCrumbItemIdDropbox ? `&breadCrumbId=${nextBCI.PreviousItem?.BreadCrumbItemId || nextBCI.PreviousItem?.BreadCrumbItemIdDropbox}` : ''}`,
      });
      nextBCI = nextBCI.PreviousItem;
    } while (nextBCI);

    if (isObsoleteFolder) {
      breadCrumbFolders[0].href += `&isObsoleteFolder=${isObsoleteFolder}`;
    }

    breadCrumb.push(...breadCrumbFolders.reverse());
    return breadCrumb;
  };

const modifiedTooltip = (
  text: string,
  record: any,
  onOpenHistoryDrawer?: (fileData?: IFileData) => void,
) => (
  <div className={styles['tooltipModifiedBy']}>
    <div className={styles['title']}>
      Última atualização em
    </div>
    <div className={styles['value']}>
      {datetimeToString(new Date(text))}
    </div>
    {
      !record.ModifiedBy || record.ModifiedBy === '-'
        ? ''
        : (<>
          <div className={`${styles['title']} ${styles['margin-top']}`}>
            Realizada por
          </div>
          <div>
            <GetAvatar name={record.ModifiedBy} />
            <span className={`${styles['padding-left']} ${styles['value']}`}>
              {record.ModifiedBy}
            </span>
          </div>
        </>)
    }
    <Button
      type="text"
      className={styles['btnActionTooltip']}
      onClick={() => onOpenHistoryDrawer && onOpenHistoryDrawer(record)}
    >
      <Icon
        icon="history"
        customSize={18}
      />
      <span>Ver histórico</span>
    </Button>
  </div>
);
