import { FC, useEffect, useMemo, useState } from "react";
import { Icon, IconName } from "Components/UI";
import Modal from "Components/UI/Modal/Modal";
import { Checkbox, ConfigProvider, Spin } from "antd";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import Button from "Components/UI/Button";
import { LoadingOutlined } from "@ant-design/icons";
import { GetAvatar } from "Utils/generateThumbnail";
import { IStage } from "Data/interfaces/Obra/IObraStages";
import ConfirmDefault from "../ConfirmDefault";
import Ellipsis from "Components/UI/Ellipsis";
import {
  ConstructionSiteStageLibraryEnum,
  IConstructionSiteStageLibrary,
  IConstructionSiteStageLibraryItem,
  IConstructionSiteStageLibraryRequest,
  ImportedLibraryTypeEnum,
} from "Data/interfaces/Obra/IObraLibraries";
import {
  Body,
  BodyContent,
  Children,
  Container,
  Content,
  Footer,
  HeaderContent,
  Hr,
  Left,
  Main,
  MainContent,
  MainContentTop,
  Section,
  Title,
  Top,
} from "./styles";

interface OptionsDefault {
  title: string;
  children: IConstructionSiteStageLibrary[];
}

interface ISelectedItem {
  id: number;
  acronym: string;
  name: string;
  isDefault: boolean;
}

interface ILibrary {
  visible: boolean;
  csId: number;
  loading?: boolean;
  listLoading?: boolean;
  libraries: IConstructionSiteStageLibrary[];
  phases: IStage[];
  onSubmit: (values: IConstructionSiteStageLibraryRequest) => void;
  onCancel: () => void;
}

const iconNameTypeEnum: Record<ConstructionSiteStageLibraryEnum, IconName> = {
  [ConstructionSiteStageLibraryEnum.Standard]: 'coordly',
  [ConstructionSiteStageLibraryEnum.MarketStandard]: 'abnt',
  [ConstructionSiteStageLibraryEnum.ConstructionSite]: 'menuContexto',
};

const Library: FC<ILibrary> = ({
  visible,
  csId,
  loading,
  listLoading,
  libraries,
  phases,
  onSubmit,
  onCancel,
}) => {
  const theme = useSelector(getTheme);

  const [active, setActive] = useState(0);
  const [selecteds, setSelecteds] = useState<ISelectedItem[]>([]);
  const [showConfirmDefault, setShowConfirmDefault] = useState(false);
  const [submitValues, setSubmitValues] = useState<IConstructionSiteStageLibraryRequest | null>(null);

  const options = useMemo(() => {
    const data: OptionsDefault[] = [];

    if (libraries && libraries.length > 0) {
      const standardLibraries = libraries.filter(lib => {
        return lib.Type === ConstructionSiteStageLibraryEnum.Standard;
      });
      const marketLibraries = libraries.filter(lib => {
        return lib.Type === ConstructionSiteStageLibraryEnum.MarketStandard;
      });
      const constructionSiteLibraries = libraries.filter(lib => {
        return lib.Type === ConstructionSiteStageLibraryEnum.ConstructionSite;
      });

      if (standardLibraries.length > 0) {
        const opt: OptionsDefault = {
          title: 'Selecione um padrão de etapas',
          children: []
        };

        for (const lib of standardLibraries) {
          opt.children.push(lib);
        }

        data.push(opt);
      }

      if (marketLibraries.length > 0) {
        const opt: OptionsDefault = {
          title: 'Padrões do mercado',
          children: []
        };

        for (const lib of marketLibraries) {
          opt.children.push(lib);
        }

        data.push(opt);
      }

      if (constructionSiteLibraries.length > 0) {
        const opt: OptionsDefault = {
          title: 'Padrões de obras',
          children: []
        };

        for (const lib of constructionSiteLibraries) {
          opt.children.push(lib);
        }

        data.push(opt);
      }
    }

    return data;
  }, [libraries]);

  const optionActive = useMemo(() => {
    return libraries.find(opt => {
      return opt.ConstructionSiteStageLibraryId === active;
    });
  }, [active, libraries]);

  const optionActiveChildren = useMemo(() => {
    let dataDefault: IConstructionSiteStageLibraryItem[] = [];

    if (optionActive && optionActive?.Items && optionActive.Items?.length > 0) {
      dataDefault = optionActive.Items.sort((a, b) => a.Order - b.Order);
    }

    const allOpts: ISelectedItem[] = [];
    for (const item of dataDefault) {
      allOpts.push({
        id: item.ConstructionSiteStageLibraryItemId,
        acronym: item.Acronym,
        name: item.Name,
        isDefault: item.IsDefault,
      });
    }

    return {
      data: dataDefault,
      dataChildIds: allOpts,
      count: allOpts.length,
    };
  }, [optionActive]);

  const disabledImport = useMemo(() => {
    return loading || optionActiveChildren.data.every(opt => {
      return phases.some(phase => {
        return (phase.TopicStageId === opt.ConstructionSiteStageLibraryItemId) ||
          phase.Acronym?.toLowerCase() === opt.Acronym?.toLowerCase() ||
          phase.Name?.toLowerCase() === opt.Name?.toLowerCase();
      });
    })
  }, [loading, optionActiveChildren.data, phases]);

  const handleSubmit = (type: ImportedLibraryTypeEnum) => {
    if (active === 0 || selecteds.length === 0) return;

    const filteredSelecteds = selecteds.filter(item => {
      return !phases.some(phase => phase.TopicStageId === item.id);
    })
    const request: IConstructionSiteStageLibraryRequest = {
      constructionSiteId: csId,
      constructionSiteStageLibraryId: active,
      constructionSiteStageLibraryItems: filteredSelecteds.map(item => item.id),
      type,
    }

    const hasOtherDefault = filteredSelecteds.some(item => item.isDefault) &&
      phases.some(phase => {
        return phase.IsDefault && !filteredSelecteds.some(item => {
          return phase.TopicStageId === item.id ||
            phase.Acronym?.toLowerCase() === item.acronym?.toLowerCase() ||
            phase.Name?.toLowerCase() === item.name?.toLowerCase();
        });
      });

    if (hasOtherDefault) {
      setSubmitValues(request);
      setShowConfirmDefault(true);
    } else {
      onSubmit(request);
    }
  }

  const handleConfirmDefault = () => {
    if (!submitValues) return;

    onSubmit(submitValues);
  };

  const closeConfirmDefault = () => {
    setSubmitValues(null);
    setShowConfirmDefault(false);
  };

  const onCheck = (value: ISelectedItem) => {
    setSelecteds(prev => {
      if (prev.some(item => item.id === value.id)) {
        return prev.filter(item => item.id !== value.id);
      }

      return [...prev, value];
    })
  };

  const onCheckAll = () => {
    setSelecteds(prev => {
      if (prev.length === optionActiveChildren.count) {
        const exists = optionActiveChildren.dataChildIds.filter(item => {
          return phases.some(phase => {
            return phase.TopicStageId === item.id ||
              phase.Acronym?.toLowerCase() === item.acronym?.toLowerCase() ||
              phase.Name?.toLowerCase() === item.name?.toLowerCase();
          }) || (item.isDefault && !phases.some(phase => phase.IsDefault));
        });

        return [...exists];
      }

      return optionActiveChildren.dataChildIds;
    });
  };

  useEffect(() => {
    if (libraries && libraries.length > 0) {
      const standardLibrary = libraries.find(lib => {
        return lib.Type === ConstructionSiteStageLibraryEnum.Standard;
      });
      const currentActive = standardLibrary?.ConstructionSiteStageLibraryId ||
        libraries[0].ConstructionSiteStageLibraryId;

      setActive(currentActive);
    }
  }, [libraries]);

  useEffect(() => {
    if (active) {
      setSelecteds([]);
      onCheckAll();
    }
  }, [active]);

  return (
    <>
      <Modal
        visible={visible}
        width={750}
        footerButtons={null}
      >
        <Container>
          <Title>
            <span className="textTitle">Biblioteca de etapas</span>
            <Icon
              icon="cancelar"
              customSize={12}
              className="iconTitle"
              onClick={onCancel}
            />
          </Title>

          <Content>
            <Left>
              {options.map((opt, index) => (
                <Section key={opt.title}>
                  <span className="title">{opt.title}</span>
                  {opt.children.map(item => (
                    <Children
                      isactive={active === item.ConstructionSiteStageLibraryId}
                      onClick={() => setActive(item.ConstructionSiteStageLibraryId)}
                    >
                      {(item.IconName ||
                        item.Type === ConstructionSiteStageLibraryEnum.Standard ||
                        item.Type === ConstructionSiteStageLibraryEnum.MarketStandard) && (
                          <Icon
                            icon={item.IconName || iconNameTypeEnum[item.Type]}
                          />
                        )}
                      {item.Type === ConstructionSiteStageLibraryEnum.ConstructionSite && (
                        <GetAvatar
                          name={item.Name}
                          shape="circle"
                          size={20}
                          thumbType="mid"
                        />
                      )}
                      <Ellipsis
                        phrase={item.Name}
                        maxLength={17}
                        className="titleChildren"
                      />
                    </Children>
                  ))}
                  {index !== options.length - 1 && <Hr />}
                </Section>
              ))}
            </Left>

            <Main>
              {optionActive && (
                <Top>
                  <span className="title">{optionActive.Name}</span>
                  <Ellipsis
                    phrase={optionActive?.Description || ''}
                    maxLength={147}
                    className="description"
                  />
                </Top>
              )}
              <MainContent>
                <ConfigProvider theme={{
                  token: {
                    colorBgContainerDisabled: `color-mix(in srgb, ${theme.colors.primary.primaryOutline}, ${theme.colors.surface.surface})`,
                    colorTextDisabled: theme.colors.primary.onPrimary,
                    colorPrimary: theme.colors.primary.primary
                  }
                }}>
                  <MainContentTop>
                    <HeaderContent>
                      <span className="checkbox">
                        <Checkbox
                          checked={selecteds.length === optionActiveChildren.count}
                          indeterminate={
                            selecteds.length > 0 &&
                            selecteds.length !== optionActiveChildren.count
                          }
                          disabled={loading}
                          onClick={onCheckAll}
                        />
                      </span>
                      <span className="columnSigla">Sigla</span>
                      <span className="columnEtapa">Etapa</span>
                    </HeaderContent>
                    <Body>
                      <BodyContent>
                        {optionActiveChildren.data.map(opt => {
                          const isActive = selecteds.some(item => {
                            return item.id === opt.ConstructionSiteStageLibraryItemId;
                          });
                          const isDisabled = loading ||
                            phases.some(phase => {
                              return (phase.TopicStageId === opt.ConstructionSiteStageLibraryItemId) ||
                                phase.Acronym?.toLowerCase() === opt.Acronym?.toLowerCase() ||
                                phase.Name?.toLowerCase() === opt.Name?.toLowerCase();
                            }) ||
                            (opt.IsDefault && !phases.some(phase => phase.IsDefault));

                          return (
                            <span key={opt.ConstructionSiteStageLibraryItemId} className="row">
                              <span className="checkbox">
                                <Checkbox
                                  checked={isActive}
                                  disabled={isDisabled}
                                  onClick={() => onCheck({
                                    id: opt.ConstructionSiteStageLibraryItemId,
                                    acronym: opt.Acronym,
                                    name: opt.Name,
                                    isDefault: opt.IsDefault,
                                  })}
                                />
                              </span>
                              <span className={`rightRow ${(!isDisabled && isActive) ? 'rightRowActive' : ''}`}>
                                <span className="columnSigla">{opt.Acronym}</span>
                                <span className="columnEtapa">
                                  {opt.Name}
                                  {opt.IsDefault && (
                                    <span className="tagDefault">Padrão</span>
                                  )}
                                </span>
                              </span>
                            </span>
                          )
                        })}
                      </BodyContent>
                    </Body>
                  </MainContentTop>
                  <Footer>
                    <span className="textLeft">
                      {`${selecteds.length} etapas selecionadas`}
                    </span>
                    <Button
                      type="primary"
                      className="btnFooter"
                      disabled={disabledImport}
                      onClick={() => handleSubmit(optionActive?.Type === ConstructionSiteStageLibraryEnum.ConstructionSite
                        ? ImportedLibraryTypeEnum.ConstructionSite
                        : ImportedLibraryTypeEnum.Predefined
                      )}
                    >
                      {loading && (
                        <Spin
                          indicator={
                            <LoadingOutlined
                              rev=""
                              color="white"
                              style={{ fontSize: 12, color: 'white', marginRight: 6 }}
                            />
                          }
                        />
                      )}
                      {loading ? 'Importando...' : 'Importar Etapas'}
                    </Button>
                  </Footer>
                </ConfigProvider>
              </MainContent>
            </Main>
          </Content>
        </Container>
      </Modal>

      {showConfirmDefault && (
        <ConfirmDefault
          visible={showConfirmDefault}
          onConfirm={() => handleConfirmDefault()}
          onCancel={closeConfirmDefault}
        />
      )}
    </>
  )
}

export default Library;
