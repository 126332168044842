import styled from "styled-components";

export const Container = styled.div`
  width: 200px;
  max-width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.primary.primaryOutline};
  background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.primaryLightContainer}, ${theme.colors.surface.surface})`};
  padding: 12px;
  margin-top: 20px;

  .headerStageCta {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .badge {
      width: 34px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.primaryContainer}, ${theme.colors.surface.surface})`};

      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      color: ${({ theme }) => theme.colors.primary.primary};
    }

    .closeIcon {
      color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.primaryOutline}, ${theme.colors.surface.surface})`} !important;
      cursor: pointer;
    }
  }

  .mainStageCta {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.secondary.onSecondary};
    }

    .description {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant};
      margin-top: 8px;
    }

    .button {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 12px;

      .playIcon {
        color: ${({ theme }) => theme.colors.primary.onPrimary} !important;
      }
    }
  }
`;
