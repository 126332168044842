import { FC } from "react";
import Button from "Components/UI/Button";
import Modal from "Components/UI/Modal/Modal";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  BtnContainer,
  Container,
  Content,
  Title,
} from "./styles";

interface IDeleteModal {
  visible: boolean;
  loading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeleteModal: FC<IDeleteModal> = ({
  visible,
  loading,
  onConfirm,
  onCancel,
}) => {

  return (
    <Modal
      visible={visible}
      width={450}
      footerButtons={null}
    >
      <Container>
        <Title>
          <span className="textTitle">
            Excluir comentário?
          </span>
        </Title>

        <Content>
          <span className="contentText">
            Você tem certeza que deseja excluir este comentário?
          </span>
        </Content>

        <BtnContainer>
          <Button type="text" disabled={loading} onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            type="primary"
            className="deleteBtn"
            onClick={loading ? undefined : onConfirm}
            primaryDanger
          >
            {loading && (
              <Spin
                indicator={
                  <LoadingOutlined
                    rev=""
                    color="white"
                    style={{ fontSize: 12, color: 'white', marginRight: 6 }}
                  />
                }
              />
            )}
            {loading ? 'Excluindo...' : 'Excluir'}
          </Button>
        </BtnContainer>
      </Container>
    </Modal>
  )
}

export default DeleteModal;
