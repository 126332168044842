import { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";
import SignalR from "Components/UI/SignalR";
import TabsShell, { PathActiveEnum } from "Components/UI/TabsShell";
import FederatedDrawer from "./components/FederatedDrawer";
import SearchViewerInput from "Components/UI/SearchViewerInput";
import LoadingDrawer from "Components/UI/CustomLoading/LoadingDrawer";
import styles from './FederadoViewer.module.scss';
import Models from "./components/Models";
import EmptyViewer from "./components/EmptyViewer";
import EmptyModels from "./components/EmptyModels";
import VideoEx from "Components/VideoEx";
import FileDestination from "./Modals/FileDestination";
import CTAViewerUpgrade from "./Modals/CTAViewerUpgrade";
import CTAMultipleModels from "./Modals/CTAMultipleModels";
import CTAUpgradeAccessViewer from "./components/CTAUpgradeAccessViewer";
import ModelsViewpoints from "./components/ModelsViewpoints";
import ModelsPranchas from "./components/ModelsPranchas";
import ActivitiesDemo from "./components/ActivitiesDemo";
import ProgressRenderBar from "Pages/ListagemArquivos/Modals/ProgressRenderBar";
import useFederatedViewer from "./useFederatedViewer";
import InternalViewer from "Components/UI/InternalViewer";
import ModelsViews from "./components/ModelsViews";

interface IFederatedViewer {
  match?: any;
}

const FederatedViewer: FC<Props> = ({
  match,
  isLoading,
  urns,
  userInfo,
  accessViewerTab,
  modelsTree,
  viewerListDisciplines,
  loadingDiscipline,
  loadingFiles,
  hideNodes,
  selectedNode,
  userLimitsActive,
  views,
  pranchas,
  viewpoints,
  obra,
}) => {
  const {
    demoVideoViewer,
    activeDrawer,
    handleActiveList,
    drawerFederatedVisible,
    closeDrawerFederated,
    showDrawerFederated,
    handleSearch,
    activeDisciplineId,
    uploadFileViewer,
    handleMaletaFileViewer,
    handleShowCTARender,
    handleShowProgressLimitRender,
    filteredEmptyDisciplines,
    openDiscipline,
    backDisciplines,
    childrenActiveDisciplines,
    searchDiscipline,
    searchDisciplineFiles,
    loadUrn,
    handleLoadUrn,
    handleActiveUrns,
    handleOpenCTAModal,
    handleToogleNode,
    handleToogleSelectNode,
    dispatch,
    handleOpenVideoModal,
    handleCloseVideoModal,
    handleUpgradePlan,
    searchItens,
    childrenActiveUrnsNames,
    toggleView,
    handleToogleView,
    toggleViewCamera,
    handleToogleViewCamera,
    handleTooglePrancha,
    toggleHideShowNode,
    toggleSelectNode,
    togglePrancha,
    handleUploadFileViewer,
    showVideo,
    showSelectUploadPath,
    cancelUploadFileViewer,
    upfileViewer,
    selectUploadPathFromMaleta,
    showCTAViewerUpgrade,
    setShowCTAViewerUpgrade,
    showCTAMultipleModelsUpgrade,
    handleCloseCTAModal,
    indexList,
    showModalProgressLimitRender,
    handleCloseProgressLimitRender,
    handleClearAllToogles,
  } = useFederatedViewer({
    match,
    isLoading,
    urns,
    userInfo,
    viewerListDisciplines,
    loadingDiscipline,
  });

  const isDrawerActiveModels = activeDrawer === 'models';
  const isDrawerActiveViews = activeDrawer === 'views';
  const isDrawerActiveViewPoints = activeDrawer === 'viewpoints';
  const isDrawerActivePranchas = activeDrawer === 'pranchas';
  const isDrawerActiveActivities = activeDrawer === 'activities';
  const hasUrns = urns.length > 0;
  const hasViews = views.length > 0;
  const hasViewpoints = viewpoints.length > 0;
  const hasPranchas = pranchas.length > 0;

  const isPopulatedDisciplinesChildrensLoading = ((loadingDiscipline || loadingFiles) 
    || ((!isDrawerActiveModels && !isDrawerActiveActivities) 
    && !hasUrns
  ));

  const searchViewerInputPlaceholder = ((!isDrawerActiveModels && !isDrawerActiveActivities)
    ? `Procure por uma ${activeDrawer}`
    : (
      activeDisciplineId
        ? "Procure por um modelo"
        : "Procure por uma disciplina"
  ));

  return (
    <div className={styles['wrapper']}> 
      <TabsShell 
        ConstructionSiteId={match.params.constructionSiteId} 
        active={PathActiveEnum.viewer}
        loading={loadingDiscipline || loadingFiles}
        match={match}
      />
      <SignalR />
      <FederatedDrawer
        activeButton={activeDrawer}
        onActiveOption={handleActiveList}
        visible={drawerFederatedVisible}
        onClose={closeDrawerFederated}
        onShow={showDrawerFederated}
      >
        <SearchViewerInput 
          value={searchDiscipline || searchDisciplineFiles || searchItens}
          uploadButton={!isDrawerActiveModels || !isDrawerActiveActivities}
          onSearch={handleSearch}
          firstLoading={isPopulatedDisciplinesChildrensLoading}
          inputPlaceholder={searchViewerInputPlaceholder}
          onUploadFile={uploadFileViewer}
          onMaletaFile={handleMaletaFileViewer}
          isDebounce={false}
          limitRender={userInfo?.UserStoreItem.MaxQtyRenderedFiles || userInfo?.UserStoreItem.StorePlan?.MaxQtyRenderedFiles}
          activeRender={userLimitsActive?.QtyRenderedFiles}
          onShowCTARender={handleShowCTARender}
          onLimitBarProgress={handleShowProgressLimitRender}
        />
        {(loadingDiscipline || loadingFiles) ? (
          <LoadingDrawer multiple={6}/>
        ) : (
          <>
            {isDrawerActiveModels && (
              (filteredEmptyDisciplines.length > 0) ? (
                <Models 
                  activeDisciplineId={activeDisciplineId}
                  openDiscipline={openDiscipline}
                  backDisciplines={backDisciplines}
                  disciplineList={filteredEmptyDisciplines}
                  childrenDisciplinesActive={childrenActiveDisciplines}
                  searchDiscipline={searchDiscipline}
                  searchDisciplineFiles={searchDisciplineFiles}
                  loadUrn={loadUrn}
                  onLoadUrn={handleLoadUrn}
                  onActiveUrns={handleActiveUrns}
                  accessViewerTab={accessViewerTab}
                  urns={urns}
                  onCTAMultipleRender={handleOpenCTAModal}
                  modelsTree={modelsTree}
                  onToggleNode={handleToogleNode}
                  hideNodes={hideNodes}
                  onToggleSelectNode={handleToogleSelectNode}
                  selectedNode={selectedNode}
                  constructionSiteId={match.params.constructionSiteId}
                  api={obra.Api}
                  userInfo={userInfo}
                  dispatch={dispatch}
                />
              ) : (
                <EmptyModels 
                  onPlay={() => handleOpenVideoModal()}
                  isModelActive
                />
              )
            )}
            {isDrawerActiveViews && (
              accessViewerTab ? (
                <>
                  {(hasUrns && hasViews) && (
                    <ModelsViews
                      listItems={views}
                      searchItems={searchItens}
                      childrenNames={childrenActiveUrnsNames}
                      activeDrawer={activeDrawer}
                      toggleView={toggleView}
                      onToggleView={handleToogleView}
                    />
                  )}
                  {(!hasUrns || (hasUrns && !hasViews)) && (
                    <EmptyModels 
                      onPlay={() => handleActiveList('models')}
                      activeDrawer={activeDrawer}
                      hasUrns={hasUrns}
                    />
                  )}
                </>
              ) : (
                <CTAUpgradeAccessViewer 
                  title={activeDrawer}
                  role={userInfo?.CurrentRoleFk}
                  onConfirm={handleUpgradePlan}
                />
              )
            )}
            {isDrawerActiveViewPoints && (
              accessViewerTab ? (
                <>
                  {(hasUrns && hasViewpoints) && (
                    <ModelsViewpoints 
                      listItems={viewpoints}
                      searchItems={searchItens}
                      childrenNames={childrenActiveUrnsNames}
                      activeDrawer={activeDrawer}
                      toggleViewCamera={toggleViewCamera}
                      onToggleViewCamera={handleToogleViewCamera}
                    />
                  )}
                  {(!hasUrns || (hasUrns && !hasViewpoints)) && (
                    <EmptyModels 
                      onPlay={() => handleActiveList('models')}
                      activeDrawer={activeDrawer}
                      hasUrns={hasUrns}
                    />
                  )}
                </>
              ) : (
                <CTAUpgradeAccessViewer 
                  title={activeDrawer}
                  role={userInfo?.CurrentRoleFk}
                  onConfirm={handleUpgradePlan}
                />
              )
            )}
            {isDrawerActivePranchas && (
              accessViewerTab ? (
                <>
                  {(hasUrns && hasPranchas) && (
                    <ModelsPranchas 
                      listItems={pranchas}
                      searchItems={searchItens}
                      childrenNames={childrenActiveUrnsNames}
                      activeDrawer={activeDrawer}
                      togglePrancha={togglePrancha}
                      onTogglePrancha={handleTooglePrancha}
                    />
                  )}
                  {(!hasUrns || (hasUrns && !hasPranchas)) && (
                    <EmptyModels 
                      onPlay={() => handleActiveList('models')}
                      activeDrawer={activeDrawer}
                      hasUrns={hasUrns}
                    />
                  )}
                </>
              ) : (
                <CTAUpgradeAccessViewer 
                  title={activeDrawer}
                  role={userInfo?.CurrentRoleFk}
                  onConfirm={handleUpgradePlan}
                />
              )
            )}
            {isDrawerActiveActivities && (
              <ActivitiesDemo />
            )}
          </>
        )}
      </FederatedDrawer>
      <div className={styles['mainWrapper']}>
        <div className={drawerFederatedVisible ? styles['viewerWrapperDrawerOpen'] : styles['viewerWrapper']}>
          {!hasUrns ? (
            <EmptyViewer 
              isDrawerVisible={drawerFederatedVisible}
            />
          ) : (
            <InternalViewer
              loadUrn={loadUrn}
              onLoadUrn={handleLoadUrn}
              onActiveUrns={handleActiveUrns}
              drawerOpen={drawerFederatedVisible}
              toggleNode={toggleHideShowNode}
              togglePrancha={togglePrancha}
              toggleSelectNode={toggleSelectNode}
              toggleViewCamera={toggleViewCamera}
              toggleView={toggleView}
              onToggleNode={handleToogleNode}
              onToggleSelectNode={handleToogleSelectNode}
              onTogglePrancha={handleTooglePrancha}
              onToggleViewCamera={handleToogleViewCamera}
              onToggleView={handleToogleView}
              onClearAllToggles={handleClearAllToogles}
            />
          )}
        </div>
      </div>
      <input
        id="uploadFileViewer"
        type="file"
        hidden
        onChange={(e) => {
          handleUploadFileViewer(e.currentTarget.files);
          e.currentTarget.value = '';
        }}
      />
      {showVideo && (
        <VideoEx 
          visible={showVideo} 
          url={demoVideoViewer}
          onCancel={() => handleCloseVideoModal()}
        />
      )}
      {showSelectUploadPath && (
        <FileDestination 
          visible={showSelectUploadPath}
          disciplineList={filteredEmptyDisciplines}
          onCancel={() => cancelUploadFileViewer()}
          file={upfileViewer}
          currentDiscipline={activeDisciplineId}
          constructionSiteId={parseInt(match.params.constructionSiteId)}
          fromMaleta={selectUploadPathFromMaleta}
        />
      )}
      {showCTAViewerUpgrade&& (
        <CTAViewerUpgrade 
         visible={showCTAViewerUpgrade}
         onCancel={() => setShowCTAViewerUpgrade(false)}
         onConfirm={handleUpgradePlan}
         role={userInfo?.CurrentRoleFk}
        />
      )}
      {showCTAMultipleModelsUpgrade && (
        <CTAMultipleModels 
          onConfirm={handleUpgradePlan}
          onClose={handleCloseCTAModal}
          role={userInfo?.CurrentRoleFk}
          indexList={indexList}
        />
      )}
      {showModalProgressLimitRender && (
        <ProgressRenderBar 
          visible={showModalProgressLimitRender}
          maxLimits={userInfo?.UserStoreItem.MaxQtyRenderedFiles || userInfo?.UserStoreItem.StorePlan?.MaxQtyRenderedFiles}
          value={userLimitsActive?.QtyRenderedFiles || userInfo?.QtyRenderedFiles || 0}
          onCancel={handleCloseProgressLimitRender}
          onConfirmRender={uploadFileViewer}
          inFederatedViewer
        />
      )}
    </div>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  ...state.viewer,
  ...state.federatedViewer,
  userInfo: state.auth.userInfo,
  planFree: state.auth.planFree,
  userLimitsActive: state.auth.userLimitsActive,
  accessViewerTab: state.auth.userInfo?.UserStoreItem.StorePlan?.AccessViewerTab,
  isLoading: state.federatedViewer.isLoading,
  obra: state.obra.Obra,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IFederatedViewer;

export default connector(FederatedViewer);
