import { AxiosResponse } from 'axios';
import { Http } from '../Utils/Http';
import { IShortURLCreateRequest } from './interfaces/ShortURL/IShortURL';

export class ShortURLApi {
  static async create(request: IShortURLCreateRequest): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    return axios.post('/ShortURL/Create', request);
  }
}
