import { IObraState } from './interfaces/IObraState';
import { ObraActionUnion, ObraActionKeys } from './Obra.actions';
import { IObraData } from '../../Data/interfaces/Obra/IObraData';
import { IUsersConstructionSiteResponse } from '../../Data/interfaces/User/IUsersConstructionSiteResponse';
import { ISharedUser } from '../../Data/interfaces/User/ISharedUser';
import { IObraResponse } from 'Data/interfaces/Obra/IObraListResponse';
import { IInvitedUser } from 'Data/interfaces/User/IInvitedUser';

const initialState: IObraState = {
  Users: {} as IUsersConstructionSiteResponse,
  isLoading: false,
  Obra: {} as IObraData,
  constructionSiteIds: [],
  responseTime: undefined,
  isLoadingGetUsersConstruction: false,
  usersForLimitInvited: [],
  isCollapsed: false,
  archivesPath: undefined,
  plotagensPath: undefined,
  clearConstructionSiteSearch: false,
  editLoading: false,
  newEditLoading: false,
  listStagesLoading: false,
  listLibrariesLoading: false,
  stages: [],
  libraries: [],
};

const obraReducer = (state = initialState, action: ObraActionUnion): IObraState => {
  switch (action.type) {
    case ObraActionKeys.LIST_OBRA_REQUEST:
      return {
        ...state,
        ObrasList: undefined,
        isLoading: true,
      };
    case ObraActionKeys.LIST_OBRA_SUCCESS:
      let newObrasList: IObraResponse[] | undefined = state.ObrasList;
      const NewObraName = action.payload.newObraName;

      if (Array.isArray(action.payload.ObrasList)) {
        newObrasList = action.payload.ObrasList;
      }

      if (NewObraName && newObrasList) {
        const indexName = newObrasList.findIndex(obra => obra.ConstructionSiteId === NewObraName.ConstructionSiteId);
        if (
          newObrasList[indexName] && NewObraName.Name
          && (newObrasList[indexName].Name !== NewObraName.Name)
        ) {
          newObrasList[indexName].Name = NewObraName.Name;
        }
      }

      return {
        ...state,
        ObrasList: newObrasList ? [...newObrasList] : newObrasList,
        isLoading: false,
      };
    case ObraActionKeys.LIST_OBRA_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ObraActionKeys.ADD_OBRA_CHUNCK_DATA:
      return {
        ...state,
        Obra: { ...state.Obra, ...action.payload.valuesChunck },
        isLoading: action.payload.shouldSendData,
        editLoading: action.payload.shouldSendData,
      };

    case ObraActionKeys.CREATE_OR_UPDATE_OBRA_REQUEST:
      return {
        ...state,
        isLoading: true,
        editLoading: true,
      };
    case ObraActionKeys.CREATE_OR_UPDATE_OBRA_SUCCESS:
      let newObrasListObra: IObraResponse[] | undefined = state.ObrasList;
      const NewObra = action.payload;

      if (NewObra) {
        if (newObrasListObra && newObrasListObra.length >= 0) {
          const indexName = newObrasListObra.findIndex(obra => obra.ConstructionSiteId === NewObra.ConstructionSiteId);
          if (newObrasListObra[indexName]) {
            newObrasListObra[indexName] = {
              ...newObrasListObra[indexName],
              ...NewObra,
            };

          } else {
            newObrasListObra = [...newObrasListObra, NewObra];
          }
        }
      }

      return {
        ...state,
        ObrasList: newObrasListObra ? [...newObrasListObra] : newObrasListObra,
        isLoading: false,
        editLoading: false,
      };
    case ObraActionKeys.CREATE_OR_UPDATE_OBRA_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        editLoading: false,
      };

    case ObraActionKeys.UPDATE_ARCHIVED_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ObraActionKeys.OBRA_RESET_STATE:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        message: undefined,
        Obra: {} as IObraData,
      };

    case ObraActionKeys.UPDATE_ARCHIVED_STATUS_SUCCESS:
      const obraAlterada = state.ObrasList
        ?.find((obra) => obra.ConstructionSiteId === action.payload.ConstructionSiteId);
      if (obraAlterada) {
        obraAlterada.isArchived = action.payload.isArchived;
      }
      return {
        ...state,
        isLoading: false,
        message: 'Obra arquivada com sucesso',
      };

    case ObraActionKeys.GET_OBRA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ObraActionKeys.GET_OBRA_SUCCESS:
      action.payload.SharedUsers = [...action.payload.SharedUsers
        .filter((sharedUser: any) => sharedUser.User.CurrentRoleFk !== 1)];
      return {
        ...state,
        Obra: { ...action.payload },
        isLoading: false,
      };
    case ObraActionKeys.GET_OBRA_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ObraActionKeys.GET_DISCIPLINES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ObraActionKeys.GET_DISCIPLINES_SUCCESS:
      const Disciplines = action.payload.filter((discipline) => discipline.Name !== 'Personalizado');
      return {
        ...state,
        Disciplines,
        isLoading: false,
      };
    case ObraActionKeys.GET_DISCIPLINES_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_REQUEST:
      return {
        ...state,
        isLoadingGetUsersConstruction: true,
      };
    case ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_SUCCESS:
      return {
        ...state,
        Users: action.payload,
        isLoadingGetUsersConstruction: false,
      };
    case ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoadingGetUsersConstruction: false,
      };

    case ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_REQUEST:
      return {
        ...state,
        isLoadingGetUsersConstruction: true,
      };
    case ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_SUCCESS:
      const updatedConstructionSite = state.ObrasList?.find((obra) =>
        obra.ConstructionSiteId === action.payload.ConstructionSiteId);
      if (updatedConstructionSite && updatedConstructionSite.SharedUsers) {
        updatedConstructionSite.SharedUsers = [
          ...updatedConstructionSite.SharedUsers.filter((user: ISharedUser) => user.User?.CurrentRoleFk === 1),
          ...action.payload.SharedUsers,
        ];
      }
      return {
        ...state,
        isLoadingGetUsersConstruction: false,
      };
    case ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoadingGetUsersConstruction: false,
      };

    case ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        AccountsInfo: action.payload,
        isLoading: false,
      };
    case ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_SUCCESS:
      return {
        ...state,
        StorageList: action.payload,
        isLoading: false,
      };
    case ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_REQUEST:
      return {
        ...state,
        isLoading: false,
      }
    case ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_SUCCESS:
      let ids = action.payload.map(constructionSite => constructionSite.ConstructionSiteId);

      return {
        ...state,
        isLoading: false,
        constructionSiteIds: ids
      }
    case ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }

    case ObraActionKeys.CLEAR_ALL_CONSTRUCTIONS_SITES:
      return {
        ...state,
        isLoading: false,
        constructionSiteIds: [],
      }

    case ObraActionKeys.OBRA_USERS_LIMIT_INVITED:
      return {
        ...state,
        usersForLimitInvited: action.payload,
      }

    case ObraActionKeys.TOGGLE_COLLAPSED:
      return {
        ...state,
        isCollapsed: action.payload,
      }

    case ObraActionKeys.STORE_ARCHIVES_PATH:
      return {
        ...state,
        archivesPath: action.payload,
      }
    case ObraActionKeys.STORE_PLOTAGENS_PATH:
      return {
        ...state,
        plotagensPath: action.payload,
      }

    case ObraActionKeys.CLEAR_CONSTRUCTION_SITE_SEARCH:
      return {
        ...state,
        clearConstructionSiteSearch: action.payload,
      }

    case ObraActionKeys.UPDATE_DETAILS_REQUEST:
      return {
        ...state,
        newEditLoading: true,
      }
    case ObraActionKeys.UPDATE_DETAILS_SUCCESS:
      let ObrasListDetails = state.ObrasList;
      let ObraDetails = state.Obra;
      if (ObrasListDetails) {
        const findIndexDetail = ObrasListDetails?.findIndex(obra => {
          return obra.ConstructionSiteId === action.payload.constructionStieId;
        });

        if (findIndexDetail !== -1) {
          ObrasListDetails[findIndexDetail].Name = action.payload.name;
          ObrasListDetails[findIndexDetail].Type = action.payload.type;
          ObrasListDetails[findIndexDetail].Address = action.payload.address;
          ObrasListDetails[findIndexDetail].AvatarFileName = action.payload.avatarFileName;
        }
      }
      if (ObraDetails && ObraDetails?.ConstructionSiteId === action.payload.constructionStieId) {
        ObraDetails.Name = action.payload.name;
        ObraDetails.Type = action.payload.type;
        ObraDetails.Address = action.payload.address;
        ObraDetails.AvatarFileName = action.payload.avatarFileName;
      }
      return {
        ...state,
        ObrasList: ObrasListDetails ? [...ObrasListDetails] : ObrasListDetails,
        Obra: ObraDetails,
        newEditLoading: false,
      }
    case ObraActionKeys.UPDATE_DETAILS_FAILED:
      return {
        ...state,
        error: action.payload,
        newEditLoading: false,
      }

    case ObraActionKeys.UPDATE_DISCIPLINES_REQUEST:
      return {
        ...state,
        newEditLoading: true,
      }
    case ObraActionKeys.UPDATE_DISCIPLINES_SUCCESS:
      let ObrasListDisciplines = state.ObrasList;
      let ObraDisciplines = state.Obra;
      if (ObrasListDisciplines) {
        const findIndex = ObrasListDisciplines?.findIndex(obra => {
          return obra.ConstructionSiteId === action.payload.constructionStieId;
        });

        if (findIndex !== -1) {
          ObrasListDisciplines[findIndex].Disciplines = action.payload.disciplines;
        }
      }
      if (ObraDisciplines && ObraDisciplines?.ConstructionSiteId === action.payload.constructionStieId) {
        ObraDisciplines.Disciplines = action.payload.disciplines;
      }
      return {
        ...state,
        ObrasList: ObrasListDisciplines ? [...ObrasListDisciplines] : ObrasListDisciplines,
        Obra: ObraDisciplines,
        newEditLoading: false,
      }
    case ObraActionKeys.UPDATE_DISCIPLINES_FAILED:
      return {
        ...state,
        error: action.payload,
        newEditLoading: false,
      }

    case ObraActionKeys.UPDATE_USERS_REQUEST:
      return {
        ...state,
        newEditLoading: true,
      }
    case ObraActionKeys.UPDATE_USERS_SUCCESS:
      let ObrasListUsers = state.ObrasList;
      let ObraUsers = state.Obra;
      if (ObrasListUsers) {
        const findIndex = ObrasListUsers?.findIndex(obra => {
          return obra.ConstructionSiteId === action.payload.constructionStieId;
        });

        if (findIndex !== -1) {
          const sharedIndex = ObrasListUsers[findIndex]?.SharedUsers?.findIndex((user: ISharedUser) => {
            return user.UserFk === action.payload.user.UserFk || user.UserId === action.payload.user.UserId;
          })

          if (sharedIndex !== -1) {
            ObrasListUsers[findIndex].SharedUsers.push(action.payload.user);
          }
        }
      }
      if (ObraUsers && ObraUsers?.ConstructionSiteId === action.payload.constructionStieId) {
        const sharedIndex = ObraUsers.SharedUsers?.findIndex(user => {
          return user.UserFk === action.payload.user.UserFk || user.UserId === action.payload.user.UserId;
        });

        if (sharedIndex !== -1) {
          ObraUsers.SharedUsers.push(action.payload.user);
        }
      }
      return {
        ...state,
        ObrasList: ObrasListUsers ? [...ObrasListUsers] : ObrasListUsers,
        Obra: { ...ObraUsers },
        newEditLoading: false,
      };
    case ObraActionKeys.UPDATE_USERS_FAILED:
      return {
        ...state,
        error: action.payload,
        newEditLoading: false,
      }

    case ObraActionKeys.DELETE_USERS_REQUEST:
      return {
        ...state,
        newEditLoading: true,
      }
    case ObraActionKeys.DELETE_USERS_SUCCESS:
      let ObrasListDeleteUsers = state.ObrasList;
      let ObraDeleteUsers = state.Obra;
      if (ObrasListDeleteUsers) {
        const findIndex = ObrasListDeleteUsers?.findIndex(obra => {
          return obra.ConstructionSiteId === action.payload.constructionStieId;
        });

        if (findIndex !== -1) {
          const sharedIndex = ObrasListDeleteUsers[findIndex]?.SharedUsers?.findIndex((user: ISharedUser) => {
            return user.UserFk === action.payload.userId || user.UserId === action.payload.userId;
          })

          if (sharedIndex !== -1) {
            ObrasListDeleteUsers[findIndex].SharedUsers.splice(sharedIndex, 1);
          }
        }
      }
      if (ObraDeleteUsers && ObraDeleteUsers?.ConstructionSiteId === action.payload.constructionStieId) {
        const sharedIndex = ObraDeleteUsers.SharedUsers?.findIndex(user => {
          return user.UserFk === action.payload.userId || user.UserId === action.payload.userId;
        });

        if (sharedIndex !== -1) {
          ObraDeleteUsers.SharedUsers.splice(sharedIndex, 1);
        }
      }
      return {
        ...state,
        ObrasList: ObrasListDeleteUsers ? [...ObrasListDeleteUsers] : ObrasListDeleteUsers,
        Obra: { ...ObraDeleteUsers },
        newEditLoading: false,
      };
    case ObraActionKeys.DELETE_USERS_FAILED:
      return {
        ...state,
        error: action.payload,
        newEditLoading: false,
      }

    case ObraActionKeys.UPDATE_INVITED_USERS_REQUEST:
      return {
        ...state,
        newEditLoading: true,
      }
    case ObraActionKeys.UPDATE_INVITED_USERS_SUCCESS:
      let ObrasListInvitedUsers = state.ObrasList;
      let ObraInvitedUsers = state.Obra;
      if (ObrasListInvitedUsers) {
        const findIndex = ObrasListInvitedUsers?.findIndex(obra => {
          return obra.ConstructionSiteId === action.payload.constructionStieId;
        });

        if (findIndex !== -1) {
          ObrasListInvitedUsers[findIndex]?.InvitedUsers?.push(action.payload.user);
        }
      }
      if (ObraInvitedUsers && ObraInvitedUsers?.ConstructionSiteId === action.payload.constructionStieId) {
        ObraInvitedUsers.InvitedUsers.push(action.payload.user);
      }
      return {
        ...state,
        ObrasList: ObrasListInvitedUsers ? [...ObrasListInvitedUsers] : ObrasListInvitedUsers,
        Obra: { ...ObraInvitedUsers },
        newEditLoading: false,
      };
    case ObraActionKeys.UPDATE_INVITED_USERS_FAILED:
      return {
        ...state,
        error: action.payload,
        newEditLoading: false,
      }

    case ObraActionKeys.DELETE_INVITED_USERS_REQUEST:
      return {
        ...state,
        newEditLoading: true,
      }
    case ObraActionKeys.DELETE_INVITED_USERS_SUCCESS:
      let ObrasListInvitedDeleteUsers = state.ObrasList;
      let ObraInvitedDeleteUsers = state.Obra;
      if (ObrasListInvitedDeleteUsers) {
        const findIndex = ObrasListInvitedDeleteUsers?.findIndex(obra => {
          return obra.ConstructionSiteId === action.payload.constructionStieId;
        });

        if (findIndex !== -1) {
          const invitedIndex = ObrasListInvitedDeleteUsers[findIndex]?.InvitedUsers?.findIndex((user: IInvitedUser) => {
            return user.Email === action.payload.invitedEmail;
          })

          if (invitedIndex !== -1) {
            ObrasListInvitedDeleteUsers[findIndex]?.InvitedUsers?.splice(invitedIndex, 1);
          }
        }
      }
      if (ObraInvitedDeleteUsers && ObraInvitedDeleteUsers?.ConstructionSiteId === action.payload.constructionStieId) {
        const invitedIndex = ObraInvitedDeleteUsers.InvitedUsers?.findIndex(user => {
          return user.Email === action.payload.invitedEmail;
        });

        if (invitedIndex !== -1) {
          ObraInvitedDeleteUsers.InvitedUsers.splice(invitedIndex, 1);
        }
      }
      return {
        ...state,
        ObrasList: ObrasListInvitedDeleteUsers ? [...ObrasListInvitedDeleteUsers] : ObrasListInvitedDeleteUsers,
        Obra: { ...ObraInvitedDeleteUsers },
        newEditLoading: false,
      };
    case ObraActionKeys.DELETE_INVITED_USERS_FAILED:
      return {
        ...state,
        error: action.payload,
        newEditLoading: false,
      }

    case ObraActionKeys.UPDATE_PERMISSIONS_REQUEST:
      return {
        ...state,
        newEditLoading: true,
        error: undefined,
      }
    case ObraActionKeys.UPDATE_PERMISSIONS_SUCCESS:
      let ObrasPermissionUsers = state.ObrasList;
      let ObraPermissionUsers = state.Obra;
      if (
        ObrasPermissionUsers &&
        (action.payload.permissions.constructionSiteInvitedUsersPermission.length > 0 ||
          action.payload.permissions.constructionSiteUsersPermission.length > 0)
      ) {
        const findIndex = ObrasPermissionUsers?.findIndex(obra => {
          return obra.ConstructionSiteId === action.payload.constructionStieId;
        });

        if (findIndex !== -1) {
          for (const user of action.payload.permissions.constructionSiteUsersPermission) {
            const sharedIndex = ObrasPermissionUsers[findIndex]?.SharedUsers?.findIndex((sharedUser: ISharedUser) => {
              return (sharedUser.UserFk || sharedUser.UserId) === user.userId;
            })

            if (sharedIndex !== -1) {
              ObrasPermissionUsers[findIndex].SharedUsers[sharedIndex].HasPlottingPermission = user.hasPlottingPermission;
              ObrasPermissionUsers[findIndex].SharedUsers[sharedIndex].Privileges = user.privileges;
            }
          }
          for (const user of action.payload.permissions.constructionSiteInvitedUsersPermission) {
            const invitedIndex = ObrasPermissionUsers[findIndex]?.InvitedUsers?.findIndex((sharedUser: ISharedUser) => {
              return sharedUser.UserFk === user.email;
            })

            if (invitedIndex !== -1) {
              ObrasPermissionUsers[findIndex].InvitedUsers[invitedIndex].HasPlottingPermission = user.hasPlottingPermission;
              ObrasPermissionUsers[findIndex].InvitedUsers[invitedIndex].Privileges = user.privileges;
            }
          }
        }
      }
      if (
        ObraPermissionUsers &&
        ObraPermissionUsers?.ConstructionSiteId === action.payload.constructionStieId &&
        (action.payload.permissions.constructionSiteInvitedUsersPermission.length > 0 ||
          action.payload.permissions.constructionSiteUsersPermission.length > 0)
      ) {
        for (const user of action.payload.permissions.constructionSiteUsersPermission) {
          const sharedIndex = ObraPermissionUsers.SharedUsers?.findIndex(sharedUser => {
            return (sharedUser.UserFk || sharedUser.UserId) === user.userId;
          })

          if (sharedIndex !== -1) {
            ObraPermissionUsers.SharedUsers[sharedIndex].HasPlottingPermission = user.hasPlottingPermission;
            ObraPermissionUsers.SharedUsers[sharedIndex].Privileges = user.privileges;
          }
        }
        for (const user of action.payload.permissions.constructionSiteInvitedUsersPermission) {
          const invitedIndex = ObraPermissionUsers.InvitedUsers?.findIndex(sharedUser => {
            return sharedUser.Email === user.email;
          })

          if (invitedIndex !== -1) {
            ObraPermissionUsers.InvitedUsers[invitedIndex].HasPlottingPermission = user.hasPlottingPermission;
            ObraPermissionUsers.InvitedUsers[invitedIndex].Privileges = user.privileges;
          }
        }
      }
      return {
        ...state,
        ObrasList: ObrasPermissionUsers ? [...ObrasPermissionUsers] : ObrasPermissionUsers,
        Obra: { ...ObraPermissionUsers },
        newEditLoading: false,
      };
    case ObraActionKeys.UPDATE_PERMISSIONS_FAILED:
      return {
        ...state,
        error: action.payload,
        newEditLoading: false,
      }

    case ObraActionKeys.UPDATE_NOMENCLATURE_REQUEST:
      return {
        ...state,
        newEditLoading: true,
      }
    case ObraActionKeys.UPDATE_NOMENCLATURE_SUCCESS:
      let ObrasListNomenclatures = state.ObrasList;
      let ObraNomenclature = state.Obra;
      if (ObrasListNomenclatures) {
        const findIndex = ObrasListNomenclatures?.findIndex(obra => {
          return obra.ConstructionSiteId === action.payload.constructionStieId;
        });

        if (findIndex !== -1) {
          ObrasListNomenclatures[findIndex].VersionConfig.HasRevisionControl = action.payload.nomenclatures.HasRevisionControl;
          ObrasListNomenclatures[findIndex].VersionConfig.VersionFields = action.payload.nomenclatures.VersionFields;
          ObrasListNomenclatures[findIndex].VersionConfig.Tenant = action.payload.nomenclatures.Tenant;
          if (action.payload.nomenclatures.IsVersionControlDefault) {
            ObrasListNomenclatures[findIndex].VersionConfigFk = undefined;
          } else {
            ObrasListNomenclatures[findIndex].VersionConfigFk = action.payload.nomenclatures.VersionConfigFk;
          }
        }
      }
      if (
        ObraNomenclature &&
        ObraNomenclature?.ConstructionSiteId === action.payload.constructionStieId
      ) {
        ObraNomenclature.VersionConfig.HasRevisionControl = action.payload.nomenclatures.HasRevisionControl;
        ObraNomenclature.VersionConfig.VersionFields = action.payload.nomenclatures.VersionFields;
        ObraNomenclature.VersionConfig.Tenant = action.payload.nomenclatures.Tenant;
        if (action.payload.nomenclatures.IsVersionControlDefault) {
          ObraNomenclature.VersionConfigFk = undefined;
        } else {
          ObraNomenclature.VersionConfigFk = action.payload.nomenclatures.VersionConfigFk;
        }
      }
      return {
        ...state,
        ObrasList: ObrasListNomenclatures ? [...ObrasListNomenclatures] : ObrasListNomenclatures,
        Obra: ObraNomenclature,
        newEditLoading: false,
      }
    case ObraActionKeys.UPDATE_NOMENCLATURE_FAILED:
      return {
        ...state,
        error: action.payload,
        newEditLoading: false,
      }

    case ObraActionKeys.LIST_STAGES_REQUEST:
      return {
        ...state,
        stages: [],
        listStagesLoading: true,
      }
    case ObraActionKeys.LIST_STAGES_SUCCESS:
      return {
        ...state,
        stages: action.payload.stages,
        listStagesLoading: false,
      }
    case ObraActionKeys.LIST_STAGES_FAILED:
      return {
        ...state,
        error: action.payload,
        listStagesLoading: false,
      }

    case ObraActionKeys.CREATE_STAGES_REQUEST:
      return {
        ...state,
        newEditLoading: true,
      }
    case ObraActionKeys.CREATE_STAGES_SUCCESS:
      const stagesCreate = state.stages;
      if (action.payload.stage) {
        if (action.payload.stage.IsDefault) {
          const currentDefaultIndex = stagesCreate.findIndex(stage => stage.IsDefault);

          if (currentDefaultIndex !== -1) {
            stagesCreate[currentDefaultIndex].IsDefault = false;
          }
        }
        stagesCreate.push(action.payload.stage);
      }
      return {
        ...state,
        stages: [...stagesCreate],
        newEditLoading: false,
      }
    case ObraActionKeys.CREATE_STAGES_FAILED:
      return {
        ...state,
        error: action.payload,
        newEditLoading: false,
      }

    case ObraActionKeys.UPDATE_STAGES_REQUEST:
      return {
        ...state,
        newEditLoading: true,
      }
    case ObraActionKeys.UPDATE_STAGES_SUCCESS:
      let stagesUpdate = state.stages;
      if (stagesUpdate.length > 0 && action.payload.stage) {
        if (action.payload.stage.IsDefault) {
          const currentDefaultIndex = stagesUpdate.findIndex(stage => stage.IsDefault);

          if (
            currentDefaultIndex !== -1 &&
            stagesUpdate[currentDefaultIndex].TopicStageId !== action.payload.stage.TopicStageId
          ) {
            stagesUpdate[currentDefaultIndex].IsDefault = false;
          }
        }
        const index = stagesUpdate.findIndex(stage => stage.TopicStageId === action.payload.stage.TopicStageId);
        if (index !== -1) {
          stagesUpdate[index] = action.payload.stage;
        }
      }
      return {
        ...state,
        stages: [...stagesUpdate],
        newEditLoading: false,
      }
    case ObraActionKeys.UPDATE_STAGES_FAILED:
      return {
        ...state,
        error: action.payload,
        newEditLoading: false,
      }

    case ObraActionKeys.ORDER_STAGES_REQUEST:
      return {
        ...state,
        newEditLoading: true,
      }
    case ObraActionKeys.ORDER_STAGES_SUCCESS:
      let stagesOrdered = state.stages;
      if (stagesOrdered.length > 0 && action.payload.orderedStages.length > 0) {
        stagesOrdered = action.payload.orderedStages;
      }
      return {
        ...state,
        stages: [...stagesOrdered],
        newEditLoading: false,
      }
    case ObraActionKeys.ORDER_STAGES_FAILED:
      return {
        ...state,
        error: action.payload,
        newEditLoading: false,
      }

    case ObraActionKeys.DELETE_STAGES_REQUEST:
      return {
        ...state,
        newEditLoading: true,
      }
    case ObraActionKeys.DELETE_STAGES_SUCCESS:
      const stagesDelete = state.stages;
      if (stagesDelete.length > 0 && action.payload.stageId) {
        const index = stagesDelete.findIndex(stage => stage.TopicStageId === action.payload.stageId);
        if (index !== -1) {
          stagesDelete.splice(index, 1);
        }
      }
      return {
        ...state,
        stages: [...stagesDelete],
        newEditLoading: false,
      }
    case ObraActionKeys.DELETE_STAGES_FAILED:
      return {
        ...state,
        error: action.payload,
        newEditLoading: false,
      }

    case ObraActionKeys.LIST_LIBRARY_REQUEST:
      return {
        ...state,
        libraries: [],
        listLibrariesLoading: true,
      }
    case ObraActionKeys.LIST_LIBRARY_SUCCESS:
      return {
        ...state,
        libraries: action.payload,
        listLibrariesLoading: false,
      }
    case ObraActionKeys.LIST_LIBRARY_FAILED:
      return {
        ...state,
        error: action.payload,
        listLibrariesLoading: false,
      }

    case ObraActionKeys.IMPORT_LIBRARY_REQUEST:
      return {
        ...state,
        newEditLoading: true,
      }
    case ObraActionKeys.IMPORT_LIBRARY_SUCCESS:
      return {
        ...state,
        stages: action.payload,
        newEditLoading: false,
      }
    case ObraActionKeys.IMPORT_LIBRARY_FAILED:
      return {
        ...state,
        error: action.payload,
        newEditLoading: false,
      }

    case ObraActionKeys.SETTINGS_STAGES_REQUEST:
      return {
        ...state,
        newEditLoading: true,
      }
    case ObraActionKeys.SETTINGS_STAGES_SUCCESS:
      let ObrasSettingsStage = state.ObrasList;
      let ObraSettingsConfig = state.Obra;
      if (ObrasSettingsStage) {
        const findIndex = ObrasSettingsStage?.findIndex(obra => {
          return obra.ConstructionSiteId === action.payload.constructionStieId;
        });

        if (findIndex !== -1) {
          ObrasSettingsStage[findIndex].ValidateStagesInNomenclature = action.payload.config.validateStagesInNomenclature;
          ObrasSettingsStage[findIndex].AllowOnlyStagesLinkedToDisciplinesInUpload = action.payload.config.allowOnlyStagesLinkedToDisciplinesInUpload;
          ObrasSettingsStage[findIndex].AllowOnlyStagesLinkedToDisciplinesInTopics = action.payload.config.allowOnlyStagesLinkedToDisciplinesInTopics;
        }
      }
      if (
        ObraSettingsConfig &&
        ObraSettingsConfig.ConstructionSiteId === action.payload.constructionStieId
      ) {
        ObraSettingsConfig.ValidateStagesInNomenclature = action.payload.config.validateStagesInNomenclature;
        ObraSettingsConfig.AllowOnlyStagesLinkedToDisciplinesInUpload = action.payload.config.allowOnlyStagesLinkedToDisciplinesInUpload;
        ObraSettingsConfig.AllowOnlyStagesLinkedToDisciplinesInTopics = action.payload.config.allowOnlyStagesLinkedToDisciplinesInTopics;
      }
      return {
        ...state,
        ObrasList: ObrasSettingsStage ? [...ObrasSettingsStage] : ObrasSettingsStage,
        Obra: ObraSettingsConfig,
        newEditLoading: false,
      }
    case ObraActionKeys.SETTINGS_STAGES_FAILED:
      return {
        ...state,
        error: action.payload,
        newEditLoading: false,
      }

    default:
      return state;
  }
};

export default obraReducer;
