import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.surface.surface};
  padding: 24px 24px 16px 24px;
`;

export const Title = styled.div`
  width: 100%;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};

  .textTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
  padding: 18px 0;

  .contentText {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }
`;

export const BtnContainer = styled.div`
  height: 60px;
  display: flex;
  justify-content: end;
  padding-top: 12px;

  .deleteBtn {
    margin-left: 6px;
  }
`;
