import styled, { css } from "styled-components";

interface IWrapper {
  first?: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  width: 100%;
  height: auto;
  padding-top: 6px;

  ${({ first }) => first && css`
    padding-top: 0;
  `}

  .date {
    display: flex;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant};
    white-space: nowrap;
  }

  .actions {
    display: none;
  }

  :hover {

    .date {
      display: none;
    }

    .actions {
      display: flex;
      align-items: center;
      margin-right: 8px;
    }
  }
`;

interface IContainer {
  active?: boolean;
}

export const Container = styled.div<IContainer>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};

  ${({ active }) => active && css`
    border: 1px solid ${({ theme }) => theme.colors.primary.primary};
  `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .headerText {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 4px;

    .headerLeftText {
      font-size: 12px;
      font-weight: 600;
      line-height: 16.34px;
      margin-right: 4px;
      word-break: break-all; 
      overflow: hidden;
  
      .name {
        color: ${({ theme }) => theme.colors.secondary.onSecondary};
        margin-left: 6px;
      }
    }
  }
`;

export const Content = styled.div`
  margin-top: 8px;

  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    gap: 1px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }
`;

export const OwnerBadge = styled.span`
  height: 16px;
  width: auto;
  padding: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.secondary.secondary};
  margin-left: 6px;

  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.secondary.onSecondary};
`;

export const ActionButton = styled.button`
  width: 24px !important;
  height: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
  margin-right: 4px;
  cursor: pointer;
  z-index: 2;

  .iconCopyLink {
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
  }

  :hover {
    border: none;
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
  }
`;
