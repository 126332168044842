/* eslint-disable react-hooks/exhaustive-deps */
import { RefObject, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAttachmentForUpload } from "Data/interfaces/Activities/IAttachmentsForUpload";
import { ActivitiesActions } from "Store/Activities/Activities.actions";
import { getAttachmentsForUpload } from "Store/Activities/Activities.selector";
import { ITopicDnDResponse } from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { ICommentActive } from "Data/interfaces/Activities/IComments";
import { TabsRef } from "../Tabs";

interface IUseActionsContent {
  tabsRef: RefObject<TabsRef>;
  updateActivity: ITopicDnDResponse | null;
  activeComment: ICommentActive;
  workComment: string | null;
  loadingExternalLink: boolean;
}

const useActionsContent = ({
  tabsRef,
  updateActivity,
  activeComment,
  workComment,
  loadingExternalLink,
}: IUseActionsContent) => {
  const dispatch = useDispatch();
  const attachmentsForUpload = useSelector(getAttachmentsForUpload);

  useEffect(() => {
    const handleUploadAction = (attachment: IAttachmentForUpload) => {
      dispatch(ActivitiesActions.uploadAttachment(attachment));
    };

    if (attachmentsForUpload) {
      const attachmentsIds = attachmentsForUpload.attachmentsIds;
      const attachments = attachmentsForUpload.attachments;

      if (JSON.stringify(attachments) !== '{}') {
        const attachmentsValues = Object.values(attachments);

        if (attachmentsIds.length > 0 && attachmentsValues.length > 0) {
          const hasUploading = attachmentsValues.some(attachment => attachment.isUploading);
          const openAttachments = attachmentsValues.filter(attachment => {
            return !attachment.isCompleted && !attachment.isUploading && !attachment.isError
          });

          if (!hasUploading && openAttachments.length > 0) {
            dispatch(ActivitiesActions.clearUploadAttachmentCancelToken());
            const attachment = openAttachments[0];

            handleUploadAction(attachment);
          }
        }
      }
    }
  }, [attachmentsForUpload]);

  useEffect(() => {
    return () => {
      dispatch(ActivitiesActions.uploadAttachmentCancelAll());
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(ActivitiesActions.uploadAttachmentCancelAll());
    }
  }, []);

  useEffect(() => {
    if (
      updateActivity?.Guid &&
      activeComment?.activeTopicId !== updateActivity.Guid
    ) {
      dispatch(ActivitiesActions.listComments({
        topicId: updateActivity.Guid,
      }));
    }
  }, [updateActivity?.Guid, activeComment?.activeTopicId]);

  useEffect(() => {
    if (
      !loadingExternalLink &&
      !!workComment &&
      activeComment.comments.length > 0 &&
      tabsRef.current
    ) {
      tabsRef.current.onActiveTab('comments');
    }
  }, [loadingExternalLink, workComment, activeComment.comments.length]);

  return {}
}

export default useActionsContent;
