import { FC } from "react";
import SkeletonMain from "../LoadingMain";
import {
  Container,
  Main,
  MainHeader,
  MainContent,
  ContentContainer,
} from "./styles";

export interface ILoadingCommentsActivity {
  multiple?: number;
}

const LoadingCommentsActivity: FC<ILoadingCommentsActivity> = ({ multiple = 1 }) => {

  const SkeletonUsers = ({ index }: { index: number }) => {
    return (
      <Container>
        <Main>
          <MainHeader>
            <div className="rightHeaderLeft">
              <SkeletonMain width="16px" height="16px" borderRadius="50%" isCustom="skeleton-custom-dark" />
              <SkeletonMain width="80px" height="12px" borderRadius="2px" marginLeft="4px" isCustom="skeleton-custom-dark" />
              <SkeletonMain width="50px" height="12px" borderRadius="2px" marginLeft="2px" isCustom="skeleton-custom-dark" />
            </div>
            <span className="date">
              <SkeletonMain width="70px" height="12px" borderRadius="2px" isCustom="skeleton-custom-light" />
            </span>
          </MainHeader>
          <MainContent>
            <ContentContainer>
              <SkeletonMain width="80px" height="24px" borderRadius="2px" isCustom="skeleton-custom-light" />
              <SkeletonMain width="12px" height="12px" borderRadius="3px" marginLeft="8px" isCustom="skeleton-custom-light" />
              <SkeletonMain width="80px" height="24px" borderRadius="2px" marginLeft="8px" isCustom="skeleton-custom-light" />
            </ContentContainer>
          </MainContent>
        </Main>
      </Container>
    )
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(SkeletonUsers);
  }

  return (
    <>
      {multipleSkeletonsObras.map((Skeleton, i) => (
        <Skeleton key={`skeleton-key-${i}`} index={i} />
      ))}
    </>
  )
}

export default LoadingCommentsActivity;
