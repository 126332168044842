import { all, takeLatest, takeEvery } from 'redux-saga/effects';
import { AuthActionKeys } from './Auth/Auth.actions';
import { 
  signUp, 
  signIn, 
  forgotPassword, 
  resetPassword, 
  signUpGoogle, 
  getUserInfo, 
  loginSuccess, 
  listCompanyPosition, 
  updateBillingData,
} from './Auth/Auth.sagas';
import { ObraActionKeys } from './Obra/Obra.actions';
import { 
  list as listDiscipline, 
  createOrUpdateDiscipline, 
  listFilesOnDiscipline, 
  deleteDiscipline,
  statusSADiscipline,
  restoreSADiscipline,
  createSADiscipline,
  deleteSADiscipline,
  mergeSADiscipline,
} from './Disciplina/Disciplina.sagas';
import { 
  create as createNomenclatura, 
  getNomenclature, 
  getCampoVersao, 
  getExtensions, 
  setExtensions,
} from './Nomenclatura/Nomenclatura.sagas';
import { uploadAvatar } from './File/File.sagas';
import { checkUploadFile, uploadChunkFile } from './Upload/Upload.sagas';
import { NomenclaturaActionKeys } from './Nomenclatura/Nomenclatura.actions';
import { FileActionKeys } from './File/File.actions';
import { 
  listFiles, 
  downloadFiles, 
  deleteFiles, 
  updateFileInfo, 
  renameFile, 
  createFolder, 
  listFilesMoveTo, 
  moveFile, 
  unarchiveFiles, 
  verifyAutodeskProgress, 
  filesVersion,
} from './ListagemArquivos/ListagemArquivos.sagas';
import { DisciplinaActionKeys } from './Disciplina/Disciplina.actions';
import { TenantActionKeys } from './Tenant/Tenant.actions';
import { 
  getUsers, 
  getTenantRoles, 
  getTenantUsers, 
  getUserTenants, 
  getTenantInviteInfo, 
  changeContext, 
  getUsersWithConstruction, 
  removeTenantInviteUser, 
  removeTenantUser, 
  reSendTenantInvite, 
  updateTenantBindConstructionSitesToInvitedUser, 
  updateTenantBindConstructionSitesToUser, 
  tenantBindInvitedUser, 
  updateTenantUserRole, 
  updateTenantStatus,
} from './Tenant/Tenant.sagas';
import { ListagemArquivosActionKeys } from './ListagemArquivos/ListagemArquivos.actions';
import { UploadActionKeys } from './Upload/Upload.actions';
import { MinhaContaActionKeys } from './MinhaConta/MinhaConta.actions';
import { 
  list as listPlotagem, 
  listDisciplinasPlotagem as getDisciplinesPlotagem, 
  listPlotagemArquivos as getPlotagemArquivos,
  updatePlotagemArquivosStatus,
  plotRequest,
  listPlotagemSolicitacoes,
  listPlotagemSolicitacoesDisciplinas,
  excluiArquivosPlotting,
  reenviaPlotagem,
  recebePlotagem, downloadRelatorioExcel
} from './Plotagem/Plotagem.sagas';
import { 
  getUserInfo as GetUserInfo,
  notifyUserConversion as NotifyUserConversion,
  novaSenha as NovaSenha,
  updateDadosPessoais as UpdateDadosPessoais,
  updateDadosEmpresa as UpdateDadosEmpresa,
} from './MinhaConta/MinhaConta.sagas'
import {
  getObra, 
  createOrUpdate as createOrUpdateObra,
  bindUsersConstructionSite, 
  getUsersConstructionSite,
  getDisciplines, 
  updateArchivedStatus, 
  list as listObras,
  listConstructionSiteAccounts,
  updateConstructionSiteApi,
  listConstructionSitesStorage,
  listAllConstructionsSitesOfUser,
  updateDetails,
  updateDisciplines,
  updateUsers,
  deleteUsers,
  updateInvitedUsers,
  deleteInvitedUsers,
  updatePermissionsUsers,
  updateNomenclatures,
  listStages,
  createStages,
  updateStages,
  deleteStages,
  listLibrary,
  importLibrary,
  settingsStages,
  orderStages
} from './Obra/Obra.sagas';
import { PlotagemActionKeys } from './Plotagem/Plotagem.actions';
import { ViewerActionKeys } from './Viewer/Viewer.actions';
import { 
  autodestViewer as AutodeskViewer, 
  getFileInfo as GetFileInfo, 
  pdfAutodestViewer,
} from './Viewer/Viewer.sagas'
import { NotificationActionKeys } from './Notification/Notification.actions';
import { 
  listAllNotifications, 
  setNotificationsToRead, 
  getFileVersionHistory, 
  listNewNotifications, 
  setNotificationsToReadDBByIds,
} from './Notification/Notification.sagas';
import { UpdateStatusActionKeys } from './UpdateStatusFileList/UpdateStatus.actions';
import { updateStatusFileList } from './UpdateStatusFileList/UpdateStatus.sagas';
import { AdmActionKeys } from './Adm/Adm.actions';
import { 
  createOrUpdatePlanAdm, 
  updateTenantAdm, 
  listPlansAdm, 
  listTenantsAdm, 
  getPlanAdm, 
  deletePlanAdm, 
  createOrUpdateTenantPaymentAdm, 
  getBillingData, 
  confirmTenantUpdatePlan, 
  listTenantUsersAdm, 
  updateTenantAdmin,
  getTenantPersonalizationAdm,
  listLibraries,
  createLibraries,
  updateLibraries,
  deleteLibraries,
} from './Adm/Adm.sagas';
import { RevenueActionKeys } from './Revenues/Revenues.actions';
import { 
  cancelUpdatePlan, 
  getDowgradeInfo, 
  upgradeOrDowngradePlan,
} from './Revenues/Revenues.sagas';
import { FederatedViewerActionKeys } from './FederatedViewer/FederatedViewer.actions';
import { 
  autodeskViewer, 
  listDisciplines, 
  listFiles as listFilesViewer, 
  updateStatusInViewer, 
  verifyViewerAutodeskProgress,
} from './FederatedViewer/FederatedViewer.sagas';
import { ActivitiesActionKeys } from './Activities/Activities.actions';
import { 
  listActivities, 
  createActivity, 
  changeActivityStatus, 
  updateActivity, 
  updateList, 
  deleteActivity,
  shareLinkActivity,
  uploadAttachment,
  deleteAttachment,
  deleteAttachmentForCreate,
  listComments,
  createComment,
  shareLinkComment,
  editComment,
  deleteComment,
} from './Activities/Activities.sagas';
import { MultiDomainActionKeys } from './MultiDomain/MultiDomain.action';
import { 
  checkDNS,
  getMultiDomain, 
  getTenantPersonalization, 
  updateTenantPersonalization, 
  uploadTenantPersonalization,
} from './MultiDomain/MultiDomain.sagas';
import { PanelsActionKeys } from './Panels/Panels.actions';
import { 
  createPanel, 
  deletePanel, 
  list, 
  panelsAutodestViewer, 
  panelsGetFileInfo, 
  panelsListDisciplina, 
  panelsListFiles, 
  panelsVerifyAutodeskProgress, 
  reorderPanel, 
  shareLinkPanel, 
  updatePanel,
} from './Panels/Panels.sagas';
import { HistoryActionKeys } from './History/History.actions';
import { listActivity, listFile } from './History/History.sagas';

export function* rootSaga() {
  yield all([
    takeLatest(AuthActionKeys.AUTH_REGISTER_REQUEST, signUp),
    takeLatest(AuthActionKeys.AUTH_REGISTER_GOOGLE_REQUEST, signUpGoogle),
    takeLatest(AuthActionKeys.AUTH_LOGIN_REQUEST, signIn),
    takeLatest(AuthActionKeys.AUTH_LOGIN_SUCCESS, loginSuccess),
    takeLatest(AuthActionKeys.AUTH_FORGOT_PASSWORD_REQUEST, forgotPassword),
    takeLatest(AuthActionKeys.AUTH_RESET_PASSWORD_REQUEST, resetPassword),
    takeLatest(AuthActionKeys.GET_USER_INFO_REQUEST, getUserInfo),
    takeLatest(AuthActionKeys.LIST_COMPANY_POSITION_REQUEST, listCompanyPosition),
    takeLatest(AuthActionKeys.UPDATE_BILLING_DATA_REQUEST, updateBillingData),

    takeLatest(ObraActionKeys.GET_OBRA_REQUEST, getObra),
    takeLatest(ObraActionKeys.LIST_OBRA_REQUEST, listObras),
    takeLatest(ObraActionKeys.CREATE_OR_UPDATE_OBRA_REQUEST, createOrUpdateObra),
    takeLatest(ObraActionKeys.UPDATE_ARCHIVED_STATUS_REQUEST, updateArchivedStatus),
    takeLatest(ObraActionKeys.GET_DISCIPLINES_REQUEST, getDisciplines),
    takeLatest(ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_REQUEST, getUsersConstructionSite),
    takeLatest(ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_REQUEST, bindUsersConstructionSite),
    takeLatest(ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_REQUEST, listConstructionSiteAccounts),
    takeLatest(ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_REQUEST, updateConstructionSiteApi),
    takeLatest(ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_REQUEST, listConstructionSitesStorage),
    takeLatest(ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_REQUEST, listAllConstructionsSitesOfUser),
    takeLatest(ObraActionKeys.UPDATE_DETAILS_REQUEST, updateDetails),
    takeLatest(ObraActionKeys.UPDATE_DISCIPLINES_REQUEST, updateDisciplines),
    takeLatest(ObraActionKeys.UPDATE_USERS_REQUEST, updateUsers),
    takeLatest(ObraActionKeys.DELETE_USERS_REQUEST, deleteUsers),
    takeLatest(ObraActionKeys.UPDATE_INVITED_USERS_REQUEST, updateInvitedUsers),
    takeLatest(ObraActionKeys.DELETE_INVITED_USERS_REQUEST, deleteInvitedUsers),
    takeLatest(ObraActionKeys.UPDATE_PERMISSIONS_REQUEST, updatePermissionsUsers),
    takeLatest(ObraActionKeys.UPDATE_NOMENCLATURE_REQUEST, updateNomenclatures),
    takeLatest(ObraActionKeys.LIST_STAGES_REQUEST, listStages),
    takeLatest(ObraActionKeys.CREATE_STAGES_REQUEST, createStages),
    takeLatest(ObraActionKeys.UPDATE_STAGES_REQUEST, updateStages),
    takeLatest(ObraActionKeys.ORDER_STAGES_REQUEST, orderStages),
    takeLatest(ObraActionKeys.DELETE_STAGES_REQUEST, deleteStages),
    takeLatest(ObraActionKeys.LIST_LIBRARY_REQUEST, listLibrary),
    takeLatest(ObraActionKeys.IMPORT_LIBRARY_REQUEST, importLibrary),
    takeLatest(ObraActionKeys.SETTINGS_STAGES_REQUEST, settingsStages),

    takeLatest(TenantActionKeys.GET_USERS_REQUEST, getUsers),
    takeLatest(TenantActionKeys.GET_TENANT_USERS_REQUEST, getTenantUsers),
    takeLatest(TenantActionKeys.GET_TENANT_ROLES_REQUEST, getTenantRoles),
    takeLatest(TenantActionKeys.GET_TENANT_INVITE_INFO_REQUEST, getTenantInviteInfo),
    takeLatest(TenantActionKeys.LIST_USER_TENANTS_REQUEST, getUserTenants),
    takeLatest(TenantActionKeys.CHANGE_CONTEXT_REQUEST, changeContext),
    takeLatest(TenantActionKeys.GET_TENANT_LIST_USERS_CONSTRUCTION_REQUEST, getUsersWithConstruction),
    takeLatest(TenantActionKeys.TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_REQUEST, reSendTenantInvite),
    takeLatest(TenantActionKeys.REMOVE_TENANT_USER_REQUEST, removeTenantUser),    
    takeLatest(TenantActionKeys.REMOVE_TENANT_INVITE_USER_REQUEST, removeTenantInviteUser),  
    takeLatest(TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_REQUEST, updateTenantBindConstructionSitesToUser),    
    takeLatest(TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_REQUEST, updateTenantBindConstructionSitesToInvitedUser),    
    takeLatest(TenantActionKeys.TENANT_BIND_INVITED_USERS_REQUEST, tenantBindInvitedUser),    
    takeLatest(TenantActionKeys.UPDATE_TENANT_USER_ROLE_REQUEST, updateTenantUserRole),    
    takeLatest(TenantActionKeys.UPDATE_TENANT_STATUS_REQUEST, updateTenantStatus),    

    takeLatest(ListagemArquivosActionKeys.LIST_FILES_REQUEST, listFiles),
    takeLatest(ListagemArquivosActionKeys.DOWNLOAD_FILES_REQUEST, downloadFiles),
    takeLatest(ListagemArquivosActionKeys.DELETE_FILES_REQUEST, deleteFiles),
    takeEvery(ListagemArquivosActionKeys.RENAME_FILE_REQUEST, renameFile),
    takeLatest(ListagemArquivosActionKeys.CREATE_FOLDER_REQUEST, createFolder),
    takeEvery(ListagemArquivosActionKeys.UPDATE_FILE_INFO_REQUEST, updateFileInfo),
    takeLatest(ListagemArquivosActionKeys.LIST_FILES_MOVE_TO_REQUEST, listFilesMoveTo),
    takeEvery(ListagemArquivosActionKeys.MOVE_FILE_REQUEST, moveFile),
    takeLatest(ListagemArquivosActionKeys.UNARCHIVE_FILES_REQUEST, unarchiveFiles),
    takeLatest(ListagemArquivosActionKeys.VERIFY_AUTODESK_PROGRESS, verifyAutodeskProgress),
    takeLatest(ListagemArquivosActionKeys.FILES_VERSION_REQUEST, filesVersion),

    takeLatest(DisciplinaActionKeys.LIST_DISCIPLINE_REQUEST, listDiscipline),
    takeLatest(DisciplinaActionKeys.CREATE_OR_UPDATE_DISCIPLINE_REQUEST, createOrUpdateDiscipline),
    takeLatest(DisciplinaActionKeys.LIST_FILES_ON_DISCIPLINE_REQUEST, listFilesOnDiscipline),
    takeLatest(DisciplinaActionKeys.DELETE_DISCIPLINE_REQUEST, deleteDiscipline),
    takeLatest(DisciplinaActionKeys.STATUS_SA_DISCIPLINE_REQUEST, statusSADiscipline),
    takeLatest(DisciplinaActionKeys.RESTORE_SA_DISCIPLINE_REQUEST, restoreSADiscipline),
    takeLatest(DisciplinaActionKeys.CREATE_SA_DISCIPLINE_REQUEST, createSADiscipline),
    takeLatest(DisciplinaActionKeys.DELETE_SA_DISCIPLINE_REQUEST, deleteSADiscipline),
    takeLatest(DisciplinaActionKeys.MERGE_SA_DISCIPLINE_REQUEST, mergeSADiscipline),
    
    takeLatest(NomenclaturaActionKeys.CREATE_NOMENCLATURA_REQUEST, createNomenclatura),
    takeLatest(NomenclaturaActionKeys.GET_NOMENCLATURA_REQUEST, getNomenclature),
    takeLatest(NomenclaturaActionKeys.GET_CAMPO_VERSAO_REQUEST, getCampoVersao),
    takeLatest(NomenclaturaActionKeys.GET_EXTENSOES_REQUEST, getExtensions),
    takeLatest(NomenclaturaActionKeys.SET_EXTENSOES_REQUEST, setExtensions),

    takeLatest(FileActionKeys.UPLOAD_AVATAR_REQUEST, uploadAvatar),

    takeEvery(UploadActionKeys.UPLOAD_CHUNK_FILES_REQUEST, uploadChunkFile),
    takeEvery(UploadActionKeys.CHECK_UPLOAD_FILES_REQUEST, checkUploadFile),

    takeLatest(PlotagemActionKeys.LIST_PLOTAGEM_REQUEST, listPlotagem),
    takeLatest(PlotagemActionKeys.LIST_DISCIPLINES_PLOTAGEM_REQUEST, getDisciplinesPlotagem),
    takeLatest(PlotagemActionKeys.LIST_PLOTAGEM_ARQUIVOS_REQUEST, getPlotagemArquivos),
    takeLatest(PlotagemActionKeys.UPDATE_PLOTAGEM_ARQUIVOS_STATUS_REQUEST, updatePlotagemArquivosStatus),
    takeLatest(PlotagemActionKeys.DOWNLOAD_RELATORIO_EXCEL_REQUEST, downloadRelatorioExcel),

    takeLatest(MinhaContaActionKeys.GET_USER_INFO_REQUEST, GetUserInfo),
    takeLatest(MinhaContaActionKeys.NOTIFY_USER_CONVERSION, NotifyUserConversion),
    takeLatest(MinhaContaActionKeys.NOVA_SENHA_REQUEST, NovaSenha),
    takeLatest(MinhaContaActionKeys.UPDATE_DADOS_PESSOAIS_REQUEST, UpdateDadosPessoais),
    takeLatest(MinhaContaActionKeys.UPDATE_DADOS_EMPRESA_REQUEST, UpdateDadosEmpresa),
    
    takeLatest(PlotagemActionKeys.PLOT_REQUEST_REQUEST, plotRequest),

    takeLatest(PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_REQUEST, listPlotagemSolicitacoes),
    takeLatest(PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_REQUEST, listPlotagemSolicitacoesDisciplinas),
    takeLatest(PlotagemActionKeys.EXCLUI_ARQUIVOS_PLOTAGEM_REQUEST, excluiArquivosPlotting),
    takeLatest(PlotagemActionKeys.REENVIA_PLOTAGEM_REQUEST, reenviaPlotagem),
    takeLatest(PlotagemActionKeys.RECEBE_PLOTAGEM_REQUEST, recebePlotagem),

    takeLatest(ViewerActionKeys.AUTODESK_VIEWER_REQUEST, AutodeskViewer),
    takeLatest(ViewerActionKeys.GET_FILE_INFO_REQUEST, GetFileInfo),
    takeLatest(ViewerActionKeys.PDF_AUTODESK_VIEWER_REQUEST, pdfAutodestViewer),

    takeLatest(FederatedViewerActionKeys.VIEWER_LIST_DISCIPLINES_REQUEST, listDisciplines),
    takeEvery(FederatedViewerActionKeys.VIEWER_LIST_FILES_REQUEST, listFilesViewer),
    takeLatest(FederatedViewerActionKeys.AUTODESK_FEDERATED_VIEWER_REQUEST, autodeskViewer),
    takeLatest(FederatedViewerActionKeys.UPDATE_STATUS_IN_VIEWER_REQUEST, updateStatusInViewer),
    takeLatest(FederatedViewerActionKeys.VERIFY_VIEWER_AUTODESK_PROGRESS, verifyViewerAutodeskProgress),

    takeLatest(NotificationActionKeys.LIST_NOTIFICATIONS_REQUEST, listAllNotifications),
    takeLatest(NotificationActionKeys.SET_NOTIFICATION_READ_REQUEST, setNotificationsToRead),
    takeLatest(NotificationActionKeys.GET_FILE_VERSION_HISTORY_REQUEST, getFileVersionHistory),
    takeLatest(NotificationActionKeys.LIST_NEW_NOTIFICATIONS_REQUEST, listNewNotifications),
    takeLatest(NotificationActionKeys.SET_NOTIFICATION_AS_VIEW, setNotificationsToReadDBByIds),

    takeLatest(AdmActionKeys.LIST_PLANS_REQUEST, listPlansAdm),
    takeLatest(AdmActionKeys.GET_PLAN_REQUEST, getPlanAdm),
    takeLatest(AdmActionKeys.CREATE_OR_UPDATE_PLAN_REQUEST, createOrUpdatePlanAdm),
    takeLatest(AdmActionKeys.DELETE_PLAN_REQUEST, deletePlanAdm),
    takeLatest(AdmActionKeys.LIST_TENANTS_REQUEST, listTenantsAdm),
    takeLatest(AdmActionKeys.UPDATE_TENANT_REQUEST, updateTenantAdm),
    takeLatest(AdmActionKeys.CREATE_OR_UPDATE_TENANT_PAYMENT_REQUEST, createOrUpdateTenantPaymentAdm),
    takeLatest(AdmActionKeys.GET_BILLING_DATA_REQUEST, getBillingData),
    takeLatest(AdmActionKeys.CONFIRM_UPDATE_PLAN_REQUEST, confirmTenantUpdatePlan),
    takeLatest(AdmActionKeys.LIST_TENANT_USERS_REQUEST, listTenantUsersAdm),
    takeLatest(AdmActionKeys.UPDATE_TENANT_ADMIN_REQUEST, updateTenantAdmin),
    takeLatest(AdmActionKeys.TENANT_PERSONALIZATION_ADM_REQUEST, getTenantPersonalizationAdm),
    takeLatest(AdmActionKeys.LIST_LIBRARY_REQUEST, listLibraries),
    takeLatest(AdmActionKeys.CREATE_LIBRARY_REQUEST, createLibraries),
    takeLatest(AdmActionKeys.UPDATE_LIBRARY_REQUEST, updateLibraries),
    takeLatest(AdmActionKeys.DELETE_LIBRARY_REQUEST, deleteLibraries),

    takeLatest(RevenueActionKeys.UPGRADE_OR_DOWGRADE_PLAN_REQUEST, upgradeOrDowngradePlan),
    takeLatest(RevenueActionKeys.CANCEL_UPDATE_PLAN_REQUEST, cancelUpdatePlan),
    takeLatest(RevenueActionKeys.GET_DOWNGRADE_INFO_REQUEST, getDowgradeInfo),

    takeLatest(ActivitiesActionKeys.LIST_ACTIVITIES_REQUEST, listActivities),
    takeLatest(ActivitiesActionKeys.CREATE_ACTIVITY_REQUEST, createActivity),
    takeLatest(ActivitiesActionKeys.CHANGE_ACTIVITY_STATUS_REQUEST, changeActivityStatus),
    takeLatest(ActivitiesActionKeys.UPDATE_ACTIVITY_REQUEST, updateActivity),
    takeLatest(ActivitiesActionKeys.DELETE_ACTIVITY_REQUEST, deleteActivity),
    takeLatest(ActivitiesActionKeys.LIST_ACTIVITIES_UPDATE_REQUEST, updateList),
    takeLatest(ActivitiesActionKeys.SHARE_LINK_ACTIVITY_REQUEST, shareLinkActivity),
    takeLatest(ActivitiesActionKeys.UPLOAD_ATTACHMENT_REQUEST, uploadAttachment),
    takeLatest(ActivitiesActionKeys.DELETE_ATTACHMENT_FOR_CREATE_REQUEST, deleteAttachmentForCreate),
    takeLatest(ActivitiesActionKeys.DELETE_ATTACHMENT_REQUEST, deleteAttachment),
    takeLatest(ActivitiesActionKeys.LIST_COMMENTS_REQUEST, listComments),
    takeLatest(ActivitiesActionKeys.CREATE_COMMENT_REQUEST, createComment),
    takeLatest(ActivitiesActionKeys.EDIT_COMMENT_REQUEST, editComment),
    takeLatest(ActivitiesActionKeys.DELETE_COMMENT_REQUEST, deleteComment),
    takeLatest(ActivitiesActionKeys.SHARE_LINK_COMMENT_REQUEST, shareLinkComment),

    takeLatest(MultiDomainActionKeys.MULTI_DOMAIN_REQUEST, getMultiDomain),
    takeLatest(MultiDomainActionKeys.TENANT_PERSONALIZATION_REQUEST, getTenantPersonalization),
    takeLatest(MultiDomainActionKeys.UPDATE_TENANT_PERSONALIZATION_REQUEST, updateTenantPersonalization),
    takeLatest(MultiDomainActionKeys.UPLOAD_TENANT_PERSONALIZATION_REQUEST, uploadTenantPersonalization),
    takeLatest(MultiDomainActionKeys.CHECK_DNS_REQUEST, checkDNS),
    
    takeLatest(PanelsActionKeys.PANELS_LIST_REQUEST, list),
    takeLatest(PanelsActionKeys.PANELS_CREATE_REQUEST, createPanel),
    takeLatest(PanelsActionKeys.PANELS_UPDATE_REQUEST, updatePanel),
    takeLatest(PanelsActionKeys.PANELS_REORDER_REQUEST, reorderPanel),
    takeLatest(PanelsActionKeys.PANELS_DELETE_REQUEST, deletePanel),
    takeLatest(PanelsActionKeys.PANELS_LIST_DISCIPLINA_REQUEST, panelsListDisciplina),
    takeLatest(PanelsActionKeys.PANELS_LIST_FILES_REQUEST, panelsListFiles),
    takeLatest(PanelsActionKeys.PANELS_GET_FILE_INFO_REQUEST, panelsGetFileInfo),
    takeLatest(PanelsActionKeys.SHARE_LINK_PANELS_REQUEST, shareLinkPanel),
    takeLatest(PanelsActionKeys.PANELS_AUTODESK_VIEWER_REQUEST, panelsAutodestViewer),
    takeLatest(PanelsActionKeys.PANELS_VERIFY_AUTODESK_PROGRESS, panelsVerifyAutodeskProgress),

    takeLatest(HistoryActionKeys.LIST_HISTORY_FILE_REQUEST, listFile),
    takeLatest(HistoryActionKeys.LIST_HISTORY_ACTIVITY_REQUEST, listActivity),

    // TODO depois voltar isso pra takeLatest
    takeEvery(UpdateStatusActionKeys.UPDATE_STATUS_FILE_LIST_REQUEST, updateStatusFileList),
  ]);
}
