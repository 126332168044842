import { Container } from './styles';

import emptyImg from 'Static/images/empty-comments.png';

const Empty = () => {
  return (
    <Container>
      <img src={emptyImg} alt="Comentários vazio" />
      <span className="title">Nenhum comentário</span>
      <span className="description">
        Ainda não foi feito nenhum comentário nessa atividade, use o campo abaixo para criar um comentário.
      </span>
    </Container>
  )
}

export default Empty;
