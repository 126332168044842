import { common, IColorsTheme } from './default';
import { isDarken } from 'Utils/Color';

export default {
  ...common,
  primary: {
    primary: '#3547C8',
    get primaryLightContainer() {return `${this.primary} 6%`},
    get primaryHover() {return `${this.primary} 12%`},
    get primaryPressed() {return `${this.primary} 24%`},
    get primaryOutline() {return `${this.primary} 50%`},
    get primaryContainer() {return `${this.primary} 16%`},
    get onPrimary() {return isDarken(this.primary) ? '#FFFFFF' : '#324150'},
    get onPrimaryHover() {return `${this.onPrimary} 12%`},
    get onPrimaryPressed() {return `${this.onPrimary} 24%`},
    get onPrimaryOutline() {return `${this.onPrimary} 50%`},
  },
  secondary: {
    secondary: '#EAEDF0',
    get secondaryHover() {return `${this.secondary} 12%`},
    get secondaryPressed() {return `${this.secondary} 24%`},
    get secondaryOutline() {return `${this.secondary} 50%`},
    onSecondary: '#324150',
    onSecondaryVariant: '#8697A8',
    get onSecondaryHover() {return `${this.onSecondary} 12%`},
    get onSecondaryPressed() {return `${this.onSecondary} 24%`},
    get onSecondaryOutline() {return `${this.onSecondary} 50%`},
  },
  tertiary: {
    tertiary: '#3547C8',
    get onTertiary() {return isDarken(this.tertiary) ? '#FFFFFF' : '#324150'},
  },
  surface: {
    surface: '#FFFFFF',
    surfaceContainer: '#F7F7F7',
    surfaceOutline: '#EAEDF0',
    onSurface: '#324150',
    onSurfaceVariant: '#8697A8',
    onSurfacePlaceholder: '#BEC2C6',
    get onSurfaceHover() {return `${this.onSurface} 12%`},
    get onSurfacePressed() {return  `${this.onSurface} 24%`},
  },
} as IColorsTheme;
