import { FC, ReactNode, useLayoutEffect, useState } from 'react';
import { InfoWrapper, Wrapper } from './styles';

export interface IFileDrop {
  maxCount?: number;
  borderRadius?: number;
  info?: ReactNode;
  isDisabled?: boolean;
  onDropFiles: (files: File | File[]) => void;
  onFocus?: (focus: boolean) => void;
}

const FileDrop: FC<IFileDrop> = ({
  children,
  maxCount,
  borderRadius,
  info,
  isDisabled,
  onDropFiles,
  onFocus
}) => {
  const [displayZone, setDisplayZone] = useState(false);

  const onDragOver = (ev: any) => {
    ev.preventDefault();

    if (isDisabled) return;
  };

  const onDragEnter = (ev: any) => {
    if (isDisabled) return;

    if (ev.dataTransfer.types.includes('Files')) {
      setDisplayZone(true);
      if (onFocus) onFocus(true);
    }
  };

  const onDragLeave = (ev: any) => {
    if (isDisabled) return;

    setDisplayZone(false);
    if (onFocus) onFocus(false);
  };

  const onDrop = (ev: any) => {
    ev.preventDefault();

    if (isDisabled) return;
    setDisplayZone(false);
    if (onFocus) onFocus(false);

    if (maxCount === 1) {
      onDropFiles(ev.dataTransfer.files[0]);
    } else {
      if (ev.dataTransfer.files.length > 0) {
        const dropFiles: File[] = [];

        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
          dropFiles.push(ev.dataTransfer.files[i]);
        }
        onDropFiles(dropFiles);
      }
    }
  };

  const onPaste = (ev: any) => {
    if (ev?.clipboardData?.files?.length > 0) {
      ev.preventDefault();
  
      if (isDisabled) return;
  
      setDisplayZone(false);
      if (onFocus) onFocus(false);
  
      if (maxCount === 1) {
        onDropFiles(ev.clipboardData.files[0]);
      } else {
        if (ev.clipboardData.files.length > 0) {
          const dropFiles: File[] = [];
  
          for (let i = 0; i < ev.clipboardData.files.length; i++) {
            dropFiles.push(ev.clipboardData.files[i]);
          }
          onDropFiles(dropFiles);
        }
      }
    }
  };

  useLayoutEffect(() => {
    const bodyElement = document.querySelector('body');

    bodyElement?.addEventListener('paste', onPaste);

    return () => bodyElement?.removeEventListener('paste', onPaste);
  }, []);

  return (
    <Wrapper onDragEnter={onDragEnter}>
      {children}
      {!isDisabled && (
        <InfoWrapper
          borderRadius={borderRadius}
          hidden={!displayZone}
          onDrop={onDrop}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
        >
          {info}
        </InfoWrapper>
      )}
    </Wrapper>
  );
};

export default FileDrop;
