import { Popover } from "antd";
import { Icon } from "Components/UI";
import { FC, useState } from "react";
import SpinCustom from "Components/UI/SpinCustom";
import { IMDTheme } from "Data/interfaces/MultiDomain/IMDTheme";
import { ActionButton, Line, MenuDropDown } from "./styles";

interface IActionButtons {
  loadingShareLink?: boolean;
  theme?: IMDTheme;
  hasDeletePermission?: boolean;
  onCopyLink: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

const ActionButtons: FC<IActionButtons> = ({
  loadingShareLink,
  theme,
  hasDeletePermission,
  onCopyLink,
  onDelete,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const overlay = (
    <MenuDropDown>
      <span
        className="menuItem"
        onClick={onCopyLink}
      >
        {loadingShareLink && (
          <SpinCustom
            size={14}
            marginRigth={14}
            color={theme?.colors.surface.onSurfaceVariant}
          />
        )}
        {!loadingShareLink && (
          <Icon
            icon="link"
            customSize={14}
            className="iconAction"
          />
        )}
        Copiar link
      </span>
      {/* <span
        className="menuItem"
        onClick={onEdit}
      >
        <Icon
          icon="editar"
          customSize={14}
          className="iconAction"
        />
        Editar
      </span> */}
      {hasDeletePermission && (
        <>
          <Line />
          <span
            className="menuItem"
            onClick={onDelete}
          >
            <Icon
              icon="excluir"
              customSize={14}
              className="iconAction"
            />
            Excluir
          </span>
        </>
      )}
    </MenuDropDown>
  )

  return (
    <Popover
      content={overlay}
      placement="bottomRight"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      overlayInnerStyle={{ padding: '4px 0 4px 0' }}
    >
      <ActionButton>
        <Icon
          icon="moreActions"
          customSize={16}
          className="iconMoreActions"
        />
      </ActionButton>
    </Popover>
  )
};

export default ActionButtons;
