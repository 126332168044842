import { FC, useMemo, useRef } from "react";
import Tabs, { ITabMap, TabsRef } from "../Tabs";
import Attachments from "../Attachments";
import { ITopicDnDResponse } from "Data/interfaces/Activities/IDragAndDropDataFormat";
import useActionsContent from "./useActionsContent";
import History from "../History";
import Comments from "../Comments";
import { useFeatureFlag } from "Hooks/useFeatureFlag";
import { useSelector } from "react-redux";
import { getActiveComment } from "Store/Activities/Activities.selector";
import { Http } from "Utils/Http";

interface IActionsContent {
  csId: number;
  updateActivity: ITopicDnDResponse | null;
  loadingExternalLink: boolean;
}

const ActionsContent: FC<IActionsContent> = ({
  csId,
  updateActivity,
  loadingExternalLink,
}) => {
  const activeComment = useSelector(getActiveComment);
  const workComment = Http.GetQueryParams('workComment');
  const tabsRef = useRef<TabsRef>(null);
  useActionsContent({
    tabsRef,
    updateActivity,
    activeComment,
    workComment: workComment || null,
    loadingExternalLink,
  });

  const commentsFeatureFlag = useFeatureFlag('comments-feature');
  const commentsFeature = commentsFeatureFlag.enabled && commentsFeatureFlag.value === 'test';

  const items = useMemo(() => {
    const opts: ITabMap = new Map();

    opts.set('attachement', {
      id: 'attachement',
      label: 'Anexos',
      isDisabled: false,
      tagNumber: updateActivity?.Attachments.length,
      children: (
        <Attachments
          csId={csId}
          updateActivity={updateActivity}
          loadingExternalLink={loadingExternalLink}
        />
      ),
    });

    if (!!updateActivity) {
      opts.set('comments', {
        id: 'comments',
        label: 'Comentários',
        isDisabled: !commentsFeature,
        tagNumber: commentsFeature ? activeComment.comments.length : undefined,
        // newTagNumber: commentsFeature ? 1 : undefined,
        customContentPadding: '0',
        children: commentsFeature ? (
          <Comments
            updateActivity={updateActivity}
            loadingExternalLink={loadingExternalLink}
            workComment={workComment}
          />
        ) : <></>,
      });
      opts.set('history', {
        id: 'history',
        label: 'Histórico',
        isDisabled: false,
        children: (
          <History
            csId={csId}
            updateActivity={updateActivity}
            loadingExternalLink={loadingExternalLink}
          />
        ),
        customContentPadding: '7px 14px',
      });
    }

    return opts;
  }, [
    csId,
    loadingExternalLink,
    updateActivity,
    commentsFeature,
    activeComment?.comments.length,
    workComment,
  ]);

  return (
    <Tabs
      ref={tabsRef}
      defaultActiveTab="attachement"
      options={items}
      loadingExternalLink={loadingExternalLink}
    />
  );
}

export default ActionsContent;
