import { createGlobalStyle } from "styled-components";

export const ModalStyled = createGlobalStyle`
  .ant-modal-header {
    background-color: ${({theme}) => theme.colors.primary.primary} !important;
    height: 63px;
    display: flex;
    align-items: center;
    padding: 0 40px !important;
    margin: 0 !important;
  }

  .ant-modal-title {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 22px !important;
    color: ${({theme}) => theme.colors.primary.onPrimary} !important;
  }

  .ant-modal-close-x {
    i {
      color: ${({theme}) => theme.colors.primary.onPrimary} !important;
    }
    margin-right: 17px;
    margin-top: -7px;
  }

  .ant-modal-close-x {
    i {
      background: transparent !important;
    }
  }

  .ant-modal-body {
    padding: 0px !important;
    background: ${({theme}) => theme.colors.surface.surface};
    border-radius: 8px !important;
    overflow: hidden;
  }

  .ant-modal-footer {
    height: 72px;
    padding-top: 17px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    margin-top: 0px !important;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }

  .ant-modal .ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
    margin-bottom: 0;
    margin-inline-start: 8px;
  }

  .ant-modal-hide-header .ant-modal-body {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .ant-modal-hide-header .ant-modal-close {
    display: none !important;
  }

  .ant-modal-mask-render {
    background-color: rgba(0, 0, 0, 0.18) !important;
  }

  /* .ant-modal-root > .ant-modal-mask:first-child {
    z-index: 99 !important;
  } */

  .ant-modal-footer-border-none > .ant-modal-content > .ant-modal-footer {
    box-shadow: none !important;
    border: none !important;
  }

  .custom-class-name > .ant-modal-content,
  .custom-class-name > .ant-modal-content > .ant-modal-body {
    border-radius: 10px;
    overflow: hidden;
  }

  .ant-modal-content {
    padding: 0 !important;
  }
`;