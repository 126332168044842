import styled, { css } from "styled-components";

interface IContent {
  loading?: boolean;
}

export const Content = styled.div<IContent>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.surface.surfaceContainer};

  .containerReload {
    position: absolute;
    height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .btnReload {
      height: 32px !important;
      width: 163px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 32px !important;
  
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
  
      .iconDownArrow {
        color: ${({ theme }) => theme.colors.primary.onPrimary} !important;
      }
    }
  }

  ${({ loading }) => loading && css`
    max-height: 380px;
    overflow: hidden;
  `}
`;

export const DataBadgeContainer = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .text {
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    border-radius: 999px;

    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant};
    background-color: ${({ theme }) => theme.colors.secondary.secondary};
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.surface.surface};
`;
