import styled from "styled-components";

export const MenuDropDown = styled.div`
  width: 150px;
  display: flex;
  flex-direction: column;

  .menuItem {
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    background-color: ${({ theme }) => theme.colors.surface.surface};
    cursor: pointer;

    :hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
    }

    .iconAction {
      margin-right: 4px;
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
    }
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: ${({ theme }) => theme.colors.surface.surfaceOutline};
`;

export const ActionButton = styled.button`
  width: 24px !important;
  height: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
  cursor: pointer;
  z-index: 1;

  .iconMoreActions {
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
  }

  :hover {
    border: none;
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
  }
`;
