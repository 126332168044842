import { FC } from "react";
import { Modal } from "antd";

interface IVideoEx {
  visible: boolean;
  url: string;
  onCancel: () => void;
}

const VideoEx: FC<IVideoEx> = ({
  visible,
  url,
  onCancel,
}) => {

  return (
    <Modal
      footer={null}
      open={visible}
      onCancel={onCancel}
      width={845}
      closable={false}
      centered
      styles={{
        content: {
          padding: 0,
          backgroundColor: 'transparent', 
          border: 'none', 
          boxShadow: 'none'
        },
      }}
    >
      <iframe
        title="Video tutorial do Viewer Federado"
        width="845px"
        height="475px"
        src={url}
        style={{
          padding: 0,
          margin: 0,
          backgroundColor: 'transparent', 
          border: 'none', 
          boxShadow: 'none'
        }}
      />
    </Modal>
  )
}

export default VideoEx;
